import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { MessageService } from "primeng/api";
import { ApisService } from "src/app/services/apis.service";

@Component({
  selector: "app-task-log",
  templateUrl: "./task-log.component.html",
  styleUrls: ["./task-log.component.scss"],
})
export class TaskLogComponent implements OnInit {
  cols = [
    { field: "user_name", header: "User", filter: true },
    { field: "file_name", header: "File name", filter: true },
    { field: "record_type", header: "Record type", filter: true },
    { field: "status", header: "Status", filter: true },
    // { field: 'details', header: 'Details' },
    { field: "created_at", header: "Submitted on" },
    { field: "updated_at", header: "Finished on" },
    { field: "execution_time", header: "Duration" },
  ];

  selectedTask: any = {};
  tasks: any[] = [];
  filters: any = {};
  selectedFilter: any = {};
  filterSet: any = new Array(8).fill(true);
  loading: boolean = false;
  showDeleteDialog: boolean = false;
  displayTask: boolean = false;

  @Input("token") token: any;

  constructor(
    private apisService: ApisService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.loading = true;
    this.filterSet = this.cols.filter((c) => c.filter);

    await this.apisService.getTaskLog(this.token).then((res: any) => {
      this.tasks = res.results.map((d: any) => this.processTask(d));
    });

    this.filterSet.map((f: any) => {
      let values = [...new Set(this.tasks.map((item) => item[f.field]))];
      this.filters[f.field] = values.map((v) => {
        return { name: v };
      });
    });

    this.loading = false;
  }

  processTask(d: any) {
    d.created_at = moment(d.created_at).format("DD-MMM-YY HH:mm:ss");
    d.updated_at = moment(d.updated_at).format("DD-MMM-YY HH:mm:ss");
    // capitalize status
    d.status = (d.status || "In Progress").toLowerCase().capitalize();
    if (d.status === "In progress") {
      d.updated_at = "";
    }

    if (d.updated_at != "") {
      // Calculate execution time in milliseconds
      const createdMoment = moment(d.created_at, "DD-MMM-YY HH:mm:ss");
      const updatedMoment = moment(d.updated_at, "DD-MMM-YY HH:mm:ss");
      const executionTimeInMillis = updatedMoment.diff(createdMoment);

      // Convert execution time to a human-readable string
      const duration = moment.duration(executionTimeInMillis);
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const humanReadableExecutionTime = `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
      d.execution_time = humanReadableExecutionTime;
    }

    try {
      d.details = JSON.parse(d.details);
      d.isJson = true;
    } catch (e) {
      d.isJson = false;
    }

    return d;
  }

  filterTable(dt: any, filterValue: any, fieldName: any) {
    dt.filter(
      (filterValue || []).map((f: any) => f.name),
      fieldName,
      "in"
    );
  }

  // confirmDeleteSelected() {
  //     this.apisService.clearAllTasksLogs().subscribe((res: any) => {
  //         this.showDeleteDialog = false;
  //         if (res.success) {
  //             this.messageService.add({
  //                 severity: 'success',
  //                 summary: 'Successful',
  //                 detail: 'All tasks logs have been cleared.',
  //                 life: 3000,
  //             });
  //             this.initData();
  //         } else {
  //             this.messageService.add({
  //                 severity: 'error',
  //                 summary: 'Something went wrong.',
  //                 detail: 'Please try again.',
  //                 life: 3000,
  //             });
  //         }
  //     });
  // }

  onRowSelect(event: any) {
    this.selectedTask = event.data;
    this.displayTask = true;
    this.apisService
      .getTaskLogDetails(this.selectedTask.uuid, this.token)
      .then((data: any) => {
        let task = this.tasks.find((t) => t.uuid === data.uuid);
        let processedTask = this.processTask(data);
        if (task.status != processedTask.status) {
          this.selectedTask = processedTask;
          this.initData();
        }
      });
  }

  getBadgeSeverity(status: string) {
    if (status === "Success") return "success";
    if (status === "Failed") return "danger";
    return "warning";
  }

  onHide() {
    this.selectedTask = {};
    this.displayTask = false;
  }
}
