import { Component, Input, OnInit } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoaderComponent implements OnInit {
  @Input("loading") loading;

  constructor() {}

  ngOnInit(): void {}
}
