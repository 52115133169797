import { SettingsService } from "src/app/services/settings.service";
import { StatisticsService } from "src/app/services/statistics.service";
import { BehaviorSubject } from "rxjs";
import { AlertService } from "../../../services/alert.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperService } from "../../../services/helper.service";
import { Component, Input, OnInit } from "@angular/core";
import { StockChart } from "angular-highcharts";
import * as _ from "lodash";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-widget-statistics-modal",
  templateUrl: "./widget-statistics-modal.component.html",
  styleUrls: ["./widget-statistics-modal.component.scss"],
})
export class WidgetStatisticsModalComponent implements OnInit {
  @Input("widgets") widgets;
  @Input("chartRef") chartRef: StockChart;
  @Input("selectedSeries") selectedSeries;
  @Input("periodicity") periodicity;
  @Input("datasetName") datasetName;
  @Input("words") words;
  @Input("measure") measure;
  @Input("extremes") extremes;
  paramChanged: BehaviorSubject<string> = new BehaviorSubject<string>("");
  series: any[] = [];
  options: any[] = [];
  currentOption: any;
  currencies: any = [];
  fields: any[] = [];
  selectedFields: any[] = [];
  statistics: any = this.helper.getStatisticsTemplate();
  languages: any = [];
  selectedLangauge: any = "";

  constructor(
    private helper: HelperService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private statisticsService: StatisticsService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.initWidget();
    this.initLang();
    this.initUnits();
    this.initOptions();
    this.computeWidget();
  }

  initUnits() {
    this.currencies = this.helper.getWidgetsCurrencies();
  }

  initLang() {
    this.settingsService.getSettings().subscribe((configs) => {
      if (!configs) return;
      this.languages = configs.languages;
      this.selectedLangauge = this.languages.find((lang) => lang.default);
    });
  }

  seriesParamChanged() {
    this.paramChanged.next("");
  }

  initWidget() {
    this.series = _.cloneDeep(this.selectedSeries);
    this.series = this.series.map((s) => {
      s.color = this.helper.randDarkColor();
      return s;
    });
    this.options = [
      {
        name: "periodicity",
        label:
          this.words["key-panel-main-statistics-time_period"] || "periodicity",
      },
      {
        name: "percentage",
        label:
          this.words["key-panel-main-statistics-percentage"] || "percentage",
      },
    ];
    this.currentOption = this.options[0];
    this.paramChanged.pipe(debounceTime(500)).subscribe(() => {
      this.computeWidget();
    });
  }

  initOptions() {
    let params = {
      extremes: {
        min: "2000-01-01T00:00:00.000Z",
        max: "2021-05-01T00:00:00.000Z",
      },
      statistics: this.statistics,
      selectedCurrencies: ["nominal"],
      measure: "Kg",
    };
    // this.widgets.selectedCurrencies = selectedCurrencies;
    let statistics = this.statisticsService.calculateStatistics(
      this.selectedSeries,
      this.statistics.currentOption,
      params,
      this.periodicity || "monthly",
      this.words
    );
    this.fields = statistics.cols;
    this.selectedFields = this.fields;
  }

  optionChanged(newOption) {
    this.statistics.currentOption = newOption.name;
    this.initOptions();
    this.computeWidget();
  }

  computeWidget() {
    // let measure = this.chartRef.ref.yAxis[0].userOptions.title.text;
    let measure = "Kg";
    let langKey = this.selectedLangauge.key;
    let code = this.helper.getStatisticsWidgetCode(
      this.series,
      this.widgets,
      measure,
      this.periodicity || "monthly",
      this.datasetName,
      this.statistics.currentOption,
      this.selectedFields.map((f) => f.uid),
      this.currentOption.name == "percentage"
        ? {
            t1: this.widgets.timeMinusVariantSparkChart,
            tn: this.widgets.timeMinusVariant,
          }
        : null,
      langKey
    );
    this.widgets.preview = code.previewCode;
    this.widgets.url = this.domSanitizer.bypassSecurityTrustResourceUrl(
      code.url
    );
  }

  getWidgetCode() {
    return { ur: this.widgets.url, preview: this.widgets.preview };
  }

  showCopyAlert() {
    this.alertService.copiedToClipboard(this.words);
  }
}
