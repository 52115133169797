import { MenuItem } from "primeng/api";
import { Input, ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { StockChart } from "angular-highcharts";

@Component({
  selector: "app-widgets-generator",
  templateUrl: "./widgets-generator.component.html",
  styleUrls: ["./widgets-generator.component.scss"],
})
export class WidgetsGeneratorComponent implements OnInit {
  @Input("generator") generator;
  @Input("widgets") widgets;
  @Input("chartRef") chartRef: StockChart;
  @Input("selectedSeries") selectedSeries;
  @Input("periodicity") periodicity;
  @Input("datasetName") datasetName;
  @Input("words") words;
  @Input("measure") measure;
  @Input("extremes") extremes;
  steps: MenuItem[];
  availableWidgets: any[];
  activeIndex: number = 0;
  activeTab: string = "";
  loading: boolean = false;
  justifyOptions: any[];
  align: any;
  align2: any;
  value1: any;

  @ViewChild("chartWidget") chartWidget;
  @ViewChild("statisticsWidget") statisticsWidget;

  stateOptions = [
    { label: "Vertical", value: "off" },
    { label: "Horizontal", value: "on" },
    { label: "Custom", value: "fluid" },
  ];

  constructor() {}

  ngOnInit(): void {
    this.justifyOptions = [
      { icon: "pi pi-align-left", justify: "Left" },
      { icon: "pi pi-align-right", justify: "Right" },
      { icon: "pi pi-align-center", justify: "Center" },
    ];

    this.steps = [
      {
        label: "Choose Widgets",
      },
      {
        label: "Finalize",
      },
    ];
    this.activeTab = this.steps[0].label;

    this.availableWidgets = [
      {
        name: "Chart Widget",
        icon: "assets/imgs/widget-chart.png",
      },
      {
        name: "Statistics Widget",
        icon: "assets/imgs/widget-statistics.png",
      },
    ];
  }

  next() {
    let selectedWidgets = this.availableWidgets.filter((w) => w.selected);
    if (this.activeIndex == 0) {
      // Step 1, Choosing widgets
      let deselectedWidgets = this.availableWidgets.filter((w) => !w.selected);
      if (!selectedWidgets.length) return;
      selectedWidgets.map((w) => {
        if (!this.steps.find((s) => s.label == w.name))
          this.steps.splice(this.steps.length - 1, 0, { label: w.name });
      });
      deselectedWidgets.map((w) => {
        let idx = this.steps.findIndex((s) => s.label == w.name);
        if (idx > -1) this.steps.splice(idx, 1);
      });
    }
    this.activeIndex = this.activeIndex + 1;
    if (this.activeIndex == selectedWidgets.length + 1) {
      // Final step
      this.generator.showDialog = false;
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.activeTab = this.steps[this.activeIndex].label;
    }, 500);
  }

  back() {
    if (this.activeIndex == 0) return;
    this.activeIndex = this.activeIndex - 1;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.activeTab = this.steps[this.activeIndex].label;
    }, 500);
  }
}
