<p-progressBar
  *ngIf="loading"
  [style]="{ height: '6px' }"
  mode="indeterminate"
></p-progressBar>
<!-- <button (click)="exportImage()">PNG</button> -->
<div
  id="widget-chart-area"
  class="chart-area"
  [style]="{ width: size.w + 'px', height: size.h + 'px' }"
>
  <div
    style="overflow: hidden"
    [chart]="chart"
    id="chart-panel"
    class="chart"
  ></div>
  <button
    *ngIf="permalink"
    (click)="openPermalink()"
    type="button"
    pButton
    pRipple
    icon="pi pi-external-link"
    class="p-button-text more-info"
    pTooltip="Link to data source"
    tooltipPosition="left"
    [style]="{ 'font-size': '10px' }"
  ></button>
</div>
