import { BehaviorSubject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { ApisService } from "./apis.service";
import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class UserService {
  loggedUser: BehaviorSubject<any>;
  cookie: any = {};

  constructor(private apis: ApisService, private cookieService: CookieService) {
    this.initUserFromCookie();
    if (this.cookie.token) {
      this.refreshAndVerifyToken();
    }
  }

  private initUserFromCookie() {
    try {
      this.cookie = JSON.parse(this.cookieService.get("user") || "{}");
    } catch (error) {
      this.cookie = {};
    }
    this.loggedUser = new BehaviorSubject(this.cookie);
  }

  private refreshAndVerifyToken() {
    let userAPIs = this.apis.userAPIs();
    userAPIs
      .refresh(this.cookie.refresh)
      .pipe(switchMap((refresh: any) => userAPIs.verify(refresh.access)))
      .subscribe(
        (res: any) => this.updateCookieToken(res.access),
        (err) => {
          if (err.error.code === "token_not_valid") {
            this.logout();
          }
        }
      );
  }

  updateCookieToken(token: string) {
    this.cookie.access = token;
    this.cookieService.set("user", JSON.stringify(this.cookie));
    this.loggedUser.next(this.cookie);
    this.getUserGroups();
  }

  login(user) {
    return this.apis.userAPIs().login(user);
  }

  saveUser(user, token) {
    let cookie = JSON.stringify({
      username: user.username,
      token: token.access,
      refresh: token.refresh,
      isLogged: true,
    });
    this.cookieService.set("user", cookie);
    this.loggedUser.next(JSON.parse(cookie));
    this.cookie = cookie;
    this.getUserGroups();
  }

  logout() {
    this.cookieService.deleteAll();
    this.loggedUser.next({});
  }

  getUserGroups() {
    let cookie = this.cookie;
    if (typeof this.cookie === "string") cookie = JSON.parse(this.cookie);
    let token = cookie.token;
    this.apis.getUserGroups(token).subscribe((res: any) => {
      this.loggedUser.next({ ...this.loggedUser.value, groups: res.results });
    });
  }
}
