import { HelperService } from "src/app/services/helper.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Table } from "primeng/table";

@Component({
  selector: "app-statistics-table",
  templateUrl: "./statistics-table.component.html",
  styleUrls: ["./statistics-table.component.scss"],
})
export class StatisticsTableComponent implements OnInit {
  @Input("statistics") statistics;
  @Input("periodicity") periodicity;
  @Input("exportFilename") exportFilename;
  @Output() onCalculationRequest: EventEmitter<any> = new EventEmitter();
  @Output() onShowChartPopupRequest: EventEmitter<any> = new EventEmitter();
  @ViewChild("statisticsDataTable") statisticsDataTable: Table;

  constructor(private helper: HelperService) {}

  ngOnInit(): void {}

  onCalculationRequestTrigger(): void {
    this.onCalculationRequest.emit([this.statistics.currentOption]);
  }

  onShowChartPopupRequestTrigger(rowData): void {
    this.onShowChartPopupRequest.emit([rowData]);
  }

  customSort(event) {
    return this.helper.customSort(event, this.periodicity);
  }

  getTableRef() {
    return this.statisticsDataTable;
  }
}
