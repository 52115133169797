<p-dialog
  [appendTo]="'body'"
  [header]="'Upload CSV Prices'"
  [(visible)]="params.showUploadDialog"
  [baseZIndex]="2500"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [style]="{ width: '90%', maxHeight: '80%' }"
>
  <app-progress-bar [loading]="loading"></app-progress-bar>

  <form [formGroup]="form" id="upload-prices-csv-form">
    <input
      type="file"
      hidden
      id="upload-prices-csv"
      name="csv-file"
      (change)="uploadCSVInputChanged($event)"
    />
  </form>
  <h5 class="header-text">CSV Header Structure</h5>
  <div class="header-structure">
    <span
      >ID,ISO3-country-code,Price-Type,Periodicity,Market,Commodity,Date,Price-Value,Currency,Measure-Unit,Source</span
    >
  </div>

  <div class="file-requirements">
    <h5 class="header-text">CSV File Requirements</h5>
    <h6>Allowed extensions: .csv</h6>
    <h6>Maximum size: 1 Mb</h6>
  </div>

  <div *ngIf="table.rows.length">
    <div class="p-grid">
      <div class="p-col-6">
        <div class="warnings">
          <h5 class="header-text">
            Warnings
            <span *ngIf="warnings.length">({{ warnings.length }})</span>
          </h5>
          <h6 *ngIf="!warnings.length" style="color: green">- No warnings</h6>
          <div *ngIf="warnings.length">
            <h6 *ngFor="let war of warnings" style="color: red; margin: 0">
              - {{ war.text }}
            </h6>
          </div>
        </div>
      </div>
      <div class="p-col-6" *ngIf="file">
        <div class="file-details">
          <h5 class="header-text">File details</h5>
          <h6>
            <b>File name: </b> <i>{{ file.name }}</i>
          </h6>
          <h6>
            <b>File type: </b> <i>{{ file.type }}</i>
          </h6>
          <h6>
            <b>File size : </b> <i>{{ file.size }}</i>
          </h6>
          <h6>
            <b>Last modified: </b> <i>{{ file.lastModified }}</i>
          </h6>
        </div>
      </div>
    </div>
    <div class="container__table b-r-24">
      <h5 class="header-text">Summary</h5>
      <p-table
        [value]="table.rows"
        [columns]="table.cols"
        styleClass="p-datatable-sm p-datatable-striped table-highlight"
        class="p-table datagrid"
        [style]="{ fontSize: '.7rem' }"
      >
        <ng-template pTemplate="header">
          <tr style="height: 30px">
            <th style="width: 2em" *ngFor="let col of table.cols">
              <span
                [style]="{ display: col.field == 'INDEX' ? 'none' : 'block' }"
                >{{ col.field }}</span
              >
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td
              *ngFor="let col of table.cols"
              class="cut-text"
              [style]="{ color: rowData.warnings[col.field] ? 'red' : 'black' }"
            >
              <!-- [style]="{ color: rowData.hasWarning ? 'red' : 'black' }" -->
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="footer">
      <p-button
        [label]="'Choose file'"
        styleClass="p-button-success"
        [style]="{ width: '200px' }"
        (click)="triggerInput()"
        [disabled]="loading"
      ></p-button>
      <p-button
        [label]="'Confirm Upload'"
        styleClass="p-button-primary"
        [style]="{ width: '200px' }"
        (click)="uploadCSV()"
        [disabled]="loading || !table.rows.length"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
