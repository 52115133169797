<div class="title">
  <div *ngFor="let option of options; let i = index" style="display: inline">
    <ng-container *ngIf="!option.hide">
      <p-menu
        [appendTo]="chartFullscreen ? '' : 'body'"
        [style]="{ width: '200px', fontSize: '12px' }"
        #menu
        [popup]="true"
        [model]="option.children"
      ></p-menu>
      <span
        class="option"
        [class.active]="option.name == activeOption"
        (click)="
          option.children ? toggleMenu(i, $event, option) : toggle(option)
        "
        [class.largeMargin]="option.icon"
      >
        <fa-icon
          *ngIf="option.icon"
          [icon]="option.icon"
          [style]="{ 'margin-right': '5px' }"
        ></fa-icon>
        <span>{{ words[option.key] }}</span>
      </span>
    </ng-container>
  </div>
</div>
