import { BehaviorSubject } from "rxjs";
import { ApisService } from "./apis.service";
import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  configs: BehaviorSubject<any> = new BehaviorSubject(null);
  words: BehaviorSubject<any> = new BehaviorSubject(null);
  language: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private apis: ApisService) {
    this.apis.getAllSettings().subscribe((res: any) => {
      let settings = res.results;
      let configs: any = {};
      configs.languages = this.transformer.langLabelsTransformer(
        settings.find((s) => s.name == "languages-labels").data
      );
      configs.languagesTexts = this.transformer.langTextsTransformer(
        settings.find((s) => s.name == "languages-texts").data
      );
      let sidebar = this.transformer.sidebarTransformer(
        settings.find((s) => s.name == "sidebar").data
      );
      configs.sidebar = sidebar.sidebar;
      configs.datagrid = sidebar.datagrid;
      configs.internalpages = sidebar.internalpages;
      settings.map((s) => {
        if (s.name.includes("language-")) configs[s.name] = s.toLowerCase();
      });
      configs.toolCustomization = this.transformer.toolCustomizationTransformer(
        settings.find((s) => s.name == "tool-customization")
      );
      if (_.isEqual(this.configs.value, configs)) return;
      this.configs.next(configs);
    });
  }

  private transformer = {
    langLabelsTransformer: (data) => {
      let languages = [];
      data.map((d) => {
        let lang = { alt: "", title: "", key: "", default: false };
        lang.alt = d.name;
        lang.title = d.data.find((sd) => sd.name == "title").value;
        lang.key = d.data.find((sd) => sd.name == "key").value;
        lang.default = d.data.find((sd) => sd.name == "default").value;
        languages.push(lang);
      });
      return languages;
    },
    langTextsTransformer: (data) => {
      let langs = {};
      data.map((d) => {
        let keyPair = {};
        d.data.map((sd) => {
          keyPair[sd.name] = sd.value;
        });
        langs[d.name.toLowerCase()] = keyPair;
      });
      return langs;
    },
    sidebarTransformer: (data) => {
      let sidebar = [];
      let datagrid = {};
      let internalpages = {};
      data.map((d) => {
        let hasChildren = d.data.find((sd) => sd.name == "Children");
        if (!hasChildren) {
          let obj: any = {};
          d.data.map((sd) => (obj[sd.name] = sd.value));
          this.transformer.findInternalPage(d, internalpages);
          sidebar.push(obj);
        } else {
          let obj: any = {};
          obj.hidden = d.hidden;
          obj.labelkey = d.data.find((sd) => sd.name == "labelkey").value;
          obj.icon = d.data.find((sd) => sd.name == "icon").value;
          obj.items = [];
          d.data
            .find((sd) => sd.name == "Children")
            .data.map((sd) => {
              let item: any = {};
              this.transformer.findInternalPage(sd, internalpages);
              sd.data.map((ssd) => {
                if (ssd.name == "dataset-options") {
                  if (!datagrid[item.id]) datagrid[item.id] = {};
                  datagrid[item.id]["datasetOptions"] =
                    this.transformer.datasetOptionsTransformer(ssd.data);
                } else if (ssd.name == "columns") {
                  if (!datagrid[item.id]) datagrid[item.id] = {};
                  datagrid[item.id]["columns"] =
                    this.transformer.datagridTransformer(ssd.data);
                  datagrid[item.id]["url"] = item.url;
                  datagrid[item.id]["key"] = item.labelkey;
                } else {
                  if (!datagrid[item.id]) datagrid[item.id] = {};
                  item[ssd.name] = ssd.value;
                  if (item.origin) {
                    datagrid[item.id]["origin"] = item.origin;
                  }
                }
              });
              obj.items.push(item);
            });
          sidebar.push(obj);
        }
      });
      return { sidebar, datagrid, internalpages };
    },
    internalPagesTransformer: (data) => {
      data.url = this.apis.getURLFromSidebarItem(data);
      return data;
    },
    findInternalPage: (sd, internalpages) => {
      let internalpage = sd.data.find((ssd) => ssd.name == "internalpage") || {
        value:
          sd.data.find((ssd) => ssd.name == "type")?.value == "internalpage",
      };
      if (internalpage && JSON.parse(internalpage.value)) {
        let internalPageItem: any = {};
        sd.data.map((ssd) => (internalPageItem[ssd.name] = ssd.value));
        internalpages[internalPageItem.id] =
          this.transformer.internalPagesTransformer(internalPageItem);
      }
    },
    datasetOptionsTransformer: (data) => {
      let datasetOptions: any = {};
      data.map((d) => {
        if (d.name == "calculate-periodicities") {
          datasetOptions.calculatePeriodicities = [];
          if (d.data) {
            d.data.map((sd) => {
              if (sd.checked) {
                datasetOptions.calculatePeriodicities.push({
                  period: sd.name,
                  from: sd.value,
                });
              }
            });
          }
        }
        if (d.name == "week-start") {
          datasetOptions.weekStart = d.value;
        }
        if (d.name == "populate-nulls") {
          datasetOptions.populateNulls = JSON.parse(d.value || false);
        }
        if (d.name == "show-commodity-info") {
          datasetOptions.showCommodityInfo = JSON.parse(d.value || false);
        }
        if (d.name == "show-periodicities") {
          let showPeriodicities = d.data.filter((d) => d.checked);
          if (showPeriodicities.length == 0)
            showPeriodicities = d.data.map((d) => {
              d.checked = true;
              return d;
            });
          datasetOptions.showPeriodicities = showPeriodicities;
        }
        if (d.name == "show-map") {
          datasetOptions.showMap = JSON.parse(d.value || false);
        }
      });
      return datasetOptions;
    },
    datagridTransformer: (data, isPeriodicity?) => {
      if (isPeriodicity) {
        data = data.filter((d) => d.checked);
      }
      let columns = [];
      data.map((d) => {
        let column = {};
        let perColumn = null;
        d.data.map((sd) => {
          if (sd.data) {
            perColumn = columns.find((c) => c.field == "periodicities");
            if (!perColumn) {
              perColumn = { field: "periodicities", columns: [] };
              columns.push(perColumn);
            }
            perColumn.columns = this.transformer.datagridTransformer(
              d.data,
              true
            );
          } else {
            column[sd.name] = sd.value;
          }
        });
        if (!perColumn) columns.push(column);
        return column;
      });
      return columns;
    },
    toolCustomizationTransformer: (data) => {
      let browser = data.data.find((d) => d.name == "browser");
      document
        .getElementById("fpma-browser-icon")
        .setAttribute(
          "href",
          browser.data.find((d) => d.name == "browser-icon").value
        );
      document.getElementById("fpma-browser-title").innerHTML =
        browser.data.find((d) => d.name == "browser-title").value;
      let options: any = {};
      let header = data.data.find((d) => d.name == "header");
      options.headerColor = header.data.find(
        (d) => d.name == "header-color"
      ).value;
      options.fontColor = header.data.find((d) => d.name == "font-color").value;
      options.bottomLineColor = header.data.find(
        (d) => d.name == "bottom-line-color"
      ).value;
      options.headerLogos = header.data
        .find((d) => d.name == "header-logos")
        .data.map((d) => {
          let logo = {};
          d.data.map((l) => {
            logo[l.name] = l.value;
          });
          return logo;
        });
      options.home = {};
      data.data
        .find((d) => d.name == "home")
        .data.map((d) => {
          options.home[d.name] = d.value;
        });
      let sidebar = data.data.find((d) => d.name == "sidebar");
      if (sidebar) {
        options.showLicense = JSON.parse(
          sidebar.data.find((d) => d.name == "show-license").value
        );
      }
      return options;
    },
  };

  public getSettings() {
    return this.configs;
  }

  public getWords() {
    return this.words;
  }

  public getCurrentLanguage() {
    return this.language;
  }

  public updateWords(words, key) {
    if (!words) return;
    let prevWords = this.words.value;
    for (let k in prevWords) {
      if (!words[k] && prevWords[k]) {
        words[k] = prevWords[k];
      }
    }
    this.words.next(words);
    this.language.next(key);
  }
}
