import { HelperService } from "src/app/services/helper.service";
import { IpaCalculatorService } from "src/app/services/ipa-calculator.service";
import { ChartConversionsService } from "src/app/services/chart-conversions.service";
import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ChartTypesService {
  constructor(
    private chartConversions: ChartConversionsService,
    private ipaCalculator: IpaCalculatorService,
    private helper: HelperService
  ) {}

  switchToIpa(params) {
    params.chartTypes.ipaArray = params.ipaArray;
    params.chartTypes.selectedIPA = params.ipaArray[0];
    params.chartTypes.ipaColors = this.ipaCalculator.colors;
    params.chart.ref$.subscribe((c) => {
      while (c.series.length > 0) c.series[0].remove(true);
      c.xAxis[0].options.type = "datetime";
      c.xAxis[0].setCategories(null);
      // Save axes configurations
      if (params.fromTimeSeries) {
        let axes = [];
        if (c.yAxis[0].userOptions.visible) axes.push(0);
        if (c.yAxis[1].userOptions.visible) axes.push(1);
      }
      this.chartConversions.toggleAxisVisibility(params.chart, 0, false);
      this.chartConversions.toggleAxisVisibility(params.chart, 1, false);
      this.chartConversions.toggleAxisVisibility(
        params.chart,
        2,
        !params.isAnnual
      );
      this.chartConversions.toggleAxisVisibility(params.chart, 3, true);
      this.chartConversions.toggleAxisLocation(
        params.chart,
        3,
        params.isAnnual ? "left" : "right"
      );
      c.update({
        chart: {
          marginTop: 65,
          zoomType: "x",
        },
        xAxis: [
          {
            labels: {
              formatter: function () {
                var thisDateFormatter = params.isAnnual ? "YYYY" : "MMM-YY";
                return moment(new Date(this.value))
                  .utc()
                  .format(thisDateFormatter);
              },
            },
          },
        ],
        rangeSelector: {
          inputEnabled: true,
          enabled: true,
        },
        navigator: {
          enabled: true,
        },
        tooltip: this.helper.getMainChartTooltip("ipa", params.isAnnual),
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          },
          column: {
            pointWidth: params.isAnnual ? 10 : 5,
            borderRadius: params.isAnnual ? 1 : 0,
            dataGrouping: {
              enabled: false,
            },
          },
        },
      });
      this.addToIpaChart(
        c,
        params.ipaArray[0],
        params.chartTypes.ignoreNegative
      );
      c.xAxis[0].setExtremes(
        _.cloneDeep(params.extremes.dataMin),
        _.cloneDeep(params.extremes.dataMax)
      );
    });
    return params.chartTypes;
  }

  addToIpaChart(c, ipa, ignoreNegative) {
    if (!ipa) return;
    c.addSeries({
      id: ipa.seriesId + "-ipa",
      visible: true,
      data: ipa.ipaData,
      type: "column",
      name: "",
      zones: this.ipaCalculator.getZones(ignoreNegative),
      yAxis: 3,
    });
    c.addSeries({
      id: ipa.seriesId + "-real",
      visible: true,
      data: ipa.realDatapoints,
      type: "spline",
      name: "",
      color: "#007FFF",
      dashStyle: "ShortDot",
      yAxis: 2,
    });
  }

  switchToPercentageChange(params) {
    let percentageChangeData = { data: [], selected: null };
    let property = params.currency
      ? params.currency + "Datapoints"
      : "nominalDatapoints";
    params.series.map((s) => {
      let sorted = s[property];
      let filtered = sorted.filter((s, i) =>
        s[1] && sorted[i - 1] && sorted[i - 1][1] && i == sorted.length - 1
          ? true
          : sorted[i + 1] && sorted[i + 1][1]
      );
      let percentage = [];
      for (let i = 1; i < filtered.length; i++) {
        let change =
          ((filtered[i][1] - filtered[i - 1][1]) * 100) / filtered[i - 1][1];
        let value = Math.round(change * 100) / 100;
        percentage.push([filtered[i][0], value]);
      }
      percentageChangeData.data.push({
        seriesId: s.id,
        seriesName: this.chartConversions.getFullTitleFromSeries(
          s,
          null,
          null,
          true
        ),
        data: percentage,
        prices: s.nominalDatapoints,
      });
    });
    let selected =
      params.chartTypes.selectedPercentage || percentageChangeData.data[0];
    params.chartTypes.selectedPercentage = selected;
    params.chart.ref$.subscribe((c) => {
      while (c.series.length > 0) c.series[0].remove(true);
      this.chartConversions.switchChartOptionsToTimeSeries(
        c,
        params.periodicity,
        params.chartTypes
      );
      c.update({
        chart: {
          marginTop: 65,
          zoomType: "x",
        },
        xAxis: [
          {
            labels: {
              formatter: function () {
                var thisDateFormatter = "MMM-YY";
                return moment(new Date(this.value))
                  .utc()
                  .format(thisDateFormatter);
              },
            },
          },
        ],
        rangeSelector: {
          inputEnabled: true,
          enabled: true,
        },
        navigator: {
          enabled: true,
        },
        tooltip: this.helper.getMainChartTooltip("percentage", params.isAnnual),
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          },
          column: {
            pointWidth: 5,
            borderRadius: 0,
            dataGrouping: {
              enabled: false,
            },
          },
        },
      });
      this.addToPercentageChart(c, selected);
    });
    return [percentageChangeData, params.chartTypes];
  }

  addToPercentageChart(c, percentage) {
    c.addSeries({
      id: percentage.seriesId + "-percentage",
      visible: true,
      data: percentage.data,
      type: "column",
      name: "",
      zones: [
        {
          value: 0,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#00a84f"],
              [1, "green"],
            ],
          },
        },
        {
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "red"],
              [1, "#c70e0e"],
            ],
          },
        },
      ],
    });
    // c.addSeries({
    //   id: percentage.seriesId + "-real",
    //   visible: true,
    //   data: percentage.prices,
    //   type: "spline",
    //   name: "",
    //   color: "#007FFF",
    //   dashStyle: "ShortDot",
    //   yAxis: 2,
    // });
  }
}
