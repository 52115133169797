<p-progressBar
  *ngIf="loading"
  [style]="{
    height: '5px',
    position: 'fixed',
    top: '70px',
    zIndex: '2',
    right: 0,
    left: 0
  }"
  mode="indeterminate"
></p-progressBar>
