import { BehaviorSubject } from "rxjs";
import { AlertService } from "../../../services/alert.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperService } from "../../../services/helper.service";
import { Component, Input, OnInit } from "@angular/core";
import { StockChart } from "angular-highcharts";
import * as _ from "lodash";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-widget-chart-modal",
  templateUrl: "./widget-chart-modal.component.html",
  styleUrls: ["./widget-chart-modal.component.scss"],
})
export class WidgetChartModalComponent implements OnInit {
  @Input("widgets") widgets;
  @Input("chartRef") chartRef: StockChart;
  @Input("selectedSeries") selectedSeries;
  @Input("periodicity") periodicity;
  @Input("datasetName") datasetName;
  @Input("words") words;
  paramChanged: BehaviorSubject<string> = new BehaviorSubject<string>("");
  series: any[] = [];
  currencies = [];

  constructor(
    private helper: HelperService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.initWidget();
    this.computeWidget();
    this.initUnits();
  }

  initUnits() {
    this.currencies = this.helper.getWidgetsCurrencies();
  }

  seriesParamChanged() {
    this.paramChanged.next("");
  }

  initWidget() {
    this.series = _.cloneDeep(this.selectedSeries);
    this.series = this.series.map((s) => {
      s.color = this.helper.randDarkColor();
      return s;
    });
    this.paramChanged
      .pipe(debounceTime(500))
      .subscribe(() => this.computeWidget());
  }

  computeWidget() {
    let measure = this.chartRef.ref.yAxis[0].userOptions.title.text;
    let code = this.helper.getChartWidgetCode(
      this.series,
      this.widgets,
      measure,
      this.periodicity,
      this.datasetName
    );
    this.widgets.preview = code.previewCode;
    this.widgets.url = this.domSanitizer.bypassSecurityTrustResourceUrl(
      code.url
    );
  }

  getWidgetCode() {
    return { ur: this.widgets.url, preview: this.widgets.preview };
  }

  showCopyAlert() {
    this.alertService.copiedToClipboard(this.words);
  }
}
