import { HelperService } from "./helper.service";
import { ChartConversionsService } from "./chart-conversions.service";
import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class IpaCalculatorService {
  constructor(
    private chartConversions: ChartConversionsService,
    private helper: HelperService
  ) {}

  colors: any = {
    grey: "#CCCCCC",
    red: "#FF0000",
    yellow: "#FFFF00",
    green: "#00FF00",
  };
  zones: any[] = [
    {
      value: -1.0,
      color: this.colors.red,
      canBeIgnored: true, //grey
    },
    {
      value: -0.5,
      color: this.colors.yellow,
      canBeIgnored: true, //grey
    },
    {
      value: 0.0,
      color: this.colors.green,
    },
    {
      value: 0.5,
      color: this.colors.green, // green
    },
    {
      value: 1.0,
      color: this.colors.yellow, // yellow
    },
    {
      color: this.colors.red,
    },
  ];

  getZones(ignoreNegative) {
    let zones = _.cloneDeep(this.zones);
    if (ignoreNegative) {
      zones = zones
        .filter((z) => !z.canBeIgnored)
        .map((z) => {
          if (z.value == 0.0) z.color = this.colors.grey;
          return z;
        });
    }
    return zones;
  }

  getIpaArray(selectedSeries, currency, measure, isAnnual?) {
    let ipaArray = [];
    selectedSeries.map((s) => {
      let realDatapoints = s.realDatapoints.filter((d) => d[1]);
      let ipaDataset = this.getIPASeries(realDatapoints);
      if (!ipaDataset) return;
      let ipaObject = {
        seriesId: s.id,
        seriesName:
          this.chartConversions.getFullTitleFromSeries(s, null, null, true) +
          " - IPA",
        ipaData: _.slice(ipaDataset, 24, ipaDataset.length).map((ipa) => [
          ipa[0],
          ipa[1] != null
            ? Number((Math.round(ipa[1] * 100) / 100).toFixed(2))
            : null,
        ]),
      };
      if (!isAnnual) ipaObject["realDatapoints"] = realDatapoints;
      ipaArray.push(ipaObject);
    });
    if (isAnnual) return this.calculateAnnualAverages(ipaArray);
    return ipaArray;
  }

  calculateAnnualAverages(ipaArray) {
    ipaArray.map((ipa) => {
      let annual = [];
      let data = ipa.ipaData;
      // Check first January that has a value
      data = data.filter((d) => d[1] != null);
      let idx = data.findIndex((d) => moment(d[0]).month() == 0);
      for (let i = idx + 1; i < data.length; i++) {
        const el = data[i];
        if (moment(el[0]).month() == 0) {
          let date = moment(el[0]).subtract(1, "year").toDate().getTime();
          let val = _.mean(data.slice(idx, i).map((d) => d[1]));
          annual.push([date, Math.round(val * 100) / 100]);
          idx = i;
        }
      }
      ipa.ipaData = annual;
      return ipa;
    });
    return ipaArray;
  }

  getThresholdColorFromZones(x: any, ignoreNegative?) {
    if (!x) return;
    let zones = _.cloneDeep(this.getZones(ignoreNegative));
    zones[zones.length - 1].value = Number.POSITIVE_INFINITY;
    zones.push({ id: "row", value: x });
    zones = zones.sort((a, b) => (a.value > b.value ? 1 : -1));
    return zones[zones.findIndex((z) => z.id == "row") + 1].color;
  }

  /*
   * getIPASeries
   * Feliz's formula : in the 4 steps
   * see http://www.fao.org/giews/food-prices/research/detail/en/c/235685/
   * 	Step 1. Calculating the quarterly and annual compound growth rates
   * 	Step 2. Calculating the time weights
   * 	Step 3. Calculating the weighted average and standard deviation.
   * 	Step 4. Obtaining the indicator of price anomalies
   */
  private getIPASeries(aSeries) {
    if (!aSeries) return;
    if (
      moment(aSeries[aSeries.length - 1][0]).diff(
        moment(aSeries[0][0]),
        "years"
      ) < 2
    )
      return [];
    var startYear: any = moment(aSeries[0][0]).utc().format("YYYY");
    var endYear: any = moment(aSeries[aSeries.length - 1][0])
      .utc()
      .format("YYYY");
    var years = endYear - startYear + 1;
    var startYearNumber = moment(aSeries[0][0]).utc().year();

    var weightMM = Array(years);
    var weightYY = Array(years - 1);
    var weightQW = Array(years);
    var weightAN = Array(years - 1);
    var QWA = Array(years);
    var AWA = Array(years - 1);
    var QWDS = Array(years);
    var AWSD = Array(years - 1);

    for (var i = 0; i < years; i++) {
      weightMM[i] = new Array(years - 1);
      weightQW[i] = new Array(years - 1);
      QWA[i] = new Array(12);
      QWDS[i] = new Array(12);
    }
    for (var i = 0; i < years - 1; i++) {
      weightYY[i] = new Array(years - 2);
      weightAN[i] = new Array(years - 2);
      AWA[i] = new Array(12);
      AWSD[i] = new Array(12);
    }

    /*
     * Step 1. Calculating the quarterly and annual compound growth rates
     */
    var CQGR = this.getCQGRSeries(aSeries);
    var CAGR = this.getCAGRSeries(aSeries);
    var temp1 = _.cloneDeep(CAGR);
    var temp2 = _.cloneDeep(CQGR);
    var CQGRInMonth = _.unzip(_.chunk(_.cloneDeep(temp2), 12));
    var CAGRInMonth = _.unzip(_.chunk(_.slice(temp1, 12, temp1.length), 12));

    /*
     * 	Step 2. Calculating the time weights
     */

    for (var i = 0; i < years; ++i) {
      for (var j = 0; j < years - 1; ++j) {
        weightMM[i][j] = j < i ? 0 : i + 1;
      }
    }
    for (var i = 0; i < years; ++i) {
      if (i == 0) {
        weightMM[i].unshift(1);
      } else {
        weightMM[i].unshift(0);
      }
    }

    for (var i = 0; i < years - 1; ++i) {
      for (var j = 0; j < years - 2; ++j) {
        weightYY[i][j] = j < i ? 0 : i + 1;
      }
    }

    for (var i = 0; i < years - 1; ++i) {
      if (i == 0) {
        weightYY[i].unshift(1);
      } else {
        weightYY[i].unshift(0);
      }
    }

    for (var col = 0; col < years; ++col) {
      var SUM = this.sumColumn(weightMM, col);
      for (var row = 0; row < years; ++row) {
        if (row == 0 && col == 0) {
          weightQW[row][col] = 0;
        } else {
          weightQW[row][col] =
            weightMM[row][col] == 0 ? 0 : weightMM[row][col] / SUM;
        }
      }
    }
    for (var col = 0; col < years - 1; ++col) {
      var SUM = this.sumColumn(weightYY, col);
      for (var row = 0; row < years - 1; ++row) {
        if (row == 0 && col == 0) {
          weightAN[row][col] = 0;
        } else {
          weightAN[row][col] =
            weightYY[row][col] == 0 ? 0 : weightYY[row][col] / SUM;
        }
      }
    }
    var QW_YEAR = _.unzip(weightQW);
    var AN_YEAR = _.unzip(weightAN);
    /*
               * 
               * Step Three
               * 			 * 	Step 3. Calculating the weighted average and standard deviation.
  
               * Quaterly Weighted Average  (QWA)
                      =SUMPRODUCT(QTY[MM];QW_[YY]/SUM(QW_[YY]) 
                      QTY[MM] = Array all  the price values of the Month (JAN,..) from the startyear and endyear (12 values)
                      QW_[YEAR] = Array of the quarterly time weight of Year 
                      SUM(QW_[YEAR]) is equals to 1 for all YEAR > STARTYEAR
                      ie. =SUMPRODUCT(QTYAPR;QW_2007)/SUM(QW_2007)
               * 
               * Annual Weighted Average (AWA)
                      =SUMPRODUCT(AN[X=YEAR-STARTYEAR-2];AN_[YEAR]/SUM(AN_[YEAR])
                      AN[YEAR] = YEAR-STARTYEAR-2
                      AN_[YEAR] =  Array of annual time weights from StartYear+1 to EndYear
               * Quarterly Weighted Standard Deviation (QWSD)
                      =SQRT(SUMPRODUCT(QW_2008*(QTYJAN-D3)^2)/SUM(QW_2014)*(QN14_-1)/QN14_)
               * Annual Weighted Standard Deviation
                      =SQRT(SUMPRODUCT(AN_2009*(ANJAN-Q3)^2)/SUM(AN_2009)*(AN_09-1)/AN_09)
               * 
               **/
    var QWArepo = _.cloneDeep(CQGR);
    var AWArepo = _.cloneDeep(CAGR);
    for (var i = 0; i != QWArepo.length; ++i) {
      if (i < 3 + 12) {
        QWArepo[i].y = null;
        QWArepo[i]["QWAValue"] = null;
      } else {
        var yN = parseInt((i / 12).toString()) - 1;
        var month = moment(QWArepo[i].x).utc().format("MMM");
        var monthNumber = moment(QWArepo[i].x).utc().month();
        var wValues = QW_YEAR[yN + 1]; //Skipped first element because always 0;
        var vSeries = CQGRInMonth[monthNumber];
        var value = this.sumProducts(vSeries, wValues);
        QWArepo[i].y = value;
        QWArepo[i]["yearNumber"] = yN;
        QWArepo[i]["month"] = month;
        QWArepo[i]["monthNumber"] = monthNumber;
        QWArepo[i]["QWAverage"] = value;
      }
    }
    for (var i = 0; i != AWArepo.length; ++i) {
      if (i < 12 + 12) {
        AWArepo[i].y = null;
        AWArepo[i]["AWAValue"] = null;
      } else {
        var yN = parseInt((i / 12).toString()) - 1;
        var wValues = AN_YEAR[yN];
        var month = moment(AWArepo[i].x).utc().format("MMM");
        var monthNumber = moment(AWArepo[i].x).utc().month();
        var vSeries = CAGRInMonth[monthNumber];
        var value = this.sumProducts(vSeries, wValues);
        AWArepo[i].y = value;
        AWArepo[i]["yearNumber"] = yN;
        AWArepo[i]["month"] = month;
        AWArepo[i]["monthNumber"] = monthNumber;
        AWArepo[i]["AWAverage"] = value;
      }
    }

    var QWDSrepo = _.cloneDeep(QWArepo);
    var AWSDrepo = _.cloneDeep(AWArepo);

    /* jump of 2 years 24 months for startyear */
    for (var i = 0 /*3+12*/; i != QWDSrepo.length; ++i) {
      var obj = QWDSrepo[i];
      if (i < 12 + 12) {
        QWDSrepo[i]["QWDSValue"] = null;
        continue;
      }
      QWDSrepo[i]["QWDSValue"] = null;
      var yN = Number(obj.yearNumber);
      var monthNumber = moment(obj.x).utc().month();
      var yearNumber = moment(obj.x).utc().year();
      var N = yearNumber - startYearNumber;
      var CXGR = _.cloneDeep(CQGRInMonth[monthNumber]);
      var prodR = 0;
      var QWAverage = QWArepo[i]["QWAverage"];
      var Weights: any = QW_YEAR[yN + 1];
      var refAr = _.map(CXGR, function (n) {
        return n === undefined || n.y === undefined
          ? undefined
          : Math.pow(n.y - QWAverage, 2);
      });
      for (var z = 0; z != refAr.length; ++z) {
        if (refAr[z] === undefined || Weights === undefined) {
          continue;
        }
        prodR += refAr[z] * Weights[z];
      }
      var sumR = _.reduce(
        Weights,
        function (sum, n) {
          return sum + (n * (N - 1)) / N;
        },
        0
      );
      var v = Math.sqrt(prodR / sumR);
      obj["QWAverage"] = QWAverage;
      obj["QWDSValue"] = v;
    }

    /* jump of 2 years 36 months for startyear */
    for (var i = 0 /*12+12*/; i != AWSDrepo.length; ++i) {
      var obj = AWSDrepo[i];
      if (i < 12 + 12 + 12) {
        continue;
      }
      var yN = Number(obj.yearNumber);
      var wValues: unknown[] = AN_YEAR[yN];
      var monthNumber = moment(obj.x).utc().month();
      var vSeries = _.cloneDeep(CAGRInMonth[monthNumber]);
      var yearNumber = moment(obj.x).utc().year();
      var N = yearNumber - startYearNumber - 1;
      var result = 0;
      var v = 0;
      //Rivedere
      var AWAverage = AWSDrepo[i]["AWAverage"];
      var refAr = _.map(vSeries, function (n) {
        return n === undefined || n.y === undefined
          ? undefined
          : Math.pow(n.y - AWAverage, 2);
      });
      for (var z = 0; z != refAr.length; ++z) {
        if (refAr[z] === undefined || wValues === undefined) {
          continue;
        }
        result += refAr[z] * Number(wValues[z]);
      }
      var resultSum = _.reduce(
        wValues,
        function (sum, n: any) {
          return sum + (n * (N - 1)) / N;
        },
        0
      );
      v = Math.sqrt(result / resultSum);

      obj["AWSDValue"] = v;
    }

    /*
     * 	Step 4. Obtaining the indicator of price anomalies
     *
     * Quarterly_IPA(YYYY-MM)= (CQGR(YYYY-MM)- QWA(YYYY-MM))/ QWSD(YYYY-MM)
     * Annual_IPA(YYYY-MM)= (CQGR(YYYY-MM)- AWA(YYYY-MM))/ AWSD(YYYY-MM)
     * IPA(YYYY-MM)=0,4*Quarterly_IPA+0,6*Annual_IPA
     */
    var Annual_IPA = _.cloneDeep(AWSDrepo);
    var Quarterly_IPA = _.cloneDeep(QWDSrepo);
    for (var i = 12 + 12; i != Quarterly_IPA.length; ++i) {
      var obj = Quarterly_IPA[i];
      if (obj["QWDSValue"] !== null) {
        obj["Quarterly_IPAValue"] =
          (CQGR[i].y - obj["QWAverage"]) / obj["QWDSValue"];
      } else {
        obj["Quarterly_IPAValue"] = null;
      }
    }
    for (var i = 12 + 12 + 12; i != Annual_IPA.length; ++i) {
      var obj = Annual_IPA[i];
      if (obj["AWSDValue"] !== 0) {
        obj["Annual_IPAValue"] =
          (CAGR[i].y - obj["AWAverage"]) / obj["AWSDValue"];
      } else {
        obj["Annual_IPAValue"] = null;
      }
    }

    var IPASeries = _.cloneDeep(Annual_IPA);
    for (var i = 12 + 12 + 12; i != IPASeries.length; ++i) {
      var obj = IPASeries[i];
      var qobj = Quarterly_IPA[i];
      if (
        qobj["Quarterly_IPAValue"] !== null &&
        obj["Annual_IPAValue"] !== null
      ) {
        obj["IPASeries"] =
          0.4 * qobj["Quarterly_IPAValue"] + 0.6 * obj["Annual_IPAValue"];
      } else {
        obj["IPASeries"] = null;
      }
      obj["y"] = obj["IPASeries"];
    }

    var retIPA = _.cloneDeep(aSeries);
    for (var i = 0; i != IPASeries.length; ++i) {
      if (i < 12 + 12 + 12) {
        retIPA[i][1] = null;
        continue;
      }
      var value = IPASeries[i].y;
      if (
        value == Number.POSITIVE_INFINITY ||
        value == Number.NEGATIVE_INFINITY
      ) {
        retIPA[i][1] = null;
      } else {
        retIPA[i][1] = value;
      }
    }
    return retIPA;
  }

  private getCommonGrowthRates(aSeries, jumps) {
    var retSeries = [];
    for (var i = 0; i < aSeries.length; i++) {
      var valueItem = aSeries[i][1];
      var obj = {
        x: aSeries[i][0],
        date: moment(aSeries[i][0]).utc().format("MMM-YY"),
        y: null,
      };
      if (i >= jumps && valueItem != null && aSeries[i - jumps][1] != null) {
        obj.y = Math.pow(valueItem / aSeries[i - jumps][1], 1 / jumps) - 1;
      }
      retSeries.push(obj);
    }
    return retSeries;
  }

  private getCQGRSeries(aSeries) {
    return this.getCommonGrowthRates(aSeries, 3);
  }

  private getCAGRSeries(aSeries) {
    return this.getCommonGrowthRates(aSeries, 12);
  }

  private sumProducts(CXGR_OfTheMonth, yearWeighs) {
    var CXGR_OfTheMonthCopy = _.cloneDeep(CXGR_OfTheMonth);
    var yearWeighsCopy = _.cloneDeep(yearWeighs);
    if (CXGR_OfTheMonthCopy.length && yearWeighsCopy != undefined) {
      var cxgr = CXGR_OfTheMonthCopy.pop();
      var weight = yearWeighsCopy.pop();
      var cxgrValue = cxgr === undefined ? 0 : cxgr.y;
      var weightValue = weight === undefined ? 0 : weight;
      return (
        cxgrValue * weightValue +
        this.sumProducts(CXGR_OfTheMonthCopy, yearWeighsCopy)
      );
    }
    return 0;
  }

  private sumColumn(matrix, column) {
    var size = matrix.length;
    var sum = 0;
    for (var i = 0; i < size; i++) {
      sum += matrix[i][column];
    }
    return sum;
  }
}
