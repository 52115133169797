<p-dialog
  [appendTo]="'body'"
  [header]="admin.operation | titlecase"
  [(visible)]="admin.showDialog"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [style]="{ 'max-width': '90%' }"
>
  <app-progress-bar [loading]="loading"></app-progress-bar>

  <div *ngIf="admin.operation == 'add'">
    <div class="p-d-flex p-fluid series">
      <div class="serie serie-add" *ngFor="let s of series">
        <div class="serie-content">
          <h5>{{ s.name }}</h5>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"
                  ><i class="pi pi-calendar"></i
                ></span>
                <p-calendar
                  tabindex="-1"
                  [(ngModel)]="s.data.date"
                  appendTo="body"
                  utc="true"
                  [touchUI]="true"
                  placeholder="Date"
                  [view]="options.view"
                  [dateFormat]="options.dateFormat"
                  readonlyInput="true"
                ></p-calendar>
              </div>
            </div>
            <div class="p-col-12 p-md-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"
                  ><i class="pi pi-money-bill"></i
                ></span>
                <input
                  type="number"
                  [(ngModel)]="s.data.price_value"
                  (keyup.enter)="add()"
                  pInputText
                  [placeholder]="words['key-admin-add-price_value']"
                  autofocus
                  (ngModelChange)="addValueChanged(s)"
                />
              </div>
              <div style="margin-top: 10px" *ngIf="s.warnings">
                <span
                  *ngFor="let warning of s.warnings"
                  class="warning"
                  [class]="warning.class"
                  >{{ warning.text }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="admin.operation == 'delete'">
    <ul>
      <li style="margin-bottom: 10px" *ngFor="let serie of admin.params">
        {{ serie.name }} <br />
        {{ words["key-admin-delete-date"] }}: <b>{{ serie.date }}</b> <br />
        {{ words["key-admin-delete-price"] }}: <b>{{ serie.price_value }}</b>
      </li>
    </ul>
  </div>

  <ng-template pTemplate="footer" *ngIf="admin.operation == 'add'">
    <p-button
      [label]="words['key-admin-add-enter']"
      styleClass="p-button-success"
      (click)="add()"
      [disabled]="loading"
    ></p-button>
  </ng-template>

  <ng-template pTemplate="footer" *ngIf="admin.operation == 'delete'">
    <p-button
      [label]="words['key-admin-delete-confirm']"
      styleClass="p-button-danger"
      (click)="delete()"
      [disabled]="loading"
    ></p-button>
  </ng-template>
</p-dialog>
