import { AlertService } from "src/app/services/alert.service";
import { ApisService } from "src/app/services/apis.service";
import { HelperService } from "src/app/services/helper.service";
import { UserService } from "src/app/services/user.service";
import { Component, Input, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import * as moment from "moment-timezone";

@Component({
  selector: "app-crud-modal",
  templateUrl: "./crud-modal.component.html",
  styleUrls: ["./crud-modal.component.scss"],
})
export class CrudModalComponent implements OnInit {
  visible = true;
  @Input("admin") admin;
  @Input("words") words;
  series: any[] = [];
  options: any = {};
  periodicity: any;
  token;
  loading: boolean = false;
  warnings: any[] = [];
  constructor(
    private userService: UserService,
    private helper: HelperService,
    private apis: ApisService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.userService.loggedUser.subscribe((user) => {
      this.token = user.token;
    });
    if (this.admin.operation == "add") {
      this.periodicity = this.admin.params.periodicity;
      let series = this.admin.params.series;
      if (this.periodicity == "monthly") {
        this.options = { view: "month", dateFormat: "M-y" };
      } else {
        this.options = { view: "date", dateFormat: "dd-M-y" };
      }
      series = series.map((s) => {
        let datapoints = s.datapoints.filter((d) => d[1]).map((d) => d[1]);
        let std = this.helper.getStandardDeviation(datapoints);
        let mean = datapoints.reduce((a, b) => a + b) / datapoints.length;
        let SDRange = {
          min: this.helper.roundDecimal((mean - std) * 1.2, 2),
          max: this.helper.roundDecimal((mean + std) * 1.2, 2),
        };
        s.SDRange = SDRange;
        s.data = {};
        s.latestDate = s.datapoints.length
          ? s.datapoints[s.datapoints.length - 1][0]
          : null;
        if (s.latestDate) {
          s.data.date = this.getNextDate(moment(s.latestDate));
        } else s.data.date = moment().toDate();
        s.latestValue = s.datapoints.length
          ? s.datapoints[s.datapoints.length - 1][1]
          : null;
        return s;
      });
      this.series = series;
    }
  }

  getNextDate(date) {
    switch (this.periodicity) {
      case "monthly":
        // date.setMonth(date.getMonth() + 1);
        date = moment(date).add(1, "month");
        break;
      case "weekly":
        // date.setDate(date.getDate() + 7);
        date = moment(date).add(1, "week");
        break;
      case "daily":
        // date.setDate(date.getDate() + 1);
        date = moment(date).add(1, "day");
        break;
    }
    return date.toDate();
  }

  delete() {
    let ids = this.admin.params.map((p) => p.id);
    let requests = [];
    ids.map((id) => {
      requests.push(this.apis.adminAPIs(this.token).deleteDatapoint(id));
    });
    this.loading = true;
    forkJoin(requests).subscribe((results) => {
      this.alertService.deleteDatapoint(ids.length, this.words);
      this.loading = false;
      this.admin.showDialog = false;
      this.admin.reload();
      this.admin.selectedRow = null;
    });
  }

  add() {
    // Check data
    let filtered = this.series.filter((s) => s.data.price_value);
    if (!filtered.length) return;
    let requests = [];
    filtered.map((s) => {
      let data = {
        uuid: s.uuid,
        price_value: s.data.price_value,
        date: moment
          .utc(s.data.date.toString().split("00:00:00")[0])
          .format("YYYY-MM-DD"),
        periodicity: this.periodicity,
      };
      requests.push(this.apis.adminAPIs(this.token).addDatapoint(s.uuid, data));
    });
    forkJoin(requests).subscribe((results) => {
      this.alertService.addDatapoint(filtered.length, this.words);
      this.loading = false;
      this.admin.showDialog = false;
      this.admin.reload();
      this.admin.selectedRow = null;
    });
  }

  addValueChanged(series) {
    series.warnings = [];
    if (series.data.price_value > series.SDRange.max) {
      series.warnings.push({
        text: `- Value ${series.data.price_value} is higher than SD range ${series.SDRange.max}`,
        class: "red",
      });
    }
    if (series.data.price_value < series.SDRange.min) {
      series.warnings.push({
        text: `- Value ${series.data.price_value} is lower than SD range ${series.SDRange.min}`,
        class: "red",
      });
    }
    if (
      series.data.price_value <= series.SDRange.max &&
      series.data.price_value >= series.SDRange.min
    ) {
      series.warnings.push({
        text: `- Value is within SD range ${series.SDRange.min} - ${series.SDRange.max}`,
        class: "green",
      });
    }
    if (series.latestValue) {
      let newValue = series.data.price_value;
      let oldValue = series.latestValue;
      let percentage = ((newValue - oldValue) * 100) / oldValue;
      let range = 20;
      let withinRange = Math.abs(percentage) < range;
      series.warnings.push({
        text: `- Percentage change from latest date value ${this.helper.roundDecimal(
          percentage,
          2
        )}%`,
        class: withinRange ? "green" : "red",
      });
    }
  }
}
