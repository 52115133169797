import { ApisService } from "src/app/services/apis.service";
import { SettingsService } from "src/app/services/settings.service";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  widgets: any = {};
  widgetsContent: any = [];
  url: any = "";

  constructor(
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    private apis: ApisService
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngOnInit() {
    this.settingsService.getSettings().subscribe((configs: any) => {
      if (!configs) return;
      this.settingsService.getCurrentLanguage().subscribe((lang) => {
        if (!lang) return;
        let home = configs.toolCustomization.home;
        let re = /(?:\.([^.]+))?$/;
        let extension = re.exec(home.url.split("/").pop())[1];
        let hasExtension = Boolean(extension);
        if (hasExtension) home.url = home.url.replace(`.${extension}`, "");
        if (home.url.includes(home.language_postfix || "__"))
          home.url = home.url.split(home.language_postfix || "__")[0];
        if (home.multilingual && JSON.parse(home.multilingual.toLowerCase())) {
          let postfix = home.language_postfix ? home.language_postfix : "__";
          if (hasExtension) home.url += `${postfix}${lang}.${extension}`;
        } else {
          if (hasExtension) home.url += `.${extension}`;
        }
        let url = this.apis.getURLFromSidebarItem(home);
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });
    });
  }

  emptyArray(n) {
    return new Array(n);
  }
}
