import { SettingsService } from "../../services/settings.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "app-toggler",
  templateUrl: "./toggler.component.html",
  styleUrls: ["./toggler.component.scss"],
})
export class TogglerComponent implements OnInit {
  togglers: any = {};
  options: any[] = [];
  activeOption: string;
  words: any = {};
  @Input("name") name: string;
  @Input("chartFullscreen") chartFullscreen: boolean;
  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren("menu") menus;
  children: any = [
    {
      name: "Statistics",
      children: [
        {
          labelkey: "key-panel-main-statistics-time_period",
          icon: "pi pi-calendar",
          command: () => {
            this.toggleFromMenu("periodicity");
          },
        },
        {
          labelkey: "key-panel-main-statistics-percentage",
          icon: "pi pi-calendar",
          command: () => {
            this.toggleFromMenu("percentage");
          },
        },
        {
          labelkey: "key-panel-main-statistics-ipa_series",
          icon: "pi pi-calendar",
          command: () => {
            this.toggleFromMenu("ipa");
          },
        },
      ],
    },
  ];
  currentOption: any;

  constructor(
    private helper: HelperService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.settingsService.getWords().subscribe((words) => {
      if (!words) return;
      this.words = words;
    });
    this.init();
  }

  setActiveToggler(name, active) {
    this.togglers[name].active = active;
  }

  init() {
    let togglers = this.helper.getTogglersData();
    for (let k in togglers) {
      togglers[k].data.map((d) => {
        this.children.map((c) => {
          if (c.name == d.name) {
            d.children = c.children;
          }
        });
        return d;
      });
    }
    this.togglers = togglers;
    this.options = this.togglers[this.name].data;
    this.activeOption = this.togglers[this.name].active;
    this.helper.setTogglerData(this.togglers);
  }

  toggle(option: any, param?) {
    this.activeOption = option.name;
    this.togglers[this.name].active = option.name;
    let data = {
      name: this.name,
      label: this.togglers[this.name].label,
      data: this.togglers[this.name],
      callback: option.callback,
    };
    if (param) {
      data["param"] = param;
    }
    this.onSelection.emit(data);
    this.helper.setTogglerData(this.togglers);
  }

  toggleMenu(i: any, e: any, option: any) {
    if (option.children)
      option.children.map((o) => {
        o.label = this.words[o.labelkey];
      });
    let menus = this.menus.toArray();
    menus[i].toggle(e);
    this.currentOption = option;
  }

  toggleFromMenu(type: any) {
    this.toggle(this.currentOption, type);
  }
}
