import { StatisticsTableComponent } from "./../../components/statistics-table/statistics-table.component";
import { UserService } from "./../../services/user.service";
import { ChartTypesService } from "src/app/services/chart-types.service";
import { IpaCalculatorService } from "src/app/services/ipa-calculator.service";
import { AlertService } from "src/app/services/alert.service";
import { SettingsService } from "src/app/services/settings.service";
import { ChartConversionsService } from "src/app/services/chart-conversions.service";
import { MarketSeasonService } from "src/app/services/market-season.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ApisService } from "../../services/apis.service";

import { StockChart } from "angular-highcharts";
import * as Highstock from "highcharts/highstock";
import { Options, SeriesOptionsType } from "highcharts";
import { HelperService } from "../../services/helper.service";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import { ActivatedRoute, Router } from "@angular/router";
import { Table } from "primeng/table";
import { TogglerComponent } from "../../components/toggler/toggler.component";
import { StatisticsService } from "src/app/services/statistics.service";
import { FilterService } from "primeng/api";
import { RadioControlRegistry } from "primeng/radiobutton";
import { ChartDataService } from "src/app/services/chart-data.service";

import { MapInfoWindow, GoogleMap } from "@angular/google-maps";
import { fadeIn } from "src/app/misc/animations";

@Component({
  selector: "app-tool",
  templateUrl: "./tool.component.html",
  styleUrls: ["./tool.component.scss"],
  providers: [FilterService, RadioControlRegistry],
  animations: [fadeIn],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolComponent implements OnInit {
  titleKey = "";
  togglers: any = {};
  rows: any[] = [];
  cols: any[] = [];
  colors: any[];
  showLegend: boolean = true;
  selectedSeries: any[] = [];
  fullscreen: any = { table: false, chart: false };
  chartTypes: any = this.helper.getChartTypes();
  currencies: any = {};
  measures: any = {};
  periodicities: any = {};
  chart = new StockChart(this.helper.getChartConfigs());
  fields: any[] = [];
  categories: any[] = [];
  datasetId: any = "";
  filters: any = {};
  filtersStock: any;
  appliedFilters: any = {};
  loading: boolean;
  loader: any = {};
  chartData: any = { rows: [], cols: [] };
  information: any = { rows: [], cols: [] };
  @ViewChild("dt") table: Table;
  @ViewChild("chartDataTable") chartDataTable: Table;
  @ViewChild("statisticsDataTable") statisticsDataTable: Table;
  @ViewChild("informationDataTable") informationDataTable: Table;
  @ViewChild(StatisticsTableComponent)
  statisticsTableComponent: StatisticsTableComponent;
  @ViewChildren(TogglerComponent) togglersElements: QueryList<TogglerComponent>;
  @ViewChildren(TogglerComponent) currencyToggler;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  mapObject: any = this.helper.getMapObject();
  words: any = {};
  footer: string = "";
  hoverPoints: { x: any; y: any } = { x: null, y: null };
  filteredGrid: any;
  getPointsSubscriber: any = [];
  gridSubscriber: any;
  statistics: any = this.helper.getStatisticsTemplate();
  extremes: any = { min: null, max: null };
  markets: any = { data: [], markers: [] };
  marketSeasonData: any;
  percentageChangeData: any;
  paginator: any = { first: 0, rows: 8 };
  showPopup: boolean = false;
  exporter: any = this.helper.getExporterTemplate();
  permalink: any = { showDialog: false };
  widgets: any = this.helper.getWidgetsTemplate();
  datasetOptions: any = {};
  user: any = {};
  userSubscriber: any;
  admin: any = {
    tempUpdate: {},
    showUploadDialog: false,
  };
  currentLang: any;
  widgetsGenerator: any = { showDialog: false };
  firstRun = true;
  remoteDataset: boolean;

  constructor(
    private apis: ApisService,
    private helper: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    public statisticsService: StatisticsService,
    private marketSeasonService: MarketSeasonService,
    private filterService: FilterService,
    private chartDataService: ChartDataService,
    private chartConversions: ChartConversionsService,
    private alertService: AlertService,
    private settingsService: SettingsService,
    private ipaCalculator: IpaCalculatorService,
    private chartTypesService: ChartTypesService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.initTool(params.id);
    });
  }

  initTool(id) {
    this.getApisData(id);
    this.observeExtremes();
    this.configChart();
    this.initUser();
  }

  initUser() {
    if (this.userSubscriber) return;
    this.userSubscriber = this.userService.loggedUser.subscribe((res: any) => {
      this.user = res;
      this.filterColumnsPeriodicities();
    });
  }

  filterColumnsPeriodicities() {
    if (!this.cols.length) return;
    let perColumns = ["daily", "weekly", "monthly"];
    let periodicities = this.datasetOptions.showPeriodicities;
    if (!periodicities) return;
    this.cols = this.cols.map((c) => {
      if (!perColumns.includes(c.field) || this.user.isLogged) {
        c.show = true;
      } else {
        periodicities.findIndex((p) => p.name == c.field) > -1
          ? (c.show = true)
          : (c.show = false);
      }
      return c;
    });
    if (this.selectedSeries.length > 0) {
      // Check if same dataset
      let ids = this.selectedSeries.map((s) => s.uuid);
      if (
        this.rows.findIndex((r) => ids.includes(r.uuid)) > -1 ||
        this.filteredGrid.findIndex((r) => ids.includes(r.uuid)) > -1
      )
        this.seriesGroupSelection(this.selectedSeries.map((s) => s.id));
    }
  }

  initMap() {
    this.loaders(true);
    this.apis.getMarkets().subscribe((res: any) => {
      let marketsIds = _.uniq(this.rows.map((r) => r.market));
      this.markets.data = res.results.filter((m) => marketsIds.includes(m.id));
      this.updateMapMarkers();
      this.loaders(false);
    });
  }

  markerClickedEvent(e: any, marker) {
    // this.mapObject.options.zoom = this.map.getZoom() + 1;
    // this.map.panTo(e.latLng);
    this.infoWindow.position = marker.position;
    let info = this.markets.data.find((m) => m.id == marker.marketId);
    let markerContent = [];
    if (info.iso3_country_code) markerContent.push(info.iso3_country_code);
    if (info.admin_unit) markerContent.push(info.admin_unit);
    if (info.market_name) markerContent.push(info.market_name);
    this.infoWindow.options = {
      pixelOffset: new google.maps.Size(0, -50),
      content: markerContent.join(", "),
    };
    this.infoWindow.open();
  }

  getSelectedAndFilteredIdsForMap() {
    let filteredIds = [];
    let selectedIds = this.selectedSeries.map((s) => s.market);
    if (JSON.stringify(this.appliedFilters) != "{}")
      filteredIds = _.uniq(this.filteredGrid.map((r) => r.market));
    else filteredIds = _.uniq(this.rows.map((r) => r.market));
    return [filteredIds, selectedIds];
  }

  updateMapMarkers() {
    let [filteredIds, selectedIds] = this.getSelectedAndFilteredIdsForMap();
    let bounds;
    [bounds, this.markets.markers] =
      this.helper.updateMarkersIconsAfterSelectionAndFilter(
        this.markets.data,
        filteredIds,
        selectedIds
      );
    if (bounds) {
      setTimeout(() => {
        this.map.fitBounds(bounds);
      }, 100);
    }
  }

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (!this.rows.length) return;
    if (
      document.activeElement != document.body &&
      document.activeElement.tagName != "TD" &&
      document.activeElement.tagName != "TH"
    )
      return;
    if (event.key == "ArrowRight") {
      if (
        this.paginator.first + this.paginator.rows >
        this.filteredGrid.length - 1
      )
        return;
      this.paginator.first += this.paginator.rows;
    }
    if (event.key == "ArrowLeft") {
      if (this.paginator.first - this.paginator.rows < 0) {
        this.paginator.first = 0;
        return;
      }
      this.paginator.first -= this.paginator.rows;
    }
    if (event.key == "ArrowDown" || event.key == "ArrowUp") {
      event.preventDefault();
      let n = this.selectedSeries.length;
      if (n == 0) {
        this.addSeriesProgrammatically(this.filteredGrid[0]);
      }
      if (n > 0) {
        if (this.paginator.performing) return;
        this.paginator.performing = true;
        let lastSeries = this.selectedSeries[n - 1].id;
        let lastSeriesIdx = this.filteredGrid.findIndex(
          (s) => s.id == lastSeries
        );
        let nextIdx =
          event.key == "ArrowDown" ? lastSeriesIdx + 1 : lastSeriesIdx - 1;
        if (this.filteredGrid[nextIdx] == undefined) {
          this.paginator.performing = false;
          return;
        }
        let nextSeries = this.filteredGrid[nextIdx];
        let page = (Math.ceil((nextIdx + 1) / 8) - 1) * 8;
        this.paginator.first = page;
        this.addSeriesProgrammatically(nextSeries);
      }
    }
    this.normalizeRowsSelectionHighlight();
  }

  addSeriesProgrammatically(s) {
    this.selectedSeries.push(s);
    this.selectedSeriesEvent(
      {
        type: "row",
        data: s,
      },
      true
    );
  }

  observeExtremes() {
    this.helper.extremesObservable.subscribe((res: any) => {
      if (String(res.min).length < 4 || String(res.max).length < 4) return;
      if (!res.min || !res.max) return;
      if (!this.chart.ref) return;
      let extremes = this.chart.ref.xAxis[0].getExtremes();
      if (res.trigger == "navigator") {
        extremes.min = moment(extremes.min).subtract(1, "months").valueOf();
        this.chart.ref.xAxis[0].setExtremes(extremes.min, extremes.max);
      }
      this.extremes.min = moment(extremes.min);
      this.extremes.max = moment(extremes.max);
      const processChanges = this.helper.debounce(() => {
        this.calculateAllData();
      }, 500);
      processChanges();
    });
  }

  configChart() {
    this.chart.ref$.subscribe((c) => {
      c.options.plotOptions.series.point.events.mouseOver = (e: any) => {
        this.hoverPoints = {
          x: this.helper.getformatDateByPeriodicity(
            this.periodicities.selected,
            e.target.x
          ),
          y: e.target.y,
        };
        this.selectedSeries.map((s) => {
          let seriesChartId = e.target.series.userOptions.id;
          if (
            String(seriesChartId).includes("nominal") &&
            String(seriesChartId).includes(s.id)
          ) {
            s.hover = this.hoverPoints;
            s.hoverReal = {};
            s.hoverDollar = {};
          } else if (
            String(seriesChartId).includes("real") &&
            String(seriesChartId).includes(s.id)
          ) {
            s.hoverReal = this.hoverPoints;
            s.hover = {};
            s.hoverDollar = {};
          } else if (
            String(seriesChartId).includes("dollar") &&
            String(seriesChartId).includes(s.id)
          ) {
            s.hoverDollar = this.hoverPoints;
            s.hover = {};
            s.hoverReal = {};
          } else {
            let hover = {};
            if (s.series) {
              s.series.map((data) => {
                if (data[0] == this.hoverPoints.x) {
                  hover = {
                    x: this.helper.getformatDateByPeriodicity(
                      this.periodicities.selected,
                      this.hoverPoints.x
                    ),
                    y: this.hoverPoints.y,
                  };
                }
              });
              s.hover = hover;
            }
          }
          return s;
        });
      };
      c.options.plotOptions.series.point.events.mouseOut = () => {
        this.selectedSeries.map((s) => {
          s.hover = {};
          s.hoverReal = {};
          s.hoverDollar = {};
          return s;
        });
      };
    });
  }

  setDatasetId(id, key) {
    this.datasetId = id;
    this.titleKey = key;
  }

  loaders(bool) {
    this.loading = bool;
    this.chart.ref$.subscribe((c: any) => {
      if (bool) {
        c.showLoading();
        return;
      }
      c.hideLoading();
    });
  }

  saveConfiguration() {
    let series = this.selectedSeries.map((s) => s.id);
    if (!series.length) {
      this.alertService.saveConfigurationsEmptySelection(this.words);
      return;
    }
    let origin = this.apis.getBaseOrigin();
    let obj = [...[origin], ...[this.datasetId], ...series];
    let text = JSON.stringify(obj);
    text = this.helper.cipher("salt", text);
    var a = document.createElement("a");
    a.setAttribute(
      "href",
      "data:text/plain;charset=utf-u," + encodeURIComponent(text)
    );
    a.setAttribute("download", this.datasetId + ".ejson");
    a.click();
  }

  loadConfiguration() {
    document.getElementById("load-configuration").click();
  }

  onImport(event: any) {
    var file = event.srcElement.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = (evt: any) => {
        let obj = JSON.parse(this.helper.decipher("salt", evt.target.result));
        event.srcElement.value = null;
        this.checkAndImportConfigurations(obj);
      };
      reader.onerror = function (evt) {};
    }
  }

  checkAndImportConfigurations(obj) {
    let origin = obj[0];
    let dataset = obj[1];
    let series = obj.slice(2);
    if (origin != this.apis.getBaseOrigin()) {
      let url =
        (this.apis.getAPIPrefix() + origin).replace("v4", "fpmat4") +
        "#/dashboard/tool/" +
        dataset +
        "?permalink=" +
        series.join(",");
      this.alertService.differentOrigin(url, this.words);
      return;
    }
    if (this.datasetId != dataset) {
      this.clearAll();
      this.router.navigate([`dashboard/tool/${dataset}`], {
        queryParams: { permalink: series.join(",") },
      });
      return;
    }
    this.clearAll();
    this.seriesGroupSelection(series);
  }

  checkIfMapActiveToInit(forceRun?) {
    if (this.togglers["table"].active == "Map View" || forceRun) {
      if (!this.markets.data.length) {
        this.initMap();
        return;
      }
      this.updateMapMarkers();
    }
  }

  initTogglers() {
    setTimeout(() => {
      this.togglersElements.toArray().forEach((toggler: TogglerComponent) => {
        toggler.init();
      });
    });
    this.togglers = this.helper.getTogglersData(this.datasetOptions);
    let mapIdx = this.togglers["table"].data.findIndex(
      (t) => t.name == "Map View"
    );
    if (this.datasetOptions.showMap !== undefined) {
      let showMap = JSON.parse(this.datasetOptions.showMap);
      this.togglers["table"].data[mapIdx].hide = !showMap;
    } else {
      this.togglers["table"].data[mapIdx].hide = false;
    }
    this.helper.setTogglerData(this.togglers);
    this.setMenuOptionsForToggler("chart");
  }

  toggleOptions(res: any) {
    if (res.param) {
      this.statistics.currentOption = res.param;
      this.calculateStatistics(res.param);
    }
    this.togglers[res.name] = res.data;
    if (res.name == "table") this.checkIfMapActiveToInit();
    if (res.name == "currency") {
      this.switchToMarketSeason(res.data.active);
    }
    if (res.name == "legend" && this.chartTypes.selected == "series") {
      if (res.data.active.toLowerCase() == "legend") {
        this.chart.ref$.subscribe((c) => {
          c.options.tooltip.enabled = false;
        });
      } else {
        this.chart.ref$.subscribe((c) => {
          c.options.tooltip.enabled = true;
        });
      }
    }
    this.setMenuOptionsForToggler(res.name);
  }

  setMenuOptionsForToggler(name) {
    let activeTogglerOptions = this.togglers[name].data.find(
      (t) => t.name == this.togglers[name].active
    ).options;
    if (activeTogglerOptions)
      this.exporter.exportingMenu = activeTogglerOptions;
  }

  isInArrayBy(array, obj, key) {
    let k = array.filter((a) => {
      return a[key] == obj[key];
    });
    return k.length > 0;
  }

  initLang() {
    this.settingsService.getWords().subscribe((words) => {
      if (!words) return;
      this.words = words;
      if (this.selectedSeries.length) this.calculateAllData();
    });
    this.settingsService.getCurrentLanguage().subscribe((lang) => {
      if (!lang) return;
      if (!this.currentLang) this.currentLang = lang;
      if (this.currentLang == lang && !this.firstRun) return;
      this.firstRun = false;
      this.currentLang = lang;
      let months = (this.words["key-panel-main-months_long"] || "").split("-");
      let shortMonths = (this.words["key-panel-main-months_short"] || "").split(
        "-"
      );
      moment.updateLocale("en", {
        months: months,
        monthsShort: shortMonths,
      });
      let clang = {
        lang: {
          months: moment.months(),
          shortMonths: moment.monthsShort(),
        },
      };
      Highstock.setOptions(clang);
      this.chart.ref$.subscribe((c) => {
        c.update(clang);
      });
      this.updateGrid(true);
    });
  }

  /**
   * Getting JSON data from the server with UUID
   *
   * @return  {void}  void
   */
  getApisData(id): void {
    if (this.gridSubscriber) this.gridSubscriber.unsubscribe();
    this.loaders(true);
    this.initLang();
    this.markets = { data: [], markers: [] };
    this.paginator.first = 0;
    this.settingsService.getSettings().subscribe((configs) => {
      if (!configs) return;
      if (!configs.datagrid[id]) {
        this.alertService.invalidDataset(this.words);
        this.loaders(false);
        return;
      }
      let grid = _.cloneDeep(configs.datagrid[id]);
      this.apis.updateBaseUrl(grid.origin);
      this.remoteDataset = Boolean(grid.origin);
      let cols = grid.columns;
      let periodicities = [];
      cols.map((c, i) => {
        if (c.field == "periodicities") {
          c.columns.map((p) => {
            let period = {
              labelkey: p.labelkey,
              field: p.field,
              type: "periodicity",
              filterable: false,
            };
            periodicities.push(period);
          });
          cols.splice(i, 1);
        }
      });
      this.gridSubscriber = this.apis
        .getGrid(grid.url)
        .subscribe((res: any) => {
          this.setDatasetId(id, grid.key);
          this.datasetOptions = grid.datasetOptions || {};
          let rows = res.results;
          // Calculated periodicities
          rows = this.checkPeriodicityAutomaticCalculation(rows, periodicities);
          rows = this.formatDataGridSortingAndDates(rows, cols);
          if (_.isEqual(rows, this.rows)) return;
          this.rows = rows;
          this.cols = cols.concat(periodicities).map((c) => {
            c.show = true;
            return c;
          });
          this.filteredGrid = this.rows;
          this.initTogglers();
          this.initFilters();
          this.clearFilters();
          this.checkForParamsPermalink();
          this.checkIfMapActiveToInit(true);
          this.filterColumnsPeriodicities();
          this.table.selectionChange.subscribe(() => {
            this.normalizeRowsSelectionHighlight();
          });

          // this.seriesGroupSelection([
          //   "74a2aae3-33c0-4ae2-8b7d-00f593923d2c",
          //   "c2dff7b8-2d92-4694-b102-84ba2fdde8e5",
          // ]);
        });
      return;
    });
  }

  normalizeRowsSelectionHighlight() {
    setTimeout(() => {
      this.rows.map((r) => {
        let row = this.selectedSeries.find((u) => r.uuid == u.uuid);
        if (row) r.selected = true;
        else r.selected = false;
      });
      if (this.filteredGrid)
        this.filteredGrid.map((r) => {
          let row = this.selectedSeries.find((u) => r.uuid == u.uuid);
          if (row) r.selected = true;
          else r.selected = false;
        });
    }, 300);
  }

  checkPeriodicityAutomaticCalculation(rows, periodicities?) {
    let calcPeriodicity = this.datasetOptions.calculatePeriodicities;
    if (calcPeriodicity) {
      calcPeriodicity.map((p) => {
        rows = this.helper.calculatePeriodicities(p.period, p.from, rows);
      });
      if (periodicities) {
        periodicities = periodicities.map((c) => {
          if (calcPeriodicity.find((p) => p.period == c.field))
            c.calculated = true;
          return periodicities;
        });
      }
    }
    return rows;
  }

  checkForParamsPermalink() {
    let permalink = this.route.snapshot.queryParams.permalink;
    if (!permalink) return;
    this.router.navigate([], { queryParams: null });
    let ids = permalink.split(",");
    this.seriesGroupSelection(ids);
  }

  formatDataGridSortingAndDates(data, cols) {
    data = data
      .sort((a, b) => {
        let condition;
        cols.map((c) => {
          condition =
            condition ||
            String(a[c.field] || "").localeCompare(String(b[c.field] || ""));
        });
        return condition;
      })
      .map((d: any) => {
        d = this.updateCommodityInfoPopup(d);
        d["seriesName"] = this.chartConversions.getFullTitleFromSeries(
          d,
          null,
          null,
          true
        );
        if (!d["periodicities"]) {
          d = this.updateSeriePeriodicity(d);
        }
        d["showNominal"] = true;
        return d;
      });
    return data;
  }

  updateCommodityInfoPopup(d) {
    if (!d.id) d.id = d.uuid;
    d["commodity_info_template"] = this.helper.replaceAll(
      this.chartDataService.getCommodityTemplate(d, this.words),
      "\n",
      ""
    );
    return d;
  }

  updateSeriePeriodicity(d) {
    if (!d.id) d.id = d.uuid;
    let periodicities = d["periodicity"];
    d["periodicities"] = d["periodicity"];
    d["periodicity"] = d["periodicities"][0]?.period;
    periodicities.map((per) => {
      let date = this.helper.getGridDateFromPeriodicity(per);
      d[per.period] = date;
      d[`${per.period}Calculated`] = per.calculated;
      d.start_date = per.start_date;
      d.end_date = per.end_date;
    });
    return d;
  }

  calculateAllData() {
    setTimeout(() => {
      this.setCurrencySelections();
      this.formatLegendsForCurrencies();
      this.calculateChartData();
      this.calculateStatistics(this.statistics.currentOption);
      this.calculateInformationTab();
      this.detectHarmonizationForAdminOperations();
    }, 200);
  }
  detectHarmonizationForAdminOperations() {
    // Disable buttons if periodicities are calculated
    if (
      this.selectedSeries.filter((s) => {
        // Find the calculated periodicity of the series
        if (!s.calculated) return false;
        let calculated = s.calculated.find(
          (p) => p.period == this.periodicities.selected
        );
        // Check if the calculated periodicity is selected
        return calculated && calculated.period == this.periodicities.selected;
      }).length > 0
    ) {
      this.admin.disableButtons = true;
      return;
    } else {
      this.admin.disableButtons = false;
    }

    // Disable button based on currency/measure criteria
    if (
      this.selectedSeries.filter(
        (s) => s.measure_unit_label != this.measures.selected
      ).length
    ) {
      this.admin.disableButtons = true;
      return;
    } else {
      this.admin.disableButtons = false;
    }
    let currencies = this.currencies;
    if (currencies.types.filter((c) => c.selected).length > 1) {
      // If multiple currencies are selected, return
      this.admin.disableButtons = true;
      return;
    } else {
      // Check if dollar is selected only and the selected series' local currencies are USD
      if (
        !currencies.selections.nominalSelected &&
        currencies.selections.dollarSelected &&
        this.selectedSeries.filter((s) => s.currency == "USD").length !=
          this.selectedSeries.length
      ) {
        this.admin.disableButtons = true;
      } else {
        this.admin.disableButtons = false;
      }
    }
  }

  calculateInformationTab() {
    this.information = this.chartDataService.calculateInformationTab(
      this.selectedSeries,
      this.markets.data,
      this.words
    );
  }

  formatLegendsForCurrencies() {
    if (this.chartTypes.selected == "series") {
      this.chart.ref$.subscribe((c) => {
        let series = c.series;
        series.map((s) => {
          let id = s.userOptions.id;
          if (id != "highcharts-navigator-series") {
            let currency = id.split("-").pop();
            let sid = id.split("-").slice(0, -1).join("-");
            this.selectedSeries = this.selectedSeries.map((ss) => {
              let legendName =
                currency == "nominal"
                  ? "legendColor"
                  : `legendColor${this.helper.capitalizeFirstLetter(currency)}`;
              if (ss.id == sid) {
                ss[legendName] = (<any>s.userOptions).color;
              }
              this.currencies.types.map((c) => {
                if (c.selected) {
                  ss[`show${this.helper.capitalizeFirstLetter(c.type)}`] = true;
                }
              });

              return ss;
            });
          }
        });
      });
    }
  }

  calculateStatistics(type) {
    let selectedCurrencies;
    if (this.chartTypes.selected == "market") {
      selectedCurrencies = [this.togglers["currency"].active];
    }
    if (
      this.chartTypes.selected == "series" ||
      this.chartTypes.selected == "percentage" ||
      this.chartTypes.selected == "ipa"
    ) {
      selectedCurrencies = this.helper.getSelectedCurrencies(this.currencies);
    }
    let statistics = this.statisticsService.calculateStatistics(
      this.selectedSeries,
      type,
      {
        extremes: this.extremes,
        statistics: this.statistics,
        selectedCurrencies,
        measure: this.measures.selected,
        ignoreNegative: this.chartTypes.ignoreNegative,
        isAnnual: this.chartTypes.ipaType == "annual",
        dateLabel: this.words["key-panel-main-date_label"],
      },
      this.periodicities.selected,
      this.words
    );
    if (statistics && statistics.currentOption != "ipa")
      this.helper.formatNumbersForStatistics(statistics);
    if (statistics && statistics.currentOption == "ipa")
      statistics.rows = this.helper.sortByDateFormatted(
        statistics.rows,
        "MMM-YY"
      );
    this.statistics =
      statistics ||
      this.helper.getStatisticsTemplate(this.statistics.currentOption);
  }

  filterByExtremes(series) {
    let data = series.filter((d) => {
      // return moment(d[0]) >= moment(this.extremes.min) && moment(d[0]) <= moment(this.extremes.max);
      let date = moment(d[0]);
      let max = this.extremes.max;
      let min = this.extremes.min;
      return date.isBetween(min, max, "months");
    });
    return data;
  }

  showChartPopup(rowData) {
    this.statistics.showModal = true;
    this.statistics.chartTitle = rowData.series;
    this.statistics.chartObject = this.helper.getStatisticsChart(
      rowData.timeMinusVariantSparkChart
    );
  }

  calculateChartData() {
    this.chartData = { rows: [], cols: [] };
    if (!this.selectedSeries.length) return;
    switch (this.chartTypes.selected) {
      case "series":
        this.chartData = this.chartDataService.chartDataForTimeSeries({
          series: this.selectedSeries,
          extremes: this.extremes,
          currencies: this.currencies,
          measure: this.measures.selected,
          periodicity: this.periodicities.selected,
          dateLabel: this.words["key-panel-main-date_label"],
        });
        this.chartData.rows = this.helper.sortByDateFormatted(
          this.chartData.rows,
          this.helper.getformatDateByPeriodicity(
            this.periodicities.selected,
            null,
            true
          )
        );
        break;
      case "percentage":
        if (!this.chartTypes.selectedPercentage) return;
        this.chartData = this.chartDataService.chartDataForPercentageChange(
          this.chartTypes.selectedPercentage,
          {
            extremes: this.extremes,
            measure: this.measures.selected,
            currency: this.currencies.types.filter((c) => c.selected)[0].type,
            series: this.selectedSeries.find(
              (s) => s.id == this.chartTypes.selectedPercentage.seriesId
            ),
            periodicity: this.periodicities.selected,
            dateLabel: this.words["key-panel-main-date_label"],
          }
        );
        this.chartData.rows = this.helper.sortByDateFormatted(
          this.chartData.rows,
          "MMM-YY"
        );
        break;
      case "ipa":
        if (!this.chartTypes.selectedIPA) return;
        this.chartData = this.chartDataService.chartDataForIPA(
          this.chartTypes.selectedIPA,
          {
            extremes: this.extremes,
            measure: this.measures.selected,
            currency: "real",
            series: this.selectedSeries.find(
              (s) => s.id == this.chartTypes.selectedIPA.seriesId
            ),
            ignoreNegative: this.chartTypes.ignoreNegative,
            isAnnual: this.chartTypes.ipaType == "annual",
            dateLabel: this.words["key-panel-main-date_label"],
          }
        );
        this.chartData.rows = this.helper.sortByDateFormatted(
          this.chartData.rows,
          "MMM-YY"
        );
        break;
      case "market":
        this.chartData = this.chartDataService.chartDataForMarketSeason(
          this.marketSeasonData
        );
        break;
    }
  }

  initFilters() {
    this.filters = this.getAvailableFiltersFromRows(this.rows);
    this.filtersStock = _.cloneDeep(this.filters);
    this.loaders(false);
  }

  getAvailableFiltersFromRows(rows) {
    let filtersCheck: any = {};
    let filters: any = {};
    rows.map((r) => {
      this.cols.map((c) => {
        if (!filters[c.field]) filters[c.field] = [];
        if (!filtersCheck[c.field]) filtersCheck[c.field] = [];
        if (filtersCheck[c.field].indexOf(r[c.field]) < 0) {
          filters[c.field].push({
            name: r[c.field],
            value: _.uniqueId("filter_"),
          });
          filtersCheck[c.field].push(r[c.field]);
        }
      });
    });
    for (let key in filters) {
      filters[key] = _.sortBy(filters[key], "name");
    }
    return filters;
  }

  onFiltering(e) {
    this.filteredGrid = e.filteredValue;
    let filters = this.getAvailableFiltersFromRows(this.filteredGrid);
    let usedFilter = Object.keys(e.filters)[0];
    if (!usedFilter) {
      this.filters = _.cloneDeep(this.filtersStock);
      return;
    }
    for (let k in filters) {
      if (usedFilter == k) filters[k] = this.filtersStock[k];
      else {
        filters[k] = filters[k].map((f) => {
          f = this.filtersStock[k].find((s) => s.name == f.name);
          return f;
        });
      }
    }
    this.filters = filters;
  }

  applyFilter(dt, col) {
    let val = this.appliedFilters[col].map((el) => el.name);
    dt.filter(val, col, "in");
    let flag = true;
    for (let k in this.appliedFilters) {
      if (!this.appliedFilters[k]) continue;
      if (this.appliedFilters[k].length) {
        flag = false;
        break;
      }
    }
    if (flag) this.appliedFilters = {};
  }

  checkHidingDropdown(e) {
    let defocus = false;
    if (!e.itemValue) {
      defocus = true;
    } else {
      if (e.itemValue.length == 1) {
        defocus = true;
      }
    }
    if (defocus) {
      setTimeout(() => {
        document.body.click();
      }, 250);
    }
  }

  clearFilters() {
    this.appliedFilters = {};
    if (this.table) this.table.reset();
    this.table.filters = {};
    this.filteredGrid = this.rows;
    this.filters = _.cloneDeep(this.filtersStock);
  }

  downloadGrid() {
    this.exporter.showDialog = true;
    this.exporter.table = this.table;
    this.helper.exporter = this.exporter;
    this.exporter.enableSelection = true;
  }

  exportAllOptions() {
    if (!this.exporter.selected) {
      this.alertService.exporterNoOptionsSelected(this.words);
      return;
    }
    setTimeout(() => {
      this.exporter.selected.map((o) => {
        this[`export${o}`]();
      });
    }, 500);
  }

  exportCSV() {
    let options: any = {};
    if (this.exporter.enableSelection)
      options.selectionOnly = this.exporter.selectionOnly;
    this.helper.exportCSV(options);
  }

  exportPDF() {
    this.helper.exportPDF();
  }

  exportExcel() {
    this.helper.exportExcel();
  }

  openExportingMenu(e, menu, active) {
    menu.toggle(e);
    this.exporter.enableSelection = false;
    this.exporter.exportingMenu = this.exporter.exportingMenu.map((m) => {
      m.label = this.words[m.labelkey];
      if (m.type == "download") {
        m.command = () => {
          this.helper.exporter = this.exporter;
          switch (active) {
            case "Chart Data":
              this.exporter.table = _.cloneDeep(this.chartDataTable);
              // Formatting for Excel
              this.exporter.table.value = this.exporter.table.value.map((v) => {
                v.date = moment(
                  v.date,
                  this.helper.getformatDateByPeriodicity(
                    this.periodicities.selected,
                    null,
                    true
                  )
                ).format("MM/DD/yyyy");
                return v;
              });
              this.exporter.showDialog = true;
              break;

            case "Chart":
              // this.chart.ref.options.getSVG()
              let svg = document
                .getElementById("chart-panel")
                .getElementsByTagName("svg")[0];
              this.helper.exportSvgToImage(svg);
              break;

            case "Statistics":
              this.exporter.table = this.statisticsTableComponent.getTableRef();
              this.exporter.showDialog = true;
              break;

            case "Information":
              this.exporter.table = this.informationDataTable;
              this.exporter.showDialog = true;
              break;

            default:
              break;
          }
        };
      }
      if (m.type == "widget") {
        m.command = () => {
          this.widgetsGenerator.showDialog = true;
          // this.computeWidget();
        };
      }
      if (m.type == "permalink") {
        m.command = () => {
          let ids = this.selectedSeries.map((s) => s.id);
          let url = this.helper.createPermalink(ids, this.datasetId);
          this.permalink.showDialog = true;
          this.permalink.url = url;
        };
      }
      return m;
    });
  }

  openPermalink(url) {
    window.open(url);
  }

  async seriesGroupSelection(
    ids?,
    periodicity?,
    currentPeriodicity?,
    rechange?
  ) {
    this.loaders(true);
    this.selectedSeries.map((s, i) => {
      if (currentPeriodicity && periodicity && s.calculated) {
        let calculated = s.calculated.find((c) => c.period == periodicity);
        if (
          !calculated ||
          calculated.from != currentPeriodicity ||
          calculated.period != this.periodicities.selected
        ) {
          if (
            !s.periodicities.find(
              (p) => p.period == periodicity && !p.calculated
            )
          ) {
            this.seriesGroupSelection(
              ids,
              this.helper.getDesiredPeriodicityForCalculations(
                this.datasetOptions.calculatePeriodicities,
                periodicity
              ),
              periodicity,
              true
            );
            return;
          }
        }
      }
    });
    let allMatchedSeries = [];
    let response: any = {};
    response = this.chartConversions.getHarmonizedSeriesFromFilteredGrid(
      ids
        ? ids.map((id) => this.rows.find((r) => r.id == id))
        : this.filteredGrid
    );
    ids = response.ids;
    this.measures = response.measures;
    this.currencies = response.currencies;
    // Update chart axis title
    ids.map((id) => allMatchedSeries.push(this.rows.find((r) => r.id == id)));
    let allIds = ids;
    this.selectedSeries = [];
    this.selectedSeries = allMatchedSeries;
    this.setChartPeriodicities(response.periodicities, periodicity);
    if (this.chartTypes.selected != "series") {
      this.chartTypes.selected = "series";
      this.switchToTimeSeries(true);
    }
    await this.helper.sleep(10);
    this.apis
      .getMultiplePrices(allIds, periodicity || this.periodicities.selected)
      .subscribe((res: any) => {
        let results = res.results.map((r) => {
          if (!r.id) r.id = r.uuid;
          return r;
        });
        this.removeAllSeries();
        this.chart.ref$.subscribe((c) => {
          this.selectedSeries.map((s, i) => {
            this.helper.doScaledTimeout(10, i, async () => {
              s.legendColor = this.helper.randDarkColor();
              let calculated = (s.calculated || []).find(
                (c) => c.period == (periodicity || this.periodicities.selected)
              );
              if (
                (periodicity || this.periodicities.selected) &&
                s.calculated &&
                calculated
              ) {
                let results: any = await this.getSinglePriceAsync(
                  s,
                  calculated
                );
                if (!results) return;
                let datapoints = results.datapoints;
                s = this.helper.calculatePricesBasedOnPeriodicities(
                  s,
                  periodicity || this.periodicities.selected,
                  this.datasetOptions,
                  datapoints
                );
              } else {
                let result = results.find((r) => r.id == s.id);
                if (!result) return;
                let datapoints = result.datapoints;
                s.series = this.helper.getDataPoints(
                  datapoints,
                  null,
                  this.datasetOptions.populateNulls,
                  this.periodicities.selected
                );
                let real = this.helper.getDataPoints(
                  datapoints,
                  "real",
                  this.datasetOptions.populateNulls,
                  this.periodicities.selected
                );
                let dollar = this.helper.getDataPoints(
                  datapoints,
                  "dollar",
                  this.datasetOptions.populateNulls,
                  this.periodicities.selected
                );
                s.realDatapoints = real;
                s.dollarDatapoints = dollar;
              }
              s = this.chartConversions.formatAllMeasures(s);
              if (this.chart.ref.series.length < 10) {
                this.helper.confirmSorting(s);
                c.addSeries({
                  id: s.id + "-" + response.currency,
                  visible: true,
                  data: s[response.propertyName],
                  type: undefined,
                  yAxis: response.currency == "dollar" ? 1 : 0,
                  name: this.chartConversions.getFullTitleFromSeries(
                    s,
                    response.currency,
                    response.measure
                  ),
                  marker: {
                    enabled: true,
                    radius: 1,
                  },
                  color: s.legendColor,
                });
              }
            });
          });
          this.formatChartForSelectingAll(response);
          setTimeout(() => {
            this.formatChartDateLabels(true);
            this.loaders(false);
            this.calculateAllData();
            if (rechange) {
              setTimeout(() => {
                this.seriesGroupSelection(ids, currentPeriodicity, null);
              }, 5);
            }
            c.zoomOut();
          }, 5 * ids.length);
        });
      });
  }

  getSinglePriceAsync(s, calculated) {
    return new Promise((resolve, reject) => {
      this.apis.getPrices(s, calculated.from).subscribe(
        (res: any) => {
          resolve(res);
        },
        () => {
          reject();
        }
      );
    });
  }

  formatChartForSelectingAll(params) {
    this.setCurrencySelections();
    let desiredIndex = params.currency == "dollar" ? 1 : 0;
    let otherIndex = params.currency == "dollar" ? 0 : 1;
    this.chart.ref$.subscribe((c) => {
      c.yAxis[otherIndex].update({
        visible: false,
      });
      c.yAxis[desiredIndex].update({
        title: {
          text: this.chartConversions.getTitleFromSeries(
            this.selectedSeries[0],
            params.currency,
            params.measure
          ),
          x: desiredIndex == 1 ? 8 : -15,
        },
        visible: true,
        opposite: false,
      });
    });
  }

  customSort(event: any) {
    return this.helper.customSort(
      event,
      this.selectedSeries.length ? this.periodicities.selected : null
    );
  }

  /**
   * Toggling the legend on the right of the charts
   *
   * @return  {void}  void
   */
  toggleLegend(): void {
    this.showLegend = !this.showLegend;
    setTimeout(() => {
      this.resizeChart();
    }, 500);
  }

  changeChartType(type) {
    if (type.name == "series") {
      this.switchToTimeSeries();
    }
    if (type.name == "percentage") {
      this.switchToPercentageChange();
    }
    if (type.name == "market") {
      this.switchToMarketSeason("nominal");
      this.chartConversions.toggleAxisVisibility(this.chart, 1, false);
    }
    if (type.name == "ipa") {
      this.switchToIpa();
    }
    setTimeout(() => {
      this.calculateAllData();
    }, 10);
  }

  switchToPercentageChange(currency?) {
    if (!currency) this.formatCurrencies("nominal");
    let fromTimeSeries = this.chartTypes.selected == "series";
    // if (fromTimeSeries) this.saveSelectedSeries();
    let series = this.selectedSeries;
    [this.percentageChangeData, this.chartTypes] =
      this.chartTypesService.switchToPercentageChange({
        chart: this.chart,
        series,
        periodicity: this.periodicities.selected,
        chartTypes: this.chartTypes,
        fromTimeSeries,
        currency,
      });
  }

  changePercentageChart() {
    this.removeAllSeries();
    this.chart.ref$.subscribe((c) => {
      // setTimeout(() => {
      let percentage = this.chartTypes.selectedPercentage;
      this.chartTypesService.addToPercentageChart(c, percentage);
      this.calculateAllData();
      // }, 1000);
    });
  }

  switchToIpa() {
    setTimeout(() => {
      this.loaders(true);
    }, 50);
    this.formatCurrencies("nominal");
    let isAnnual = this.chartTypes.ipaType == "annual";
    let fromTimeSeries = this.chartTypes.selected == "series";
    // if (fromTimeSeries) this.saveSelectedSeries();
    setTimeout(() => {
      let ipaArray = this.ipaCalculator.getIpaArray(
        this.selectedSeries,
        this.currencies.selected,
        this.measures.selected,
        isAnnual
      );
      this.chartTypes = this.chartTypesService.switchToIpa({
        chart: this.chart,
        ipaArray,
        extremes: this.extremes,
        chartTypes: this.chartTypes,
        isAnnual,
        fromTimeSeries,
      });
      setTimeout(() => {
        this.loaders(false);
      }, 500);
    }, 500);
  }

  switchChartTypeSubOptions(model) {
    if (model == "ipaType") {
      if (this.chartTypes.selected == "ipa") {
        this.switchToIpa();
      } else {
        this.calculateAllData();
      }
    }
  }

  ipaChartIgnoreNegative(ignoreNegative) {
    this.chart.ref$.subscribe((c) => {
      let ipa = c.series.find((s) => s.userOptions.id.includes("-ipa"));
      if (!ipa) return;
      ipa.update(<SeriesOptionsType>{
        zones: this.ipaCalculator.getZones(ignoreNegative),
      });
    });
    this.calculateAllData();
  }

  timeSeriesConnectNulls(connectNulls) {
    this.chart.ref$.subscribe((c) => {
      c.update({ plotOptions: { series: { connectNulls: connectNulls } } });
      c.series.map((s) => {
        s.update(<SeriesOptionsType>{
          connectNulls: connectNulls,
        });
      });
    });
  }

  changeIpaChart() {
    this.removeAllSeries();
    this.chart.ref$.subscribe((c) => {
      let ipa = this.chartTypes.selectedIPA;
      this.chartTypesService.addToIpaChart(
        c,
        ipa,
        this.chartTypes.ignoreNegative
      );
    });
    this.calculateAllData();
  }

  changeChartTypeTo(type) {
    setTimeout(() => {
      this.chartTypes.selected = type;
    }, 300);
  }

  switchToMarketSeason(currency, serie?) {
    let series = serie;
    if (!serie) series = this.selectedSeries[0];
    this.formatCurrencies(currency);
    let data = _.cloneDeep(series);
    data.info = { commodityMarketSeasonStart: series.commodity_start_date };
    let isDollar = series.currency == "USD";
    let property =
      this.chartConversions.getSeriesPropertyNameBasedOnCurrencyAndMeasure(
        currency,
        this.measures.selected,
        isDollar
      );
    this.chartTypes.dataLabel = this.chartConversions.getFullTitleFromSeries(
      series,
      currency,
      this.measures.selected
    );
    let marketSeasonData = this.marketSeasonService.getMarketSeasonData(
      data,
      property
    );
    marketSeasonData.map((m) => {
      m.legendColor = this.helper.randDarkColor();
      m.uid = _.uniqueId();
      return m;
    });
    this.marketSeasonData = marketSeasonData;
    this.addMarketSeasonChart(currency, series.commodity_start_date);
    setTimeout(() => {
      if (serie) this.calculateAllData();
    }, 100);
  }

  addMarketSeasonChart(currency, commodityMarketSeasonStart) {
    this.chart.ref$.subscribe((c) => {
      c.yAxis[2].update({ visible: false });
      c.yAxis[3].update({ visible: false });
      c.yAxis[0].update({
        visible: true,
        title: {
          text:
            currency == "dollar"
              ? "USD/" + this.measures.selected
              : this.selectedSeries[0].currency + "/" + this.measures.selected,
        },
      });
      c.xAxis[0].setExtremes(
        _.cloneDeep(this.extremes.dataMin),
        _.cloneDeep(this.extremes.dataMax)
      );
      this.removeAllSeries();
      c.xAxis[0].options.type = "category";
      let categories = this.helper.getMonthsShortNames();
      if (commodityMarketSeasonStart > 1) {
        categories = _.cloneDeep(categories)
          .splice(commodityMarketSeasonStart - 1, categories.length - 1)
          .concat(
            _.cloneDeep(categories).splice(0, commodityMarketSeasonStart)
          );
      }
      c.xAxis[0].setCategories(categories);
      for (let i = 0; i < 5; i++) {
        if (this.marketSeasonData[i]) {
          this.addSeriesToMarketSeason(this.marketSeasonData[i]);
          this.marketSeasonData[i].isSelected = [this.marketSeasonData[i].uid];
        }
      }
      c.update(<Options>{
        chart: {
          type: "line",
          marginTop: 100,
          zoomType: <any>"",
        },
        xAxis: [
          {
            labels: {
              formatter: null,
            },
          },
        ],
        rangeSelector: {
          enabled: false,
          inputEnabled: false,
        },
        navigator: {
          enabled: false,
        },
        tooltip: this.helper.getMainChartTooltip("market"),
        plotOptions: {
          series: {
            zones: null,
            marker: {
              enabled: true,
              radius: 4,
            },
          },
        },
      });
    });
  }

  addSeriesToMarketSeason(data) {
    this.chart.ref$.subscribe((c) => {
      let months = this.helper.getMonthsShortNames();
      let marketSeries = [];
      data.data.map((d) => {
        marketSeries.push({ name: months[d.month], y: d.y, year: d.year });
      });
      c.addSeries({
        id: data.uid,
        visible: true,
        data: marketSeries,
        type: undefined,
        name: data.endYear,
        color: data.legendColor,
      });
    });
  }

  marketSeasonSelectEvent(e, data) {
    this.calculateChartData();
    if (e.checked) {
      this.addSeriesToMarketSeason(data);
      return;
    }
    this.chart.ref$.subscribe((c) => {
      c.get(data.uid).remove();
    });
  }

  switchToTimeSeries(temporary?) {
    this.formatCurrencies("nominal");
    this.chart.ref$.subscribe((c) => {
      this.removeAllSeries();
      this.chartConversions.switchChartOptionsToTimeSeries(
        c,
        this.periodicities.selected,
        this.chartTypes
      );
      if (!temporary) this.adminOperation().reload();
    });
  }

  /**
   * A helper function to resize the chart whenever the area changes
   *
   * @return  {void}  void
   */
  resizeChart(): void {
    this.chart.ref.setSize(null, null, true);
  }

  /**
   * Toggling full screen of the table and the
   *
   * @param   {string}  key  Key of the element to be full screen
   *
   * @return  {void}       void
   */
  toggleFullscreen(key: string): void {
    if (key == "chart") {
      this.fullscreen.chart = !this.fullscreen.chart;
      setTimeout(() => {
        this.resizeChart();
      }, 50);
    }
    if (key == "table") {
      this.fullscreen.table = !this.fullscreen.table;
      if (this.fullscreen.table) {
        this.paginator.rows = 50;
        this.mapObject.options.height = `${window.innerHeight - 45}px`;
      } else {
        this.mapObject.options.height = "350px";
      }
    }
  }

  /**
   * Remove a series from the chart
   *
   * @param   {any}  series  The series data to be removed
   *
   * @return  {void}             void
   */
  removeSeries(series: any): void {
    let i = this.selectedSeries.indexOf(series);
    let selectedSeries = this.selectedSeries;
    if (i > -1) {
      selectedSeries.splice(i, 1);
      this.selectedSeries = [];
      selectedSeries.map((s) => {
        this.selectedSeries.push(s);
      });
      // let chart = this.chart.ref.get(series.id);
      // if (chart) chart.remove();
      this.removeInstancesOfSeries(series.id);
    }
    this.calculateAllData();
    this.normalizeAfterDeselect();
  }

  removeAllSeries() {
    this.selectedSeries = this.selectedSeries.map((s) => {
      s.legendColor = null;
      s.legendColorReal = null;
      s.legendColorNominal = null;
      return s;
    });
    this.chart.ref$.subscribe((c) => {
      while (c.series.length > 0) c.series[0].remove(true);
    });
  }

  addLastSeriesOnly() {
    setTimeout(() => {
      let lastSeries = this.selectedSeries[this.selectedSeries.length - 1];
      this.selectedSeries = [];
      this.selectedSeries.push(lastSeries);
    }, 10);
  }

  performRowSingleSelection(e: any, programmatically?) {
    if (this.selectedSeries.length > 1) {
      this.addLastSeriesOnly();
      this.removeAllSeries();
    }
    this.seriesGroupSelection([e.data.uuid], this.periodicities.selected);
  }

  compatibleConstraints(e: any) {
    if (this.selectedSeries.length <= 1) return { canBeCompared: true };
    let response: any = {};
    if (this.selectedSeries.length == 1) return { canBeCompared: true };

    let periodicities = this.chartConversions.checkPeriodicities(
      this.selectedSeries
    );

    if (!periodicities.length) {
      return this.cannotBeCompared(e.data);
    }

    if (periodicities.length == 1) {
      response["diffPeriod"] = periodicities[0];
    }

    this.setChartPeriodicities(periodicities, this.periodicities.selected);

    let [sameMeasure, sameCurrency] = this.chartConversions.getDiffUnits(
      this.selectedSeries
    );
    // User selects different unit
    if (!sameMeasure) {
      let canBeComparedWithCommonUnit = this.hasCommonMeasureUnit(
        this.selectedSeries
      );
      if (canBeComparedWithCommonUnit) {
        response = { canBeCompared: true, commonUnit: true };
      } else {
        return this.cannotBeCompared(e.data);
      }
    } else {
      // User selects same unit
      response["canBeCompared"] = true;
    }
    if (!sameCurrency) {
      // let canBeComparedWithCommonCurrency = this.hasCommonCurrency(this.selectedSeries);
      response["commonCurrency"] = true;
    }
    return response;
  }

  cannotBeCompared(serie) {
    this.alertService.cannotCompare(this.words);
    setTimeout(() => {
      this.removeSeries(serie);
    }, 10);
    let response = { canBeCompared: false };
    return response;
  }

  hasCommonCurrency(series) {
    let hasDollar = true;
    series.map((s) => {
      if (!s.dollarDatapoints) hasDollar = false;
    });
    return hasDollar;
  }

  hasCommonMeasureUnit(series) {
    let hasFactors = true;
    series.map((s) => {
      if (!s.conversion_factor) hasFactors = false;
    });
    return hasFactors;
  }

  setChartMeasures(series) {
    let measure = series.measure_unit_label;
    let factor = series.conversion_factor;
    this.measures = {
      types: [],
      selected: measure == "Kg" ? "Kg" : measure,
      previous: measure == "Kg" ? "Kg" : measure,
    };
    if (factor || measure == "Kg") {
      this.measures.types.push({
        name: "Kg",
      });
      this.measures.types.push({
        name: "tonne",
      });
    }
    if (measure != "Kg" && measure != "tonne") {
      this.measures.types.push({
        name: measure,
      });
    }
  }

  setChartCurrencies(currency) {
    let isDollar = currency == "USD";
    this.currencies = {
      types: [
        {
          name: currency,
          type: "nominal",
          selected: isDollar ? false : true,
          hidden: isDollar,
        },
        {
          name: currency,
          type: "real",
          selected: false,
          hidden: isDollar,
        },
        {
          name: `Dollars`,
          type: "dollar",
          selected: isDollar ? true : false,
        },
      ],
      selections: { nominalSelected: !isDollar, dollarSelected: isDollar },
    };
    this.selectedSeries[0][`show${currency == "USD" ? "Dollar" : "Nominal"}`] =
      true;
    this.selectedSeries[0][`show${currency == "USD" ? "Nominal" : "Dollar"}`] =
      false;
    this.configureChartForCurrency({ checked: true });
  }

  setCurrencySelections() {
    this.currencies.types.map((c) => {
      this.currencies.selections[`${c.type}Selected`] = c.selected > 0;
    });
  }

  formatChartWithoutDollarAxis() {
    if (this.chartTypes.selected == "series") {
      this.chartConversions.toggleAxisVisibility(
        this.chart,
        0,
        this.currencies.selections.realSelected ||
          this.currencies.selections.nominalSelected
      );
      this.chartConversions.toggleAxisVisibility(
        this.chart,
        1,
        this.currencies.selections.dollarSelected
      );
      if (this.currencies.selections.dollarSelected) {
        let position =
          !this.currencies.selections.realSelected &&
          !this.currencies.selections.nominalSelected
            ? "left"
            : "right";
        this.chartConversions.toggleAxisLocation(this.chart, 1, position);
      }
    }
    this.updateChartAxisLabels();
  }

  updateChartAxisLabels() {
    let currencies = this.currencies.types
      .filter((c) => c.selected && c.type != "real")
      .map((c) => c.type);
    currencies.map((c) => {
      let measure = this.measures.selected;
      let index = 0;
      if (c == "dollar") index = 1;
      this.chart.ref.yAxis[index].update({
        title: {
          text: this.chartConversions.getTitleFromSeries(
            this.selectedSeries[0],
            c,
            measure
          ),
        },
      });
    });
  }

  configureChartForCurrency(e) {
    this.setCurrencySelections();
    this.formatChartWithoutDollarAxis();
    if (!e.checked) {
      this.chart.ref$.subscribe((c) => {
        let exists = true;
        while (exists) {
          let idx = c.series.findIndex((s) =>
            String(s.options.id).includes(e.currency)
          );
          if (idx < 0) {
            exists = false;
            return;
          }
          c.get(c.series[idx].options.id).remove();
        }
      });
      this.selectedSeries = this.selectedSeries.map((s) => {
        let propertyCapitalized = this.helper.capitalizeFirstLetter(e.currency);
        s[`show${propertyCapitalized}`] = false;
        return s;
      });
    }
  }

  setCurrency(e, currency) {
    this.loaders(true);
    this.configureChartForCurrency({ checked: e.checked, currency });
    if (e.checked) {
      let selectedSeries = this.selectedSeries;
      selectedSeries.map((s) => {
        let series = this.chart.ref.get(`${s.id}-${currency}`);
        if (series) return;
        let isDollar = s.currency == "USD";
        let property =
          this.chartConversions.getSeriesPropertyNameBasedOnCurrencyAndMeasure(
            currency,
            this.measures.selected,
            isDollar
          );
        let datapoints = s[property];
        let color = this.helper.randDarkColor();
        let propertyCapitalized = this.helper.capitalizeFirstLetter(currency);
        s[`legendColor${propertyCapitalized}`] = color;
        s[`show${propertyCapitalized}`] = true;
        this.chart.ref$.subscribe((c) => {
          if (this.chartTypes.selected == "series") {
            if (currency == "dollar") {
              c.yAxis[1].update({
                visible: true,
                title: { text: "USD/" + this.measures.selected },
              });
            }
          }
          c.addSeries({
            id: `${s.id}-${currency}`,
            visible: true,
            data: _.cloneDeep(datapoints),
            type: undefined,
            dashStyle:
              currency == "dollar"
                ? "ShortDashDotDot"
                : currency == "real"
                ? "ShortDot"
                : "Solid",
            name:
              s.country_name +
              ", " +
              s.price_type +
              ", " +
              s.commodity_name +
              ", " +
              this.chartConversions.getTitleFromSeries(
                s,
                currency,
                this.measures.selected
              ),
            color: color,
            marker: {
              enabled: true,
              radius: 1,
            },
            yAxis: currency == "dollar" ? 1 : 0,
          });
        });
      });
    }
    this.loaders(false);
  }

  measureSelectedEvent(measure) {
    if (this.chartTypes.selected == "market") {
      this.switchToMarketSeason(
        this.currencies.types.find((c) => c.selected).type
      );
    }
    this.chart.ref$.subscribe((c) => {
      let currencies = this.currencies.types
        .filter((c) => c.selected)
        .map((c) => c.type);
      this.selectedSeries.map((series) => {
        currencies.map((currency) => {
          let isDollar = series.currency == "USD";
          let property =
            this.chartConversions.getSeriesPropertyNameBasedOnCurrencyAndMeasure(
              currency,
              measure.name,
              isDollar
            );
          let data = _.cloneDeep(series[property]);
          let chartSerie = c.series.find(
            (s) => String(s.userOptions.id) == `${series.id}-${currency}`
          );
          if (!chartSerie) return;
          let serieName = this.chartConversions.updateMeasureBySlash(
            chartSerie.name,
            measure.name
          );
          c.update({
            series: [
              {
                id: `${series.id}-${currency}`,
                data: _.cloneDeep(data),
                type: undefined,
                name: serieName,
              },
            ],
          });
        });
      });
      let axisOneName = this.chartConversions.updateMeasureBySlash(
        c.yAxis[0].userOptions.title.text,
        measure.name
      );
      let axisTwoName = this.chartConversions.updateMeasureBySlash(
        c.yAxis[1].userOptions.title.text,
        measure.name
      );
      c.update({
        yAxis: [
          {
            title: {
              text: axisOneName,
            },
          },
          {
            title: {
              text: axisTwoName,
            },
          },
        ],
      });
    });
    this.calculateAllData();
  }

  currencySelectedEvent(e, currency) {
    let empty = true;
    this.currencies.types.map((c) => {
      if (c.selected) empty = false;
    });
    if (empty) {
      setTimeout(() => {
        currency.selected = true;
        this.alertService.currencySelectionError(this.words);
      }, 1);
      return;
    }
    setTimeout(() => {
      if (this.chartTypes.selected == "market") {
        this.togglers["currency"].active = currency.type;
        this.togglersElements.reset(
          this.togglersElements.toArray().map((query) => {
            if (query.name == "currency") {
              query.activeOption = currency.type;
            }
            return query;
          })
        );
        if (this.chartTypes.selected == "market")
          this.switchToMarketSeason(currency.type);
        else this.setCurrency(e, currency.type);
      } else if (this.chartTypes.selected == "percentage") {
        this.formatCurrencies(currency.type);
        this.switchToPercentageChange(currency.type);
      } else {
        this.setCurrency(e, currency.type);
      }
      this.calculateAllData();
    }, 200);
  }

  formatCurrencies(currency) {
    if (!this.selectedSeries.length) return;
    this.currencies.types.map((c) => {
      let condition = currency == c.type;
      if (c.selected) {
        if (currency != c.type) {
          this.setCurrency({ checked: false }, c.type);
        }
      } else {
        if (currency == c.type && this.chartTypes != "percentage") {
          this.setCurrency({ checked: true }, c.type);
        }
      }
      c.selected = condition ? true : false;
    });
  }

  formatMeasures(unit?) {
    if (!this.selectedSeries.length) return;
    if (!unit) unit = this.selectedSeries[0].measure_unit_label;
    this.measures.previous = this.measures.selected;
    this.measures.selected = unit;
    this.measureSelectedEvent({ name: unit });
  }

  resetChartZoom() {
    this.chart.ref$.subscribe((c) => {
      c.zoomOut();
    });
  }

  setChartPeriodicities(periodicities, selectedPeriodicity?) {
    let perColumns = periodicities;
    let perOptions = this.datasetOptions.showPeriodicities;
    if (perOptions && !this.user.isLogged) {
      perColumns = perColumns.filter(
        (p) => perOptions.findIndex((o) => o.name == p) > -1
      );
      periodicities = perColumns;
    }
    let hasPeriodicity = {
      hasDaily: periodicities.includes("daily"),
      hasWeekly: periodicities.includes("weekly"),
      hasMonthly: periodicities.includes("monthly"),
    };
    this.periodicities.types = [
      {
        period: "daily",
        label: "key-daily",
        active: hasPeriodicity["hasDaily"],
      },
      {
        period: "weekly",
        label: "key-weekly",
        active: hasPeriodicity["hasWeekly"],
      },
      {
        period: "monthly",
        label: "key-monthly",
        active: hasPeriodicity["hasMonthly"],
      },
    ];
    let periodicity = selectedPeriodicity;
    if (
      periodicity &&
      hasPeriodicity[`has${this.helper.capitalizeFirstLetter(periodicity)}`]
    ) {
    } else {
      if (hasPeriodicity["hasMonthly"]) periodicity = "monthly";
      else if (hasPeriodicity["hasWeekly"]) periodicity = "weekly";
      else if (hasPeriodicity["hasDaily"]) periodicity = "daily";
    }
    setTimeout(() => {
      if (
        this.periodicities.selected &&
        periodicities.length > 1 &&
        this.periodicities.selected != periodicity &&
        this.selectedSeries.length > 1
      ) {
        this.changePeriodicity(periodicity);
        this.periodicities.selected = periodicity;
      }
    }, 100);
    this.periodicities.selected = periodicity;
  }

  changePeriodicity(periodicity) {
    let currentPeriodicity = this.periodicities.selected;
    this.periodicities.selected = periodicity;
    let ids = this.selectedSeries.map((s) => s.id);

    this.seriesGroupSelection(ids, periodicity, currentPeriodicity);
  }

  /**
   * On selecting a series from the table, adding to the chart
   *
   * @param   {any}  e  Selection event
   *
   * @return  {void}  void
   */
  selectedSeriesEvent(e: any, programmatically?): void {
    setTimeout(() => {
      if (e.type == "row") {
        this.setChartPeriodicities(
          e.data.periodicities.map((p) => p.period),
          this.selectedSeries.length == 1 ? null : this.periodicities.selected
        );
        this.performRowSingleSelection(e, programmatically);
        this.setChartCurrencies(e.data.currency);
        this.setChartMeasures(e.data);
      } else {
        let comparable = this.compatibleConstraints(e);
        if (comparable.canBeCompared) {
          if (this.selectedSeries.length == 1) {
            this.setChartCurrencies(e.data.currency);
            this.setChartMeasures(e.data);
            this.setChartPeriodicities(
              e.data.periodicities.map((p) => p.period),
              this.selectedSeries.length == 1
                ? null
                : this.periodicities.selected
            );
          } else {
            if (!comparable.commonCurrency) {
              this.currencies.dollarOnly = false;
              if (e.data.currency != "USD") this.formatCurrencies("nominal");
            } else {
              this.currencies.dollarOnly = true;
            }
            this.formatMeasures();
          }
          this.addToChart(e, null, comparable, this.chartTypes.selected);
          if (
            this.selectedSeries.length >= 2 &&
            this.chartTypes.selected != "series"
          ) {
            this.chartTypes.selected = "series";
            this.switchToTimeSeries(true);
          }
        }
      }
    }, 1);
  }

  formatCurrenciesToDollar(setCurrency?) {
    this.formatCurrencies("dollar");
    this.currencies.types = this.currencies.types.map((c) => {
      c.type != "dollar" ? (c.hidden = true) : null;
      return c;
    });
    if (setCurrency) {
      this.setCurrency({ checked: false }, "nominal");
      this.setCurrency({ checked: false }, "real");
      this.setCurrency({ checked: true }, "dollar");
    }
  }

  addToChart(e: any, checkType?, comparable?, chartType?, programmatically?) {
    let isDollar = e.data.currency == "USD";
    this.formatChartDateLabels(true);
    let color = this.helper.randDarkColor();
    this.loaders(true);
    // Check if calculated
    let req;
    let calculated;
    if (e.data.calculated) {
      calculated = e.data.calculated.find(
        (p) => p.period == this.periodicities.selected
      );
      if (calculated) {
        req = this.apis.getPrices(e.data, calculated.from);
      } else {
        req = this.apis.getPrices(e.data, this.periodicities.selected);
      }
    } else {
      req = this.apis.getPrices(e.data, this.periodicities.selected);
    }
    let subscriber = { id: e.data.id };
    subscriber["subscriber"] = req.subscribe(
      (res: any) => {
        res.results = res.datapoints;
        this.removeSubscriber(e.data.id);
        let series = this.helper.getDataPoints(
          res.results,
          null,
          this.datasetOptions.populateNulls,
          this.periodicities.selected
        );
        let chartDatapoints = _.cloneDeep(series);
        let real = this.helper.getDataPoints(
          res.results,
          "real",
          this.datasetOptions.populateNulls,
          this.periodicities.selected
        );
        let dollar = this.helper.getDataPoints(
          res.results,
          isDollar ? null : "dollar",
          this.datasetOptions.populateNulls,
          this.periodicities.selected
        );
        let originalSeries =
          this.selectedSeries.find((s) => s.id == e.data.id) || {};
        originalSeries.series = series;
        originalSeries.nominalDatapoints = series;
        originalSeries.realDatapoints = real;
        originalSeries.dollarDatapoints = dollar;
        if (calculated) {
          originalSeries = this.helper.calculatePricesBasedOnPeriodicities(
            originalSeries,
            this.periodicities.selected,
            this.datasetOptions
          );
        }
        this.chartConversions.formatAllMeasures(originalSeries);
        let measure = "";
        if (comparable && comparable.canBeCompared) {
          if (comparable.commonUnit) {
            measure = "Kg";
            this.formatMeasures("Kg");
            this.measures = {
              selected: "Kg",
              types: [
                {
                  name: "Kg",
                },
                {
                  name: "tonne",
                },
              ],
            };
          }
          chartDatapoints =
            originalSeries[
              `${isDollar ? "dollar" : "nominal"}Datapoints${measure}`
            ];
        }
        this.chart.ref.addSeries({
          id: `${e.data.id}-${isDollar ? "dollar" : "nominal"}`,
          data: _.cloneDeep(chartDatapoints),
          type: undefined,
          visible: true,
          name: this.chartConversions.getFullTitleFromSeries(
            e.data,
            null,
            this.measures.selected
          ),
          color: color,
          marker: {
            enabled: true,
            radius: 1,
          },
          yAxis: isDollar ? 1 : 0,
        });
        if (comparable && comparable.commonCurrency) {
          this.formatCurrenciesToDollar(true);
        }
        if (checkType && this.chartTypes.selected != "series") {
          if (this.chartTypes.selected == "market") {
            this.switchToMarketSeason("nominal");
          }
          if (this.chartTypes.selected == "ipa") {
            this.switchToIpa();
          }
          if (this.chartTypes.selected == "percentage") {
            this.switchToPercentageChange();
          }
        }
        // }
        this.selectedSeries[this.selectedSeries.length - 1].legendColor = color;
        this.calculateAllData();
        if (this.selectedSeries.length == 1) {
          if (isDollar) {
            this.chart.ref$.subscribe((c) => {
              c.yAxis[1].update({
                visible: true,
                title: { text: "USD/" + this.measures.selected },
              });
            });
          }
          this.resetChartZoom();
          this.chartConversions.updateAxisUnit(
            this.chart,
            0,
            this.selectedSeries[0]
          );
        } else {
          if (
            comparable &&
            comparable.diffPeriod &&
            comparable.diffPeriod != this.periodicities.selected
          ) {
            this.changePeriodicity(comparable.diffPeriod);
          }
        }
        if (chartType == "series") this.loaders(false);
        this.switchTypeAfterAddedSeries(chartType);
        this.removeSubscriber(e.data.id);
        if (programmatically) this.paginator.performing = false;
      },
      (err) => {
        this.removeSubscriber(e.data.id);
      }
    );
    this.getPointsSubscriber.push(subscriber);
  }

  switchTypeAfterAddedSeries(type) {
    if (type && type != "series") {
      this.changeChartTypeTo(type);
      this.changeChartType({ name: type });
    }
  }

  formatChartDateLabels(skipCheck?) {
    if (this.selectedSeries.length > 1 && !skipCheck) return;
    let periodicityFormat = this.helper.getChartDateLabelByPeriodicity(
      this.periodicities.selected,
      true
    );
    this.chart.ref$.subscribe((c) => {
      c.xAxis[0].update({});
      c.update({
        tooltip: {
          xDateFormat: periodicityFormat,
        },
        rangeSelector: {
          inputDateFormat: periodicityFormat,
          inputEditDateFormat: "%m-%d-%Y",
        },
      });
    });
  }

  diffMeasureUnit(e) {
    if (this.selectedSeries.length == 1) return false;
    return !(
      this.selectedSeries[this.selectedSeries.length - 2].measure_unit ==
      e.data.measure_unit
    );
  }

  removeSubscriber(id) {
    let index = this.getPointsSubscriber.findIndex((s) => s.id == id);
    this.getPointsSubscriber.splice(index, 1);
    this.loaders(false);
  }

  /**
   * On unselecting a series from the table, removing from the chart
   *
   * @param   {any}  e  Unselection event
   *
   * @return  {void}  void
   */
  async unSelectedSeriesEvent(e: any) {
    // await this.helper.sleep(200);
    let index = this.getPointsSubscriber.findIndex((s) => s.id == e.data.id);
    let subscriber = this.getPointsSubscriber[index];
    if (subscriber) {
      subscriber.subscriber.unsubscribe();
      this.removeSubscriber(e.data.id);
      return;
    }
    let chartType = this.chartTypes.selected;
    if (chartType != "series") {
      if (this.selectedSeries.length == 0) {
        this.chartTypes.selected = "series";
        this.switchToTimeSeries(true);
      } else {
        if (chartType == "ipa") {
          let index = this.chartTypes.ipaArray.findIndex(
            (s) => s.seriesId == e.data.id
          );
          this.chartTypes.ipaArray.splice(index, 1);
          if (e.data.id == this.chartTypes.selectedIPA.seriesId) {
            this.chartTypes.selectedIPA = this.chartTypes.ipaArray[0];
            this.changeIpaChart();
          }
        }
        if (chartType == "market") {
          this.chartTypes.selectedMarket = this.selectedSeries[0];
          this.switchToMarketSeason("nominal", this.chartTypes.selectedMarket);
        }
      }
      // this.chartTypes.timeSeries = this.chartTypes.timeSeries.filter(
      //   (s) => !s.userOptions.id.includes(e.data.id)
      // );
    }
    this.removeInstancesOfSeries(e.data.id);
    this.calculateAllData();
    if (this.selectedSeries.length > 1)
      this.setChartMeasures(
        this.selectedSeries[this.selectedSeries.length - 1]
      );
    if (chartType == "series") this.normalizeAfterDeselect();
  }

  normalizeAfterDeselect() {
    if (this.selectedSeries.length == 1) {
      let series = this.selectedSeries[0];
      let isDollar = series.currency == "USD";
      this.setChartMeasures(series);
      this.setChartCurrencies(series.currency);
      this.setCurrency({ checked: false }, isDollar ? "nominal" : "dollar");
      this.setCurrency({ checked: true }, isDollar ? "dollar" : "nominal");
      this.setChartPeriodicities(series.periodicities.map((p) => p.period));
    }
  }

  removeInstancesOfSeries(id) {
    this.chart.ref$.subscribe((c) => {
      let flag = true;
      while (flag) {
        var series = c.series.find((s) =>
          String(s.userOptions.id).includes(id)
        );
        if (!series) flag = false;
        else {
          c.series[
            c.series.findIndex((s) => s.userOptions.id == series.userOptions.id)
          ].remove(true);
        }
      }
    });
  }

  async clearAll() {
    this.selectedSeries = [];
    this.normalizeRowsSelectionHighlight();
    await this.helper.sleep(10);
    if (this.chartTypes.selected != "series") {
      this.chartTypes.selected = "series";
      this.switchToTimeSeries();
    }
    this.removeAllSeries();
    this.normalizeAfterDeselect();
    this.updateMapMarkers();
  }

  downloadChart() {}

  /**
   * Hovering the legend to focus series on the chart
   *
   * @param   {string}  state   State -> hover start or end
   * @param   {any}     series  Series data to be focused
   *
   * @return  {void}          void
   */
  hoverLegend(state: string, series: any): void {
    // Hover map market
    if (this.markets.data.length) {
      let color = this.helper.mapMarkersColors.hover;
      if (state == "end") {
        color = this.helper.mapMarkersColors.selected;
      }
      let market = this.markets.data.find((m) => m.id == series.market);
      let markerIdx = this.markets.markers.findIndex(
        (m) => m.marketId == series.market
      );
      let newMarker = this.helper.mapMarkerHover(market, color);
      this.markets.markers[markerIdx] = newMarker;
    }
    // Hover chart series
    let serieses = this.chart.ref.series;
    serieses.map((s) => {
      s.setState(
        state == "start"
          ? String(s.options.id).includes(series.id)
            ? "hover"
            : "inactive"
          : "normal"
      );
    });
  }

  // Admin Section
  adminOperation() {
    return {
      add: (params) => {
        this.admin.showDialog = true;
        this.admin.operation = "add";
        this.admin.params = params;
      },
      reload: () => {
        this.seriesGroupSelection(
          this.selectedSeries.map((s) => s.id),
          this.periodicities.selected
        );
        this.updateGrid();
      },
      delete: (params) => {
        this.admin.showDialog = true;
        this.admin.operation = "delete";
        this.admin.params = params;
      },
    };
  }

  updateGrid(all?) {
    this.loaders(true);
    let ids = all ? "" : this.selectedSeries.map((s) => s.id).join(",");
    this.apis.getGridSerie(ids).subscribe((res: any) => {
      let updatedSeries = res.results;
      updatedSeries.map((u) => {
        let serieIdx = this.rows.findIndex((s) => s.uuid == u.uuid);
        this.rows[serieIdx] = this.checkPeriodicityAutomaticCalculation([u]);
        this.rows[serieIdx] = this.updateSeriePeriodicity(u);
        this.rows[serieIdx] = this.updateCommodityInfoPopup(u);
        if (JSON.stringify(this.appliedFilters) != "{}") {
          let filteredIdx = this.filteredGrid.findIndex(
            (s) => s.uuid == u.uuid
          );
          this.filteredGrid[filteredIdx] = this.rows[serieIdx];
        }
        this.normalizeRowsSelectionHighlight();
        this.loaders(false);
      });
    });
  }

  async startUpdatingDatapoint(rowData, uid, i) {
    rowData[uid + "-data"].editable = true;
    await this.helper.sleep(200);
    let input = document.getElementById(`${uid}-${i}`);
    if (input) input.focus();
  }

  updateDatapointFocusEvent(rowData, uid) {
    this.admin.tempUpdate[uid] = rowData[uid];
  }

  cancelUpdateDatapoint(rowData, uid) {
    rowData[uid] = this.admin.tempUpdate[uid];
    rowData[uid + "-data"].editable = false;
  }

  updateDatapoint(rowData, uid) {
    if (rowData[uid] == null || rowData[uid].length < 1) return;
    rowData[uid + "-data"].editable = false;
    let price_id = rowData[uid + "-data"].price_id;
    this.apis
      .adminAPIs(this.user.token)
      .updateDatapoint(price_id, { price_value: rowData[uid] })
      .subscribe((res: any) => {
        this.alertService.updateDatapoint(this.words);
      });
  }

  deleteDatapoint(selectedRow) {
    if (this.admin.disableButtons) return;
    let deleteData = [];
    for (let k in selectedRow) {
      if (k.includes("data")) {
        let id = selectedRow[k].price_id;
        if (!id) continue;
        let name = this.chartConversions.getFullTitleFromSeries(
          this.selectedSeries.find((s) => s.id == k.split("-")[2]),
          null,
          null,
          true
        );
        let price_value = selectedRow[k.replace("-data", "")];
        let date = selectedRow["date"];
        deleteData.push({ name, price_value, date, id });
      }
    }
    if (!deleteData.length) return;
    this.admin.reload = this.adminOperation().reload;
    this.adminOperation().delete(deleteData);
  }

  addDatapoint() {
    if (this.admin.disableButtons) return;
    let series = [];
    // Pass selected series to the CRUD modal
    this.selectedSeries.map((s) => {
      series.push({
        uuid: s.id,
        name: this.chartConversions.getFullTitleFromSeries(s, null, null, true),
        datapoints: s.nominalDatapoints,
      });
    });
    this.adminOperation().add({
      series,
      periodicity: this.periodicities.selected,
    });
    this.admin.reload = this.adminOperation().reload;
  }
}
