<!-- <p-dialog
  [appendTo]="'body'"
  [header]="words['key-widget-chart-header']"
  [(visible)]="widgets.showDialog"
  [baseZIndex]="2500"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [style]="{ width: '90%' }"
> -->
<div class="p-grid" style="height: 100%; font-size: 0.9rem">
  <div class="p-col-6">
    <div class="p-grid">
      <div class="p-col-6">
        <h5 class="p-mb-2 p-mt-0">{{ words["key-widget-chart-title"] }}</h5>
        <input
          type="text"
          [(ngModel)]="widgets.title"
          (ngModelChange)="seriesParamChanged()"
          pInputText
          style="width: 100%"
        />
        <h5 class="p-mb-2 p-mt-3">
          {{ words["key-widget-chart-subtitle"] }}
        </h5>
        <input
          type="text"
          [(ngModel)]="widgets.subtitle"
          (ngModelChange)="seriesParamChanged()"
          pInputText
          style="width: 100%"
        />
        <div class="p-grid p-fluid">
          <div class="p-field p-col-6">
            <h5 class="p-mb-2 p-mt-3">
              {{ words["key-widget-chart-width"] }}
            </h5>
            <p-inputNumber
              [(ngModel)]="widgets.width"
              (ngModelChange)="seriesParamChanged()"
              incrementButtonClass="p-button-secondary"
              decrementButtonClass="p-button-secondary"
              [showButtons]="true"
              mode="decimal"
              [min]="200"
              [max]="2000"
              [step]="10"
            >
            </p-inputNumber>
          </div>
          <div class="p-field p-col-6">
            <h5 class="p-mb-2 p-mt-3">
              {{ words["key-widget-chart-height"] }}
            </h5>
            <p-inputNumber
              [(ngModel)]="widgets.height"
              (ngModelChange)="seriesParamChanged()"
              incrementButtonClass="p-button-secondary"
              decrementButtonClass="p-button-secondary"
              [showButtons]="true"
              mode="decimal"
              [min]="200"
              [max]="2000"
              [step]="10"
            >
            </p-inputNumber>
          </div>
          <div class="p-col-6">
            <div class="p-field">
              <h5 class="p-mb-2 p-mt-0">
                {{ words["key-widget-chart-date_range"] }}
              </h5>
              <div class="p-field-checkbox p-mt-3 p-ml-3">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="widgets.dynamicDateRange"
                  (ngModelChange)="seriesParamChanged()"
                  inputId="dateRange"
                >
                </p-checkbox>
                <label for="dateRange">{{
                  words["key-widget-chart-date_range-dynamic"]
                }}</label>
              </div>
            </div>
          </div>
          <div class="p-col-6">
            <div class="p-field">
              <h5 class="p-mb-2 p-mt-0">
                {{ words["key-widget-chart-latest_no_period"] }}
              </h5>
              <p-inputNumber
                [(ngModel)]="widgets.noPeriod"
                (ngModelChange)="seriesParamChanged()"
                incrementButtonClass="p-button-secondary"
                decrementButtonClass="p-button-secondary"
                [showButtons]="true"
                mode="decimal"
                [min]="1"
                [max]="1000"
                [step]="1"
                [disabled]="!widgets.dynamicDateRange"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-grid p-fluid">
            <div class="p-col-12">
              <div class="p-field">
                <h5 class="p-mb-2 p-mt-0">
                  {{ words["key-panel-settings-label-currency"] }}
                </h5>
                <p-multiSelect
                  [options]="currencies"
                  [(ngModel)]="widgets.selectedCurrencies"
                  optionLabel="label"
                  optionValue="value"
                  [style]="{ width: '100%' }"
                  appendTo="body"
                  [filter]="false"
                  [showHeader]="false"
                  (ngModelChange)="seriesParamChanged()"
                ></p-multiSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-6 p-pl-3">
        <div class="p-field-checkbox p-mt-5 p-mb-4">
          <p-checkbox
            binary="true"
            [(ngModel)]="widgets.enablePermalink"
            (ngModelChange)="seriesParamChanged()"
          >
          </p-checkbox>
          <label color="grey">{{ words["key-widget-chart-permalink"] }}</label>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox
            binary="true"
            [(ngModel)]="widgets.enableLegend"
            (ngModelChange)="seriesParamChanged()"
          >
          </p-checkbox>
          <label color="grey">{{
            words["key-widget-chart-enable_legend"]
          }}</label>
        </div>
        <div class="p-ml-3">
          <div class="p-grid">
            <div class="p-col-5">
              <div class="p-field-checkbox">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="widgets.options.country_name"
                  (ngModelChange)="seriesParamChanged()"
                  [disabled]="!widgets.enableLegend"
                >
                </p-checkbox>
                <label color="grey">{{
                  words["key-widget-chart-country"]
                }}</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="widgets.options.market_name"
                  (ngModelChange)="seriesParamChanged()"
                  [disabled]="!widgets.enableLegend"
                >
                </p-checkbox>
                <label color="grey">{{
                  words["key-widget-chart-location"]
                }}</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="widgets.options.commodity_name"
                  (ngModelChange)="seriesParamChanged()"
                  [disabled]="!widgets.enableLegend"
                >
                </p-checkbox>
                <label color="grey">{{
                  words["key-widget-chart-commodity"]
                }}</label>
              </div>
            </div>
            <div class="p-col-7">
              <div class="p-field-checkbox">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="widgets.options.price_type"
                  (ngModelChange)="seriesParamChanged()"
                  [disabled]="!widgets.enableLegend"
                >
                </p-checkbox>
                <label color="grey">{{
                  words["key-widget-chart-price_type"]
                }}</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox
                  binary="true"
                  [(ngModel)]="widgets.options.currency"
                  (ngModelChange)="seriesParamChanged()"
                  [disabled]="!widgets.enableLegend"
                >
                </p-checkbox>
                <label color="grey">{{
                  words["key-widget-chart-currency_measure"]
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid p-fluid" style="margin-top: -15px">
          <div class="p-field p-col-6">
            <div class="p-field">
              <h5 class="p-mb-2">
                {{ words["key-widget-chart-legend_position"] }}
              </h5>
              <p-inputNumber
                [(ngModel)]="widgets.legend"
                (ngModelChange)="seriesParamChanged()"
                incrementButtonClass="p-button-secondary"
                decrementButtonClass="p-button-secondary"
                [showButtons]="true"
                mode="decimal"
                [min]="-500"
                [max]="500"
                [step]="10"
                [disabled]="!widgets.enableLegend"
              >
              </p-inputNumber>
            </div>
          </div>
          <div class="p-field p-col-6">
            <div class="p-field">
              <h5 class="p-mb-2">
                {{ words["key-widget-chart-legend_padding"] }}
              </h5>
              <p-inputNumber
                [(ngModel)]="widgets.legendPadding"
                (ngModelChange)="seriesParamChanged()"
                incrementButtonClass="p-button-secondary"
                decrementButtonClass="p-button-secondary"
                [showButtons]="true"
                mode="decimal"
                [min]="-500"
                [max]="500"
                [step]="10"
                [disabled]="!widgets.enableLegend"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="color-picker">
      <div class="series" *ngFor="let serie of series">
        <div class="p-grid">
          <div class="p-col-8">
            <p [style]="{ color: serie.color }">
              {{ serie.seriesName }}
            </p>
          </div>
          <div class="p-col-4">
            <div class="colors">
              <p-colorPicker
                [(ngModel)]="serie.color"
                format="hex"
                styleClass="widgetColorPicker"
                appendTo="body"
                (ngModelChange)="seriesParamChanged()"
              ></p-colorPicker>
              <input
                pInputText
                [(ngModel)]="serie.color"
                (ngModelChange)="seriesParamChanged()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <textarea
      readonly="true"
      #widgetCode
      [(ngModel)]="widgets.preview"
      pInputTextarea
      style="width: 100%"
      rows="5"
    ></textarea>
    <div class="p-d-flex">
      <div>
        <button
          type="button"
          (click)="showCopyAlert()"
          pButton
          pRipple
          icon="pi pi-copy"
          class="p-button-secondary"
          [label]="words['key-widget-chart-copy_script']"
          [ngxClipboard]="widgetCode"
          [style]="{ 'font-size': '10px' }"
        ></button>
      </div>
      <div class="p-field-checkbox p-ml-2 p-mt-2">
        <p-checkbox
          name="group1"
          value="New York"
          binary="true"
          [(ngModel)]="widgets.livePreview"
          inputId="ny"
          (ngModelChange)="seriesParamChanged()"
        >
        </p-checkbox>
        <label>{{ words["key-widget-chart-live_preview"] }}</label>
      </div>
    </div>
  </div>
</div>
<div
  style="background-color: #e5e5e5; border-radius: 10px; padding: 20px"
  *ngIf="widgets.livePreview"
>
  <iframe
    id="fpma-chart-widget-iframe"
    style="border: none; transition: all 0.5s linear"
    frameborder="0"
    scrolling="no"
    async="true"
    [width]="widgets.width + 'px'"
    [height]="widgets.height + 'px'"
    [src]="widgets.url"
    frameborder="0"
  ></iframe>
</div>
<!-- </p-dialog> -->
