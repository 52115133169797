import { WidgetStatisticsComponent } from "./components/widgets/widget-statistics/widget-statistics.component";
import { WidgetChartComponent } from "./components/widgets/widget-chart/widget-chart.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BrowserComponent } from "./pages/browser/browser.component";
import { HomeComponent } from "./pages/home/home.component";
import { ToolComponent } from "./pages/tool/tool.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard/home",
    pathMatch: "full",
  },
  {
    path: "widget-chart",
    component: WidgetChartComponent,
  },
  {
    path: "widget-statistics",
    component: WidgetStatisticsComponent,
  },
  {
    path: "dashboard",
    children: [
      {
        path: "",
        component: DashboardComponent,
        children: [
          {
            path: "",
            redirectTo: "home",
            pathMatch: "full",
          },
          {
            path: "tool/:id",
            component: ToolComponent,
          },
          {
            path: "browser/:url",
            component: BrowserComponent,
          },
          {
            path: "home",
            component: HomeComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
