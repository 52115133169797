import { SettingsService } from "src/app/services/settings.service";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-browser",
  templateUrl: "./browser.component.html",
  styleUrls: ["./browser.component.scss"],
})
export class BrowserComponent implements OnInit {
  url: any = "";
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.attachEvent();
    this.listenToRouteChange();
  }

  attachEvent() {
    document
      .getElementById("internalpage-iframe")
      .addEventListener("load", () => {
        this.loading = false;
      });
  }

  listenToRouteChange() {
    this.route.params.subscribe((params) => {
      this.loading = true;
      this.settingsService.getSettings().subscribe((configs) => {
        if (!configs) return;
        this.settingsService.getCurrentLanguage().subscribe((lang) => {
          if (!lang) return;
          let page = configs.internalpages[params.url];
          let re = /(?:\.([^.]+))?$/;
          let extension = re.exec(page.url.split("/").pop())[1];
          let hasExtension = Boolean(extension);
          if (hasExtension) page.url = page.url.replace(`.${extension}`, "");
          if (page.url.includes(page.language_postfix || "__"))
            page.url = page.url.split(page.language_postfix || "__")[0];
          if (
            page.multilingual &&
            JSON.parse(page.multilingual.toLowerCase())
          ) {
            let postfix = page.language_postfix ? page.language_postfix : "__";
            if (hasExtension) page.url += `${postfix}${lang}.${extension}`;
          } else {
            if (hasExtension) page.url += `.${extension}`;
          }
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(page.url);
        });
      });
    });
  }
}
