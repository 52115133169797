import { Injectable } from "@angular/core";
import { NavigatorOptions, Options, TooltipOptions } from "highcharts";
import { BehaviorSubject } from "rxjs";
import * as moment from "moment-timezone";
import * as _ from "lodash";
import { Chart } from "angular-highcharts";
import { ExportToCsv } from "export-to-csv";

import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  private togglers: any = null;
  public words: any = {};
  public extremesObservable: BehaviorSubject<any>;
  public exporter;
  public mapMarkersColors = {
    hover: "#FFBE00",
    default: "#2081A2",
    selected: "#1DB758",
  };
  private timer;

  constructor() {
    this.togglers = {
      table: {
        data: [
          { name: "Grid View", key: "key-datagrid-grid_view", icon: "th" },
          {
            name: "Map View",
            key: "key-datagrid-map_view",
            icon: "globe",
            hide: false,
          },
        ],
        active: "Grid View",
      },
      chart: {
        data: [
          {
            name: "Chart",
            key: "key-panel-main-chart",
            icon: "chart-area",
            options: this.getOptionsTemplate("chart"),
          },
          {
            name: "Chart Data",
            key: "key-panel-main-chart_data",
            callback: "calculateChartData",
            icon: "table",
            options: this.getOptionsTemplate("chart_data"),
          },
          {
            name: "Statistics",
            key: "key-panel-main-statistics",
            callback: "calculateStatistics",
            icon: "chart-line",
            options: this.getOptionsTemplate("statistics"),
          },
          {
            name: "Information",
            key: "key-panel-main-information",
            icon: "question-circle",
            options: this.getOptionsTemplate("information"),
          },
        ],
        active: "Chart",
      },
      legend: {
        data: [
          { name: "Legend", key: "key-panel-settings-legend" },
          { name: "Options", key: "key-panel-settings-options" },
        ],
        active: "Legend",
      },
      currency: {
        data: [
          { name: "nominal", key: "key-panel-settings-label-currency-nominal" },
          { name: "real", key: "key-panel-settings-label-currency-real" },
          { name: "dollar", key: "key-panel-settings-label-currency-dollar" },
        ],
        active: "nominal",
      },
    };
  }

  getMapObject() {
    return {
      center: new google.maps.LatLng(60.382641, 15.537416),
      markerClustererImagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      options: {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        minZoom: 2,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: true,
        zoom: 2,
        height: "350px",
        styles: [
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "off" }],
          },
          {
            stylers: [{ hue: "#1c2024" }, { saturation: -90 }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ lightness: 90 }, { visibility: "simplified" }],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
        ] as google.maps.MapTypeStyle[],
      } as google.maps.MapOptions,
    };
  }

  getOptionsTemplate(name) {
    let array = [];
    array.push({
      labelkey: "key-panel-main-download_" + name,
      icon: "pi pi-cloud-download",
      type: "download",
      command: () => {},
    });
    if (name == "chart") {
      array.push({
        labelkey: "key-panel-main-widget",
        icon: "pi pi-th-large",
        type: "widget",
        command: () => {},
      });
    }
    array.push({
      labelkey: "key-panel-main-permalink",
      icon: "pi pi-external-link",
      type: "permalink",
      command: () => {},
    });
    return array;
  }

  getTogglersData(datasetOptions?) {
    return _.cloneDeep(this.togglers);
  }

  setTogglerData(togglers) {
    this.togglers = togglers;
  }

  debounce(func, timeout = 500) {
    return (...args) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  /**
   * Chart configurations
   *
   * @return  {Options} Chart options data
   */
  getChartConfigs(): Options {
    let x: NavigatorOptions;
    // x.
    this.extremesObservable = new BehaviorSubject([]);
    return {
      chart: {
        type: "line",
        margin: 10,
        marginLeft: 70,
        marginRight: 80,
        marginTop: 65,
        // zoomType: "x",
        zooming: {
          type: "x",
        },
        reflow: true,
        height: null,
        events: {},
        animation: false,
      },
      navigator: <NavigatorOptions>{
        // top: 25,
        // height: 0,
        height: 0,
        top: 25,
        outlineColor: "rgba(0,0,0,0)",
      },
      navigation: {},
      yAxis: [
        {
          className: "nominal-axis",
          offset: 20,
          visible: true,
          opposite: false,
          title: {
            text: "",
            x: -15,
          },
          labels: {
            align: "left",
          },
        },
        {
          className: "dollar-axis",
          offset: 20,
          visible: false,
          opposite: true,
          title: {
            text: "USD",
            x: 15,
          },
          labels: {
            align: "right",
          },
        },
        {
          className: "ipa-real",
          offset: 20,
          opposite: false,
          visible: false,
          title: {
            text: "Real Prices",
            x: -15,
            style: {
              color: "#007FFF",
            },
          },
          labels: {
            align: "left",
            style: {
              color: "#007FFF",
            },
          },
        },
        {
          className: "ipa-ipa",
          offset: 20,
          visible: false,
          opposite: true,
          title: {
            text: "IPA Series",
            x: 15,
          },
          labels: {
            align: "right",
          },
        },
      ],
      xAxis: [
        {
          events: {
            afterSetExtremes: (e: any) => {
              this.extremesObservable.next(e);
            },
          },
        },
      ],
      plotOptions: {
        series: {
          point: {
            events: {},
          },
          states: {
            hover: {
              opacity: 1,
            },
            inactive: { opacity: 1 },
          },
          animation: false,
          stickyTracking: true,
          shadow: false,
          dataLabels: {
            style: {
              textShadow: false,
            },
          },
          connectNulls: false,
        },
      },
      exporting: {
        enabled: false,
        fallbackToExportServer: false,
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
      },
      title: {
        text: "",
        align: "left",
      },
      subtitle: {
        text: "",
        align: "left",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        animation: false,
        shared: true,
        valueDecimals: 2,
        outside: true,
      },
      rangeSelector: {
        enabled: true,
        inputEnabled: true,
        allButtonsEnabled: false,
        inputPosition: {
          x: -60,
        },
        inputBoxBorderColor: "hidden",
        inputStyle: {
          color: "black",
        },
        buttonPosition: {
          x: -20,
        },
        labelStyle: {
          color: "white",
        },
        buttons: [
          {
            type: "month",
            count: 3,
            text: "3m",
          },
          {
            type: "month",
            count: 6,
            text: "6m",
          },
          {
            type: "year",
            count: 1,
            text: "1y",
          },
          {
            type: "year",
            count: 2,
            text: "2y",
          },
          {
            type: "year",
            count: 3,
            text: "3y",
          },
          {
            type: "all",
            text: "All",
          },
        ],
      },
    };
  }

  returnSparkLineChart(data: any) {
    return new Chart({
      title: {
        text: "",
      },
      chart: {
        renderTo: "Sparkline-" + data.id,
        height: 100,
        width: 100,
        type: "column",
        margin: [2, 0, 2, 0],
        style: {
          padding: "0 20 0 20",
          cursor: "pointer",
        },
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        visible: false,
        tickWidth: 5,
        width: 5,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        type: "category",
        visible: false,
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          // minPointLength: 3,
          threshold: 0,
          negativeColor: "#017D3B",
          color: "#940411",
        },
        column: {
          pointPadding: 0,
          groupPadding: 0,
        },
      },
      tooltip: {
        split: false,
        enabled: true,
        backgroundColor: "black",
        borderWidth: 0,
        borderRadius: 5,
        style: {
          fontSize: "13px",
          color: "white",
          padding: "0",
          width: 200,
          height: 50,
        },
        outside: true,
        headerFormat: "",
        formatter: function (point) {
          return "<b>" + this.key + ", " + this.y + "</b>";
        },
        // headerFormat: '<span style="font-size: 9px">{point.key}</span><br/>'
      },
      series: <any>[
        {
          data: data.value,
        },
      ],
    });
  }

  getMarkerIconByColor(color) {
    return {
      path: faMapMarkerAlt.icon[4] as string,
      fillColor: color,
      fillOpacity: 1,
      anchor: new google.maps.Point(
        faMapMarkerAlt.icon[0] / 2, // width
        faMapMarkerAlt.icon[1] // height
      ),
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.075,
    };
  }

  getMarkerColorBasedOnFilterAndSelection(selectedIds, marketId) {
    let color;
    if (selectedIds.includes(marketId)) {
      color = this.mapMarkersColors.selected;
    } else {
      color = this.mapMarkersColors.default;
    }
    return color;
  }

  updateMarkersIconsAfterSelectionAndFilter(markets, filteredIds, selectedIds) {
    let markers = [];
    let bounds = new google.maps.LatLngBounds();
    markets.map((m) => {
      if (filteredIds.includes(m.id)) {
        let color = this.getMarkerColorBasedOnFilterAndSelection(
          selectedIds,
          m.id
        );
        let marker = this.getMarkerTemplate(m, color);
        bounds.extend(new google.maps.LatLng(m.latitude, m.longitude));
        markers.push(marker);
      }
    });
    return [bounds, markers];
  }

  mapMarkerHover(market, color) {
    return this.getMarkerTemplate(market, color);
  }

  getMarkerTemplate(market, color) {
    return <google.maps.MarkerOptions>{
      marketId: market.id,
      position: {
        lat: market.latitude,
        lng: market.longitude,
      },
      options: {
        icon: this.getMarkerIconByColor(color),
      },
    };
  }

  getChartTypes() {
    return {
      selected: "series",
      types: [
        {
          name: "series",
          label: "key-panel-settings-label-chart_type-time_series",
        },
        {
          name: "percentage",
          label: "key-panel-settings-label-chart_type-percentage_change",
        },
        {
          name: "market",
          label: "key-panel-settings-label-chart_type-market_season",
        },
        {
          name: "ipa",
          label: "key-panel-settings-label-chart_type-ipa",
          options: [
            {
              label: "key-panel-settings-label-chart_type-ipa-monthly",
              value: "monthly",
            },
            {
              label: "key-panel-settings-label-chart_type-ipa-annual",
              value: "annual",
            },
          ],
          model: "ipaType",
        },
      ],
      ignoreNegative: true,
      ipaType: "monthly",
    };
  }

  getStatisticsTemplate(currentOption?) {
    return {
      rows: [],
      cols: [],
      currentOption: currentOption || "periodicity",
      timeMinusVariantSparkChart: 5,
      timeMinusVariant: 13,
      showModal: false,
      chartTitle: "",
    };
  }

  formatNumbersForStatistics(statistics) {
    return statistics.rows.map((row) => {
      for (let k in row) {
        if (!isNaN(row[k])) row[k] = Number(row[k]);
      }
      return row;
    });
  }

  getStatisticsChart(data: any) {
    return new Chart({
      title: {
        text: "",
      },
      chart: {
        renderTo: "Sparkline-" + data.id,
        height: 400,
        width: 600,
        type: "column",
        backgroundColor: "rgba(0,0,0,0)",
        // margin: [2, 0, 2, 0],
        style: {
          padding: "0 20 0 20",
          cursor: "pointer",
        },
      },
      yAxis: {
        labels: {
          enabled: true,
        },
        title: {
          text: "t-1 %Δ Percentage Change",
        },
        visible: true,
      },
      xAxis: {
        labels: {
          enabled: true,
        },
        title: {
          text: null,
        },
        type: "category",
        visible: true,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          // minPointLength: 3,
          point: {},
          threshold: 0,
          negativeColor: "#017D3B",
          color: "#940411",
          dataLabels: {
            enabled: true,
            format: "{y}%",
          },
        },
        column: {
          pointWidth: 50,
        },
      },
      tooltip: {
        split: false,
        enabled: true,
        backgroundColor: "black",
        borderWidth: 0,
        borderRadius: 5,
        style: {
          fontSize: "9px",
          color: "white",
          padding: "0",
        },
        headerFormat: "",
        formatter: function () {
          return "<b>" + this.key + ", " + this.y + "</b>";
        },
      },
      series: <any>[
        {
          data: data.value,
        },
      ],
    });
  }

  getMainChartTooltip(type, isAnnual?, periodicity?) {
    let tooltip = {
      series: {
        pointFormat: "{series.name}: <b>{point.y}</b>",
        xDateFormat: this.getChartDateLabelByPeriodicity(periodicity, true),
      } as TooltipOptions,
      market: {
        pointFormat: "{point.name} {point.year}: <b>{point.y}</b>",
      } as TooltipOptions,
      ipa: {
        pointFormat: `<span style="color:{point.color}">\u25CF</span>  <b>{point.y}</b>`,
        xDateFormat: isAnnual ? `%Y` : "%b-%y",
      } as TooltipOptions,
      percentage: {
        pointFormat: `<span style="color:{point.color}">\u25CF</span>  <b>{point.y} %</b>`,
        xDateFormat: "%b-%y",
      } as TooltipOptions,
    };
    return tooltip[type];
  }

  public getMonthsShortNames() {
    return moment.monthsShort();
  }

  public getMonthsLongNames() {
    return moment.months();
  }

  cipher(salt, text) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  }

  decipher(salt, encoded) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  }

  /**
   * Generating UUID
   *
   * @return  {string}  UUID String
   */
  uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  /**
   * Generating random dark color
   *
   * @return  {string}  Random dark color
   */
  randDarkColor(): string {
    var lum = -0.25;
    var hex = String(
      "#" + Math.random().toString(16).slice(2, 8).toUpperCase()
    ).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }
    return rgb;
  }

  /**
   * Group by an array of objects by key
   *
   * @param   {any[]}   xs   The array
   * @param   {string}  key  The key of the object
   *
   * @return  {any[]}           Groupped by array
   */
  groupBy(xs: any[], key: string): any[] {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  getDataPoints(data, currency?, populateNulls?, periodicity?) {
    let property = "price_value" + (currency ? `_${currency}` : "");
    let sorted = data
      .sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .map((d) => {
        return [
          new Date(d["date"]).getTime(),
          d[property] ? Number(d[property]) : null,
          d["id"],
        ];
      });
    if (populateNulls) sorted = this.populateNulls(sorted, periodicity);
    return sorted;
  }

  populateNulls(data, periodicity) {
    let unit: any = this.getTimeUnitFromPeriodicity(periodicity);
    let i = 0;
    if (!data) return;
    while (i < data.length - 1) {
      const d = data[i];
      let date = moment(d[0]);
      let nextDate = moment(data[i + 1][0]);
      let diff = Math.round(nextDate.diff(date, unit, true));
      if (Number(diff) > 1) {
        for (let j = 0; j < diff - 1; j++) {
          date = date.add(1, unit);
          data.splice(i + j + 1, 0, [date.valueOf(), null]);
        }
      }
      i = i + 1;
    }
    return data.sort((a: any, b: any) => {
      return new Date(a[0]).getTime() - new Date(b[0]).getTime();
    });
  }

  getGridDateFromPeriodicity(per) {
    return (
      this.getformatDateByPeriodicity(per.period, per.start_date) +
      " - " +
      this.getformatDateByPeriodicity(per.period, per.end_date)
    );
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }

  getTimeUnitFromPeriodicity(periodicity, capitalize?) {
    let unit = "";
    if ((periodicity || "").toLowerCase() == "weekly") unit = "week";
    if ((periodicity || "").toLowerCase() == "monthly") unit = "month";
    if ((periodicity || "").toLowerCase() == "daily") unit = "day";
    return capitalize ? this.capitalizeFirstLetter(unit) : unit;
  }

  getformatDateByPeriodicity(periodicity, date?, formatOnly?) {
    let format = "DD-MMM-YY";
    if ((periodicity || "").toLowerCase() == "weekly") format = "DD-MMM-YY";
    if ((periodicity || "").toLowerCase() == "monthly") format = "MMM-YY";
    if ((periodicity || "").toLowerCase() == "daily") format = "DD-MMM-YY";
    if (formatOnly) return format;
    let date_obj = moment(date);
    if (date_obj.isValid()) {
      return date_obj.format(format);
    }
    return "";
  }

  getChartDateLabelByPeriodicity(periodicity, showMonthNames?) {
    let b = showMonthNames ? "%b" : "%m";
    let format = "%b-%y";
    if ((periodicity || "").toLowerCase() == "weekly")
      format = `${showMonthNames ? "%d-" + b : b + "-%d"}-%y`;
    if ((periodicity || "").toLowerCase() == "monthly") format = `${b}-%y`;
    if ((periodicity || "").toLowerCase() == "daily")
      format = `${showMonthNames ? "%d-" + b : b + "-%d"}-%y`;
    return format;
  }

  doScaledTimeout(n, i, callback) {
    setTimeout(() => {
      callback();
    }, i * n);
  }

  capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getExporterTemplate() {
    return {
      exportingMenu: [],
      showDialog: false,
      enableSelection: false,
      filename: "Filename_" + new Date().toDateString().split(" ").join("_"),
      options: [
        { icon: "pi pi-file-o", name: "CSV", value: "CSV" },
        { icon: "pi pi-file-excel", name: "Excel", value: "Excel" },
        { icon: "pi pi-file-pdf", name: "PDF", value: "PDF" },
      ],
    };
  }

  downloadGrid(filteredGrid, cols) {
    let data = [];
    filteredGrid.map((d) => {
      let object = {};
      cols.map((c) => {
        object[c.field] = d[c.field];
      });
      data.push(object);
    });
    const options = {
      filename: "series",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: cols.map((c) => c.name),
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  exportCSV(options?) {
    let table = _.cloneDeep(this.exporter.table);
    table.columns = table.columns.map((c) => {
      if (!c.field) c.field = c.uid;
      if (!c.header) c.header = c.name || c.field;
      return c;
    });
    table.exportCSV(options);
  }

  exportPDF() {
    let rows;
    if (this.exporter.enableSelection && this.exporter.selectionOnly) {
      rows = this.exporter.table.selection;
    } else {
      rows = this.exporter.table.value;
    }
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then((x) => {
        const doc = new jsPDF.default("p", "pt");
        doc.setFontSize(6);
        // doc.autoTable(
        //   this.exporter.table.columns.map((col) => ({
        //     title: col.field || col.name || col.label,
        //     dataKey: col.field || col.uid,
        //   })),
        //   rows,
        //   {
        //     styles: {
        //       fontSize: 6,
        //     },
        //   }
        // );
        // doc.save(this.exporter.filename + ".pdf");
      });
    });
  }

  exportExcel() {
    let cols = this.exporter.table.columns;
    let allrows;
    if (this.exporter.enableSelection && this.exporter.selectionOnly) {
      allrows = this.exporter.table.selection;
    } else {
      allrows = this.exporter.table.value;
    }
    let rows = [];
    allrows.map((r) => {
      let row = {};
      cols.map((c) => {
        row[c.field || c.name || c.label] = r[c.field || c.uid];
      });
      rows.push(row);
    });
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(rows);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      xlsx.writeFile(workbook, this.exporter.filename + ".xlsx", {
        bookType: "xlsx",
      });
    });
  }

  exportSvgToImage(svg) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    canvas.width = svg.getBoundingClientRect().width;
    canvas.height = svg.getBoundingClientRect().height;

    var img = document.createElement("img");
    img.setAttribute(
      "src",
      "data:image/svg+xml;base64," +
        window.btoa(
          unescape(
            encodeURIComponent(new XMLSerializer().serializeToString(svg))
          )
        )
    );

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      let content = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      var link = document.createElement("a");
      link.download =
        "Chart_" + new Date().toDateString().split(" ").join("_") + ".png";
      link.href = content;
      link.click();
    };
  }

  getWidgetsTemplate() {
    return {
      chart: {
        enableLegend: true,
        livePreview: true,
        enablePermalink: true,
        width: 840,
        height: 400,
        legend: 0,
        legendPadding: 40,
        dynamicDateRange: false,
        noPeriod: 24,
        selectedCurrencies: ["nominal"],
        options: {
          country_name: true,
          market_name: true,
          commodity_name: true,
          price_type: true,
          currency: true,
        },
      },
      statistics: {
        enableLegend: true,
        livePreview: true,
        enablePermalink: true,
        width: 1120,
        height: 400,
        dynamicDateRange: false,
        noPeriod: 24,
        fields: [],
        selectedCurrencies: ["nominal"],
        currentOption: "",
        timeMinusVariantSparkChart: 5,
        timeMinusVariant: 13,
      },
    };
  }

  getWidgetsCurrencies() {
    return [
      { label: "Nominal Price", value: "nominal" },
      { label: "Real Price", value: "real" },
      { label: "USD Price", value: "dollar" },
    ];
  }

  getChartWidgetCode(selectedSeries, widgets, measure, periodicity, dataset) {
    let ids = this.getSeriesUrlStructure(selectedSeries, widgets, "chart");
    let url = this.getChartWidgetUrl(
      ids,
      widgets,
      measure,
      periodicity,
      dataset
    );
    return {
      previewCode: this.widgetPreviewCode(url, widgets),
      url,
    };
  }

  getStatisticsWidgetCode(
    selectedSeries,
    widgets,
    measure,
    periodicity,
    dataset,
    currentOption,
    selectedFields,
    tParams,
    langKey
  ) {
    let ids = this.getSeriesUrlStructure(selectedSeries, widgets, "statistics");
    let url = this.getStatisticsWidgetUrl(
      ids,
      widgets,
      measure,
      periodicity,
      dataset,
      currentOption,
      selectedFields,
      tParams,
      langKey
    );
    return {
      previewCode: this.widgetPreviewCode(url, widgets),
      url,
    };
  }

  getSeriesUrlStructure(selectedSeries, widgets, type) {
    let ids = selectedSeries.map((s) => {
      let name = null;
      let nameArray = [];
      let options = widgets.options;
      if (widgets.enableLegend) {
        for (let k in options) {
          if (options[k] == true) {
            if (k == "currency") {
              nameArray.push(`${s[k]}/${s.measure_unit_label}`);
            } else {
              nameArray.push(s[k]);
            }
          }
        }
        name = nameArray.join(",");
      }

      if (type == "chart") return [name, s.id, s.color.replace("#", "")];

      if (type == "statistics")
        return [
          s.id,
          s.color.replace("#", ""),
          s.country_name,
          s.price_type,
          s.market_name,
          s.commodity_name,
          s.currency,
          s.measure_unit_label,
        ];
    });
    return ids;
  }

  private getChartWidgetUrl(ids, widgets, measure, periodicity, dataset) {
    let url = `${window.location.origin}${
      window.location.pathname
    }#/widget-chart?wi=${JSON.stringify(ids)}&m=${measure}&w=${
      widgets.width
    }&cs=${JSON.stringify(widgets.selectedCurrencies)}&h=${widgets.height}&e=${
      widgets.enableLegend
    }&l=${widgets.legend}&p=${widgets.legendPadding}&d=${
      widgets.dynamicDateRange
    }&n=${widgets.noPeriod}&per=${periodicity}&perm=${widgets.enablePermalink}`;
    if (widgets.title) url += `&t=${widgets.title}`;
    if (widgets.subtitle) url += `&s=${widgets.subtitle}`;
    if (JSON.parse(widgets.enablePermalink || null))
      url += `&dataset=${dataset}`;
    url = url.split("%").join("{[{|}]}");
    return url;
  }

  getStatisticsWidgetUrl(
    ids,
    widgets,
    measure,
    periodicity,
    dataset,
    currentOption,
    selectedFields,
    tParams,
    langKey
  ) {
    let url = `${window.location.origin}${
      window.location.pathname
    }#/widget-statistics?wi=${JSON.stringify(
      ids
    )}&m=${measure}&c=${widgets.selectedCurrencies.join(",")}&w=${
      widgets.width
    }&h=${widgets.height}&d=${widgets.dynamicDateRange}&n=${
      widgets.noPeriod
    }&per=${periodicity}&perm=${
      widgets.enablePermalink
    }&langKey=${langKey}&type=${currentOption}&fields=${selectedFields.join(
      ","
    )}`;
    if (tParams) {
      url += `&t1=${tParams.t1}`;
      url += `&tn=${tParams.tn}`;
    }
    if (widgets.title) url += `&t=${widgets.title}`;
    if (widgets.subtitle) url += `&s=${widgets.subtitle}`;
    if (JSON.parse(widgets.enablePermalink || null))
      url += `&dataset=${dataset}`;
    url = url.split("%").join("{[{|}]}");
    return url;
  }

  private widgetPreviewCode(url, widgets) {
    return `<iframe style="border: none" frameborder="0" scrolling="no" async="true" width="${widgets.width}px" height="${widgets.height}px"
src='${url}'
frameborder="0"></iframe>`;
  }

  createPermalink(ids, name) {
    let url = `${window.location.origin}${
      window.location.pathname
    }#/dashboard/tool/${name}?permalink=${ids.join(",")}`;
    return url;
  }

  public getSelectedCurrencies(currencies) {
    let selections = [];
    for (let k in currencies.selections) {
      if (currencies.selections[k] == true)
        selections.push(k.split("Selected")[0]);
    }
    return selections;
  }

  calculatePeriodicities(period, from, data) {
    data.map((d) => {
      let original = d.periodicity.find((p) => p.period == from);
      let newPeriod = d.periodicity.find((p) => p.period == period);
      if (original && !newPeriod) {
        newPeriod = _.cloneDeep(original);
        newPeriod.period = period;
        newPeriod.calculated = true;
        d.periodicity.push(newPeriod);
        if (!d.calculated) d.calculated = [];
        d.calculated.push({ period, from });
      }
      return d;
    });
    return data;
  }

  calculatePricesBasedOnPeriodicities(
    series,
    periodicity,
    options,
    datapoints?
  ) {
    let populateNulls = options.populateNulls;
    let weekStart = options.weekStart;
    let calculated = series.calculated.find((c) => c.period == periodicity);
    let period = calculated.period;
    let from = calculated.from;
    let unit = this.getTimeUnitFromPeriodicity(period);
    if (datapoints) {
      series.nominalDatapoints = this.getDataPoints(
        datapoints,
        null,
        populateNulls,
        periodicity
      );
      series.realDatapoints = this.getDataPoints(
        datapoints,
        "real",
        populateNulls,
        periodicity
      );
      series.dollarDatapoints = this.getDataPoints(
        datapoints,
        "dollar",
        populateNulls,
        periodicity
      );
    }
    series.series = (series.nominalDatapoints =
      this.calculateFromPeriodicity(
        series.nominalDatapoints,
        unit,
        from,
        weekStart
      ) || []).sort((a, b) => {
      b[0] - a[0];
    });
    // series.realDatapoints = (
    //   this.calculateFromPeriodicity(
    //     series.realDatapoints,
    //     unit,
    //     from,
    //     weekStart
    //   ) || []
    // ).sort((a, b) => {
    //   b[0] - a[0];
    // });
    // series.dollarDatapoints = (
    //   this.calculateFromPeriodicity(
    //     series.dollarDatapoints,
    //     unit,
    //     from,
    //     weekStart
    //   ) || []
    // ).sort((a, b) => {
    //   b[0] - a[0];
    // });
    if (populateNulls) {
      series.nominalDatapoints = series.series = this.populateNulls(
        series.nominalDatapoints,
        period
      );
      series.realDatapoints = this.populateNulls(series.realDatapoints, period);
      series.dollarDatapoints = this.populateNulls(
        series.dollarDatapoints,
        period
      );
    }
    return series;
  }

  calculateFromPeriodicity(datapoints, unit, from, weekStart, addExtraDay?) {
    if (from == "daily") {
      if (addExtraDay == undefined && unit == "monthly") addExtraDay = true;
      let d = datapoints[0];
      if (!d) return;
      if (weekStart != undefined) {
        moment.updateLocale("en", {
          week: {
            dow: weekStart,
          },
        });
      }
      let end_date = moment(d[0]).utc().endOf(unit);
      if (addExtraDay) end_date.add(1, "day");
      let data = [];
      let lastIdx;
      datapoints = _.filter(datapoints, (d) => d[1]);
      datapoints.map((d, i) => {
        let date = moment(d[0]);
        if (date.utc().isAfter(end_date)) {
          let avgPrice = this.getAvgPriceFromInterval(datapoints, lastIdx, i);
          let newDate = end_date.valueOf();
          data.push([newDate, avgPrice]);
          end_date = date.utc().endOf(unit);
          if (addExtraDay) end_date.add(1, "day");
          lastIdx = i;
        }
      });
      return data;
    }
    if (from == "weekly") {
      let d = datapoints[0];
      if (!d) return;
      let dailyData = [];
      datapoints
        .filter((d) => d[1])
        .map((d) => {
          let date = moment(moment(d[0]).toISOString());
          if (Number(date.format("D")) <= 3) {
            let weekBefore = moment(date).subtract(1, "week");
            d[0] = weekBefore.valueOf();
          }
          dailyData.push([d[0], d[1], d[2]]);
        });
      dailyData = dailyData.sort((a, b) => a[0] - b[0]);
      return this.calculateFromPeriodicity(
        dailyData,
        "month",
        "daily",
        weekStart,
        false
      );
    }
  }

  getAvgPriceFromInterval(arr, start, end) {
    return Math.round(_.meanBy(arr.slice(start, end), (m) => m[1]) * 100) / 100;
  }

  getDesiredPeriodicityForCalculations(periodicities, unit) {
    return periodicities.find((p) => p.period == unit).from;
  }

  sortByDateFormatted(arr, format) {
    return arr.sort((a, b) => {
      return (
        moment(b.date, format).valueOf() - moment(a.date, format).valueOf()
      );
    });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  confirmSorting(series) {
    let properties = [
      "nominalDatapoints",
      "realDatapoints",
      "dollarDatapoints",
      "nominalDatapointsKg",
      "nominalDatapointsTonne",
      "realDatapointsKg",
      "realDatapointsTonne",
      "dollarDatapointsKg",
      "dollarDatapointsTonne",
    ];
    properties.map((property) => {
      if (series[property]) {
        series[property] = series[property].sort((a, b) => a[0] - b[0]);
      }
    });
    return series;
  }

  customSort(event, selectedPeriodicity) {
    return event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string") {
        let dateFormat = this.getformatDateByPeriodicity(
          selectedPeriodicity || event.data[0].periodicity,
          null,
          true
        );
        if (
          event.field == "date" ||
          event.field == "start_date" ||
          event.field == "end_date" ||
          event.field == "max_date" ||
          event.field == "min_date"
        ) {
          let date1 = moment(value1, dateFormat);
          let date2 = moment(value2, dateFormat);
          result = date1 > date2 ? 1 : -1;
        } else if (
          event.field == "monthly" ||
          event.field == "weekly" ||
          event.field == "daily"
        ) {
          let date1 = moment(value1.split(" - ")[1], dateFormat);
          let date2 = moment(value2.split(" - ")[1], dateFormat);
          result = date1 > date2 ? 1 : -1;
        } else {
          result = value1.localeCompare(value2);
        }
      } else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  getStandardDeviation(array) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  }

  roundDecimal(val, decimal) {
    return Math.round(val * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  formatCSVColumnValue(str, operator?) {
    str = str.toLowerCase();
    str = this.replaceAll(str, " ", operator || "");
    str = this.replaceAll(str, "-", operator || "");
    str = this.replaceAll(str, "_", operator || "");
    return str;
  }

  bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(
      String(Math.floor(Math.log(bytes) / Math.log(1024))),
      10
    );
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  isValidHttpAPI(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
}
