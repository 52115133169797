import { IpaCalculatorService } from "./ipa-calculator.service";
import { ChartConversionsService } from "./chart-conversions.service";
import { HelperService } from "./helper.service";
import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  constructor(
    private helper: HelperService,
    private chartConversions: ChartConversionsService,
    private ipaCalculator: IpaCalculatorService
  ) {}

  getColumns(statistics, type, periodicity, words) {
    let label = this.helper.getTimeUnitFromPeriodicity(periodicity, true);
    if (type == "percentage") {
      if (periodicity == "daily") {
        return [
          {
            uid: "series",
            label: (
              words["key-panel-main-statistics-column-series"] || ""
            ).replace("{periodicity}", periodicity),
          },
          {
            uid: "latest_date",
            label: words["key-panel-main-statistics-column-latest_t_date"],
            group: "#0091EA",
          },
          {
            uid: "latest_price",
            label: words["key-panel-main-statistics-column-latest_t_value"],
            group: "#0091EA",
          },
          {
            uid: "timeMinus1_date",
            label: (
              words["key-panel-main-statistics-column-t_1_date"] || ""
            ).replace("{periodicity}", label),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus1_price",
            label: (
              words["key-panel-main-statistics-column-t_1_value"] || ""
            ).replace("{periodicity}", label),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus1_percent",
            label: (
              words["key-panel-main-statistics-column-t_1_change"] || ""
            ).replace("{periodicity}", label),
            background: "#d4f6ff",
            colorable: true,
            group: "#00CCFF",
          },
          {
            uid: "timeMinus12_date",
            label: words["key-panel-main-statistics-column-t_12_date_month"],
            group: "#8F01FB",
          },
          {
            uid: "timeMinus12_price",
            label: words["key-panel-main-statistics-column-t_12_value_month"],
            group: "#8F01FB",
          },
          {
            uid: "timeMinus12_percent",
            label: words["key-panel-main-statistics-column-t_12_change_month"],
            background: "#F1E2FD",
            colorable: true,
            group: "#8F01FB",
          },
          {
            uid: "timeMinusVariant_date",
            label: (
              words["key-panel-main-statistics-column-t_variant_date"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", periodicity),
            group: "#FFBE58",
          },
          {
            uid: "timeMinusVariant_price",
            label: (
              words["key-panel-main-statistics-column-t_variant_value"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            group: "#FFBE58",
          },
          {
            uid: "timeMinusVariant_percent",
            label: (
              words["key-panel-main-statistics-column-t_variant_change"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            background: "#FFEDD2",
            colorable: true,
            group: "#FFBE58",
          },
        ];
      }
      if (periodicity == "weekly") {
        return [
          {
            uid: "series",
            label: (
              words["key-panel-main-statistics-column-series"] || ""
            ).replace("{periodicity}", periodicity),
          },
          {
            uid: "latest_date",
            label: words["key-panel-main-statistics-column-latest_t_date"],
            group: "#0091EA",
          },
          {
            uid: "latest_price",
            label: words["key-panel-main-statistics-column-latest_t_value"],
            group: "#0091EA",
          },
          {
            uid: "timeMinus1_date",
            label: (
              words["key-panel-main-statistics-column-t_1_date"] || ""
            ).replace("{periodicity}", label),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus1_price",
            label: (
              words["key-panel-main-statistics-column-t_1_value"] || ""
            ).replace("{periodicity}", label),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus1_percent",
            label: (
              words["key-panel-main-statistics-column-t_1_change"] || ""
            ).replace("{periodicity}", label),
            background: "#d4f6ff",
            colorable: true,
            group: "#00CCFF",
          },
          {
            uid: "timeMinus12_date",
            label: words["key-panel-main-statistics-column-t_12_date_year"],
            group: "#8F01FB",
          },
          {
            uid: "timeMinus12_price",
            label: words["key-panel-main-statistics-column-t_12_value_year"],
            group: "#8F01FB",
          },
          {
            uid: "timeMinus12_percent",
            label: words["key-panel-main-statistics-column-t_12_change_year"],
            background: "#F1E2FD",
            colorable: true,
            group: "#8F01FB",
          },
          {
            uid: "timeMinusVariant_date",
            label: (
              words["key-panel-main-statistics-column-t_variant_date"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            group: "#FFBE58",
          },
          {
            uid: "timeMinusVariant_price",
            label: (
              words["key-panel-main-statistics-column-t_variant_value"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            group: "#FFBE58",
          },
          {
            uid: "timeMinusVariant_percent",
            label: (
              words["key-panel-main-statistics-column-t_variant_change"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            background: "#FFEDD2",
            colorable: true,
            group: "#FFBE58",
          },
        ];
      }
      if (periodicity == "monthly") {
        return [
          {
            uid: "series",
            label: (
              words["key-panel-main-statistics-column-series"] || ""
            ).replace("{periodicity}", periodicity),
          },
          {
            uid: "latest_date",
            label: words["key-panel-main-statistics-column-latest_t_date"],
            group: "#0091EA",
          },
          {
            uid: "latest_price",
            label: words["key-panel-main-statistics-column-latest_t_value"],
            group: "#0091EA",
          },
          {
            uid: "timeMinus1_date",
            label: (
              words["key-panel-main-statistics-column-t_1_date"] || ""
            ).replace("{periodicity}", label),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus1_price",
            label: (
              words["key-panel-main-statistics-column-t_1_value"] || ""
            ).replace("{periodicity}", label),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus1_percent",
            label: (
              words["key-panel-main-statistics-column-t_1_change"] || ""
            ).replace("{periodicity}", label),
            background: "#d4f6ff",
            colorable: true,
            group: "#00CCFF",
          },
          {
            uid: "timeMinusVariantYears",
            label: (
              words[
                "key-panel-main-statistics-column-t_variant_latest_years"
              ] || ""
            ).replace(
              "{timeMinusVariant}",
              statistics.timeMinusVariantSparkChart
            ),
            group: "#00CCFF",
          },
          {
            uid: "timeMinus12_date",
            label: words["key-panel-main-statistics-column-t_12_date_year"],
            group: "#8F01FB",
          },
          {
            uid: "timeMinus12_price",
            label: words["key-panel-main-statistics-column-t_12_value_year"],
            group: "#8F01FB",
          },
          {
            uid: "timeMinus12_percent",
            label: words["key-panel-main-statistics-column-t_12_change_year"],
            background: "#F1E2FD",
            colorable: true,
            group: "#8F01FB",
          },
          {
            uid: "timeMinusVariant_date",
            label: (
              words["key-panel-main-statistics-column-t_variant_date"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            group: "#FFBE58",
          },
          {
            uid: "timeMinusVariant_price",
            label: (
              words["key-panel-main-statistics-column-t_variant_value"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            group: "#FFBE58",
          },
          {
            uid: "timeMinusVariant_percent",
            label: (
              words["key-panel-main-statistics-column-t_variant_change"] || ""
            )
              .replace("{timeMinusVariant}", statistics.timeMinusVariant)
              .replace("{periodicity}", label),
            background: "#FFEDD2",
            colorable: true,
            group: "#FFBE58",
          },
        ];
      }
    }
    return [
      {
        uid: "series",
        label: (words["key-panel-main-statistics-column-series"] || "").replace(
          "{periodicity}",
          periodicity
        ),
      },
      {
        uid: "start_date",
        label: words["key-panel-main-statistics-column-start"],
      },
      { uid: "end_date", label: words["key-panel-main-statistics-column-end"] },
      {
        uid: "count",
        label: words["key-panel-main-statistics-column-datapoints"],
      },
      {
        uid: "percentage_change",
        label: words["key-panel-main-statistics-column-percentage_change"],
        colorable: true,
      },
      {
        uid: "avg_price",
        label: words["key-panel-main-statistics-column-avg_price"],
      },
      {
        uid: "std_dev",
        label: words["key-panel-main-statistics-column-std_dev"],
      },
      {
        uid: "min_price",
        label: words["key-panel-main-statistics-column-min_price"],
      },
      {
        uid: "min_date",
        label: words["key-panel-main-statistics-column-min_date"],
      },
      {
        uid: "max_price",
        label: words["key-panel-main-statistics-column-max_price"],
      },
      {
        uid: "max_date",
        label: words["key-panel-main-statistics-column-max_date"],
      },
      {
        uid: "price_range",
        label: words["key-panel-main-statistics-column-price_range"],
      },
    ];
  }

  calculateStatistics(selectedSeries, type, params, periodicity, words) {
    if (type == "ipa") {
      return this.getIpaStatistics(selectedSeries, params);
    }
    let series = _.cloneDeep(selectedSeries);
    if (!series.length) return;
    let rows = [];
    let selectedCurrencies = params.selectedCurrencies;
    selectedCurrencies.map((c) => {
      series.map((s) => {
        let isDollar = s.currency == "USD";
        let property =
          this.chartConversions.getSeriesPropertyNameBasedOnCurrencyAndMeasure(
            c,
            params.measure,
            isDollar
          );
        let datapoints = s[property];
        if (!datapoints) return;
        s.data = _.uniqBy(
          datapoints.filter(
            (d) =>
              moment(d[0]) >= moment(params.extremes.min) &&
              moment(d[0]) <= moment(params.extremes.max)
          ),
          (d) => d[0]
        );
        let row =
          type == "periodicity"
            ? this.getCalculationForPeriodReport(s, periodicity)
            : this.getCalculationForLatestReport(
                s,
                periodicity,
                params.statistics.timeMinusVariant,
                params.statistics.timeMinusVariantSparkChart
              );
        row["series"] = this.chartConversions.getFullTitleFromSeries(
          s,
          c,
          params.measure
        );
        row["legendColor"] =
          c == "nominal"
            ? s.legendColor
            : s[`legendColor${this.helper.capitalizeFirstLetter(c)}`];
        rows.push(row);
      });
      params.statistics.cols = this.getColumns(
        params.statistics,
        type,
        periodicity,
        words
      );
      params.statistics.rows = rows;
      if (type == "percentage" && periodicity == "monthly") {
        params.statistics.rows.forEach((element) => {
          element["chartObject"] = this.helper.returnSparkLineChart(
            element.timeMinusVariantSparkChart
          );
        });
      }
    });
    return params.statistics;
  }

  getIpaStatistics(selectedSeries, params) {
    let ipaArray = this.ipaCalculator.getIpaArray(
      selectedSeries,
      "nominal",
      params.measure,
      params.isAnnual
    );
    // return;
    let rows = [];
    let cols = [{ uid: "date", label: params.dateLabel }];
    ipaArray.map((ipa) => {
      let uid = `col-${ipa.seriesId}`;
      let col = { uid, label: ipa.seriesName };
      cols.push(col);
      ipa.ipaData.map((d) => {
        if (
          moment(d[0]) >= moment(params.extremes.min) &&
          moment(d[0]) <= moment(params.extremes.max)
        ) {
          let date = params.isAnnual
            ? moment(d[0]).format("YYYY")
            : this.helper.getformatDateByPeriodicity("monthly", d[0]);
          if (!rows.length) {
            let row = { date: date };
            row[uid] = d[1];
            row["color"] = this.ipaCalculator.getThresholdColorFromZones(
              d[1],
              params.ignoreNegative
            );
            rows.push(row);
          } else {
            let existingRow = rows.find((r) => r.date == date);
            if (existingRow) {
              existingRow[uid] = d[1];
            } else {
              let row = { date: date };
              row[uid] = d[1];
              row["color"] = this.ipaCalculator.getThresholdColorFromZones(
                d[1],
                params.ignoreNegative
              );
              rows.push(row);
            }
          }
        }
      });
    });
    cols = cols
      .map((a: any) => {
        if (a.uid == "date") return a;
        a.n = a.uid.split("-").splice(2, 1).join();
        return a;
      })
      .sort((a, b) => b.n - a.n);
    params.statistics.cols = cols;
    params.statistics.rows = rows;
    return params.statistics;
  }

  getCalculationForPeriodReport(aSeries, periodicity) {
    if (!aSeries.data) aSeries.data = [];
    var countPoints = this.getCountPoints(aSeries);
    var percChanges = this.getPercentageChange(aSeries);
    var avgPrice = this.getAvgPrice(aSeries);
    var stdDev = this.getStdDev(aSeries);
    var priceRange = this.getPriceRange(aSeries, periodicity);
    var min = this.getMinObject(aSeries, periodicity);
    var max = this.getMaxObject(aSeries, periodicity);
    var startDateFormatter = this.helper.getformatDateByPeriodicity(
      periodicity,
      this.firstDateOfSeries(aSeries)
    );
    var endDateFormatter = this.helper.getformatDateByPeriodicity(
      periodicity,
      this.lastDateOfSeries(aSeries)
    );

    var nodata_statisticsObjectPeriod = {
      start_date: "",
      end_date: "",
      count: countPoints,
      percentage_change: "",
      avg_price: "",
      std_dev: "",
      min_date: "",
      max_date: "",
      min_price: "",
      max_price: "",
      min: { date: "", price: "" },
      max: { date: "", price: "" },
      price_range: "",
    };
    var statisticsObjectPeriod = {
      start_date: startDateFormatter,
      end_date: endDateFormatter,
      count: countPoints,
      percentage_change: percChanges.toFixed(2),
      avg_price: avgPrice.toFixed(2),
      std_dev: stdDev,
      min_date:
        min == null
          ? ""
          : this.helper.getformatDateByPeriodicity(periodicity, min.millsecs),
      max_date:
        max == null
          ? ""
          : this.helper.getformatDateByPeriodicity(periodicity, max.millsecs),
      min_price: min == null ? "" : min.price,
      max_price: max == null ? "" : max.price,
      price_range: priceRange,
    };
    if (countPoints == 0) return nodata_statisticsObjectPeriod;
    else return statisticsObjectPeriod;
  }

  getCalculationForLatestReport(
    aSeries,
    periodicity,
    newValueNumSteps,
    numStepsLatestYears
  ) {
    var tablePeriodicitySteps = {
      daily: {
        getStep1: () => {
          return -1;
        },
        getStep2: () => {
          return -7;
        },
        getStep3: (start) => {
          var refDate = moment(start);
          var pastDate = moment(refDate).subtract(1, "month");
          var daysDiff = refDate.diff(pastDate, "days");
          return -daysDiff;
        },
        getStep4: () => {},
      },
      weekly: {
        getStep1: () => {
          return -1;
        },
        getStep2: (start) => {
          var refDate = moment(start);
          var pastDate = moment(refDate).subtract(1, "month");
          var weeksDiff = refDate.diff(pastDate, "weeks");
          return -weeksDiff;
        },
        getStep3: (start) => {
          var refDate = moment(start);
          var pastDate = moment(refDate).subtract(1, "year");
          var weeksDiff = refDate.diff(pastDate, "weeks");
          return -weeksDiff;
        },
        getStep4: () => {},
      },
      monthly: {
        getStep1: () => {
          return -1;
        },
        getStep2: () => {
          return -3;
        },
        getStep3: () => {
          return -12;
        },
        getStep4: () => {},
      },
    };

    var countPoints = this.getCountPoints(aSeries);

    if (countPoints !== 0) {
      var latest = this.getLatestObject(aSeries);

      var backPoints_step1 = tablePeriodicitySteps[periodicity].getStep1(
        latest.date
      );
      var backPoints_step2 = tablePeriodicitySteps[periodicity].getStep2(
        latest.date
      );
      var backPoints_step3 = tablePeriodicitySteps[periodicity].getStep3(
        latest.date
      );

      var timeMinus1 = this.getMinusObject(aSeries, backPoints_step1);
      var timeMinus3 = this.getMinusObject(aSeries, backPoints_step2);
      var timeMinus12 = this.getMinusObject(aSeries, backPoints_step3);
      var timeMinusVariant = this.getMinusObject(aSeries, -newValueNumSteps);

      var latest = this.getLatestObject(aSeries);

      var backPoints_step1 = tablePeriodicitySteps[periodicity].getStep1(
        latest.date
      );
      var backPoints_step2 = tablePeriodicitySteps[periodicity].getStep2(
        latest.date
      );
      var backPoints_step3 = tablePeriodicitySteps[periodicity].getStep3(
        latest.date
      );

      var timeMinus1 = this.getMinusObject(aSeries, backPoints_step1);
      var timeMinus3 = this.getMinusObject(aSeries, backPoints_step2);
      var timeMinus12 = this.getMinusObject(aSeries, backPoints_step3);
      var timeMinusVariant = this.getMinusObject(aSeries, -newValueNumSteps);

      var statisticsObjectLatest = {
        latest_date: this.helper.getformatDateByPeriodicity(
          periodicity,
          latest.date != "" ? latest.date : ""
        ),
        latest_price: latest.price,
        timeMinus1_date: this.helper.getformatDateByPeriodicity(
          periodicity,
          timeMinus1.date != "" ? timeMinus1.date : ""
        ),
        timeMinus1_price: (timeMinus1.price || 0).toFixed(2),
        timeMinus1_percent: timeMinus1.percent,
        timeMinus3_date: this.helper.getformatDateByPeriodicity(
          periodicity,
          timeMinus3.date != "" ? timeMinus3.date : ""
        ),
        timeMinus3_price: timeMinus3.price,
        timeMinus3_percent: timeMinus3.percent,
        timeMinus12_date: this.helper.getformatDateByPeriodicity(
          periodicity,
          timeMinus12.date != "" ? timeMinus12.date : ""
        ),
        timeMinus12_price: String(
          Math.round(Number(timeMinus12.price) * 100) / 100
        ),
        timeMinus12_percent: timeMinus12.percent,
        timeMinusVariant_date: this.helper.getformatDateByPeriodicity(
          periodicity,
          timeMinusVariant.date != "" ? timeMinusVariant.date : ""
        ),
        timeMinusVariant_price: (timeMinusVariant.price || 0).toFixed(2),
        timeMinusVariant_percent: timeMinusVariant.percent,
      };
      if (periodicity === "monthly") {
        var objectPercentChange = this.getArrayLatestObjectsOfDateAndValue(
          aSeries,
          numStepsLatestYears
        );
        var timeMinusVariantLastFivePercent = objectPercentChange.value;
        var timeMinusVariantLastFiveYears = objectPercentChange.date;
        // statisticsObjectLatest['timeMinusVariantLastFivePercent'] = timeMinusVariantLastFivePercent;
        // statisticsObjectLatest['timeMinusVariantLastFiveYears'] = timeMinusVariantLastFiveYears;
        let timeMinusVariantSparkChart = { id: _.uniqueId(), value: [] };
        timeMinusVariantLastFiveYears.map((d, i) => {
          timeMinusVariantSparkChart.value.push([
            d,
            Number(timeMinusVariantLastFivePercent[i]),
          ]);
        });
        statisticsObjectLatest["timeMinusVariantSparkChart"] =
          timeMinusVariantSparkChart;
      }
    } else {
      statisticsObjectLatest = {
        latest_date: "",
        latest_price: "",
        timeMinus1_date: "",
        timeMinus1_price: "",
        timeMinus1_percent: "",
        timeMinus3_date: "",
        timeMinus3_price: "",
        timeMinus3_percent: "",
        timeMinus12_date: "",
        timeMinus12_price: "",
        timeMinus12_percent: "",
        timeMinusVariant_date: "",
        timeMinusVariant_price: "",
        timeMinusVariant_percent: "",
      };
      if (periodicity === "monthly") {
        // statisticsObjectLatest['timeMinusVariantLastFivePercent'] = "";
        // statisticsObjectLatest['timeMinusVariantLastFiveYears'] = "";
        statisticsObjectLatest["timeMinusVariantSparkChart"] = [];
      }
    }
    return statisticsObjectLatest;
  }

  getCountPoints(aSeries) {
    if (!aSeries.data) return 0;
    var count = 0;
    for (var i = 0; i < aSeries.data.length; i++) {
      var valueItem = aSeries.data[i][1];
      if (valueItem != null) {
        count++;
      }
    }
    return count;
  }

  getPercentageChange(aSeries) {
    var lastNonZeroValue = this.getLastNonZeroValue(aSeries);
    var firstNonZeroValue = this.getFirstNonZeroValue(aSeries);
    return ((lastNonZeroValue - firstNonZeroValue) / firstNonZeroValue) * 100;
  }

  getLastNonZeroValue(aSeries) {
    var lastNonZeroValue = 0;
    for (var i = aSeries.data.length - 1; i > 0; i--) {
      var valueItem = aSeries.data[i][1];
      if (valueItem != null && lastNonZeroValue == 0 && valueItem > 0) {
        lastNonZeroValue = valueItem;
        break;
      }
    }
    return lastNonZeroValue;
  }
  getFirstNonZeroValue(aSeries) {
    var firstNonZeroValue = 0;
    for (var i = 0; i < aSeries.data.length; i++) {
      var valueItem = aSeries.data[i][1];
      if (valueItem != null && firstNonZeroValue == 0 && valueItem > 0) {
        firstNonZeroValue = valueItem;
        break;
      }
    }
    return firstNonZeroValue;
  }

  getAvgPrice(aSeries) {
    return this.getTotalValue(aSeries) / this.getCountPoints(aSeries);
  }

  getTotalValue(aSeries) {
    var total = 0;
    for (var i = 0; i < aSeries.data.length; i++) {
      var valueItem = aSeries.data[i][1];
      total = total + valueItem;
    }
    return total;
  }

  getStdDev(aSeries) {
    var runningTotal = 0;
    var avg = this.getAvgPrice(aSeries);
    var count = 0;
    for (var i = 0; i < aSeries.data.length; i++) {
      var valueItem = aSeries.data[i][1];
      if (valueItem == null) continue;
      count++;
      runningTotal += Math.pow(valueItem - avg, 2);
    }
    return (Math.sqrt(runningTotal) / Math.sqrt(count)).toFixed(2);
  }

  getPriceRange(aSeries, periodicity) {
    var minObj = this.getMinObject(aSeries, periodicity);
    var maxObj = this.getMaxObject(aSeries, periodicity);
    if (minObj != null && maxObj != null) {
      return (maxObj.price - minObj.price).toFixed(2);
    }
    return null;
  }

  getMinObject(aSeries, periodicity) {
    var obj = null;
    var minValue = null;
    var minDate = null;
    if (aSeries.data.lenght > 0 && aSeries[0][1] != undefined)
      minValue = aSeries.data[0][1];
    for (var i = 0; i < aSeries.data.length; i++) {
      var valueItem = aSeries.data[i][1];
      if (valueItem != null && (valueItem <= minValue || minValue == null)) {
        minValue = valueItem;
        minDate = aSeries.data[i][0];
      }
    }
    if (minValue == null) return null;
    //			var date=_getMinDate(aSeries);
    //			var price=this.getMinValue(aSeries);
    var dateFormatter = this.helper.getformatDateByPeriodicity(
      periodicity,
      minDate
    );
    obj = {
      date: dateFormatter,
      millsecs: minDate,
      price: minValue.toFixed(2),
    };
    return obj;
  }

  getMaxObject(aSeries, periodicity) {
    var obj = null;
    var maxValue = null;
    var maxDate = null;
    if (aSeries.data.lenght > 0 && aSeries[0][1] != undefined)
      maxValue = aSeries.data[0][1];
    for (var i = 0; i < aSeries.data.length; i++) {
      var valueItem = aSeries.data[i][1];
      if (valueItem != null && (valueItem >= maxValue || maxValue == null)) {
        maxValue = valueItem;
        maxDate = aSeries.data[i][0];
      }
    }
    if (maxValue == null) return null;
    var dateFormatter = this.helper.getformatDateByPeriodicity(
      periodicity,
      maxDate
    );
    obj = {
      date: dateFormatter,
      millsecs: maxDate,
      price: maxValue.toFixed(2),
    };
    return obj;
  }

  lastDateOfSeries(aSeries) {
    if (aSeries.data.length == 0) return null;
    return aSeries.data[aSeries.data.length - 1][0];
  }
  firstDateOfSeries(aSeries) {
    if (aSeries.data.length == 0) return null;
    return aSeries.data[0][0];
  }

  // Statistics for end date

  getLatestObject(aSeries) {
    if (aSeries.data.length !== 0) {
      var date = this.getLatestDate(aSeries);
      var price = this.getLatestValue(aSeries);
      var latest: any =
        price == null
          ? { date: date, price: "" }
          : { date: date, price: price.toFixed(2) };
      return latest;
    } else {
      latest = { price: "", date: "" };
    }
  }

  getLatestValue(aSeries) {
    return aSeries.data[aSeries.data.length - 1][1];
  }
  getLatestDate(aSeries) {
    return aSeries.data[aSeries.data.length - 1][0];
  }

  getMinusObject(aSeries, backPoints) {
    var date = "";
    var value: any = "";
    var percent: any = "";
    var latestObject = aSeries.data[aSeries.data.length - 1];

    var latestDate = latestObject[0];
    var latestValue = latestObject[1];

    if (!this.isNil(latestValue)) {
      if (aSeries.data.length > -1 * backPoints) {
        var forComparisonObject =
          aSeries.data[aSeries.data.length - 1 + backPoints];
        if (!this.isNil(forComparisonObject)) {
          date = forComparisonObject[0];
          value = forComparisonObject[1];
          if (!this.isNil(value)) {
            value = value;
            percent = (latestValue * 100) / value;
            percent = percent - 100;
            percent = percent.toFixed(2);
          }
        }
      } else {
        value = "";
        date = date;
      }
    }
    var minus = {
      date: date,
      price: value,
      percent: percent,
    };
    return minus;
  }

  isNil(obj) {
    if (_.isNil) {
      return _.isNil(obj);
    }
    return _.isNull(obj) || _.isUndefined(obj);
  }

  getArrayLatestObjectsOfDateAndValue(aSeries, numStepsLatestYears) {
    var timeMinusVariablePercent = [];
    var timeMinusVariableDate = [];
    var objectPercentChange;
    var timeMinusVariable = {};
    for (var i = numStepsLatestYears - 1; i > -1; i--) {
      if (i === 0) {
        objectPercentChange = this.getMinusObjectFoLatestYears(
          aSeries,
          -(i + 1)
        );
      } else {
        objectPercentChange = this.getMinusObjectFoLatestYears(
          aSeries,
          -i * 12
        );
      }
      if (!objectPercentChange.percent || !objectPercentChange.date) {
        timeMinusVariablePercent.push("");
        // SELECTED PERIODICITY // aSeries.periodicity -> selectedPeriodicity
        timeMinusVariableDate.push(
          this.getformatDateByPeriodicityWithoutUtc(
            objectPercentChange.date,
            aSeries.periodicity
          ) +
            " / " +
            this.getformatDateByPeriodicityWithoutUtc(
              objectPercentChange.previusdate,
              aSeries.periodicity
            )
        );
      } else {
        timeMinusVariablePercent.push(objectPercentChange.percent);
        timeMinusVariableDate.push(
          this.getformatDateByPeriodicityWithoutUtc(
            objectPercentChange.date,
            aSeries.periodicity
          ) +
            " / " +
            this.getformatDateByPeriodicityWithoutUtc(
              objectPercentChange.previusdate,
              aSeries.periodicity
            )
        );
      }
    }
    return (timeMinusVariable = {
      value: timeMinusVariablePercent,
      date: timeMinusVariableDate,
    });
  }

  getMinusObjectFoLatestYears(aSeries, backPoints) {
    var value,
      percent,
      latestObject,
      forComparisonObject,
      previusdate,
      date = "",
      latestDate,
      latestValue,
      lastData = aSeries.data.length - 1;

    latestObject = this.getLatestObjectYears(aSeries, backPoints);

    latestDate = latestObject[0];
    latestValue = latestObject[1];

    if (!this.isNil(latestValue) || !this.isNil(latestDate)) {
      if (backPoints === -1) {
        forComparisonObject = aSeries.data[lastData + backPoints];
      } else {
        forComparisonObject = aSeries.data[lastData + (backPoints - 1)];
      }
      if (forComparisonObject == undefined) {
        forComparisonObject = latestObject;
        forComparisonObject = moment(forComparisonObject[0]).format(
          "DD MMM YYYY"
        );
        var numMonths = Math.abs(backPoints - 1);
        var dateDiff = moment(forComparisonObject)
          .subtract(moment.duration({ M: numMonths }))
          .format("DD MMM YYYY");
        var dateDiffinNumber = this.convertInNumber(dateDiff);
        forComparisonObject = [];
        forComparisonObject.push(dateDiffinNumber);
        forComparisonObject.push("");
      }
      if (!this.isNil(forComparisonObject)) {
        date = forComparisonObject[0];
        value = forComparisonObject[1];
        if (!this.isNil(value) && value !== "") {
          value = value;
          percent = (latestValue * 100) / value;
          percent = percent - 100;
          percent = percent.toFixed(2);
        } else {
          value = "";
          date = latestDate;
          previusdate = date;
          percent = "";
        }
      }
    }
    var minus = {
      date: latestDate,
      previusdate: date,
      price: value,
      percent: percent,
    };
    return minus;
  }

  getLatestObjectYears(aSeries, backPoints) {
    var lastData = aSeries.data.length - 1;
    var latestArray: any = [];
    var latestDate;
    if (backPoints === -1) {
      latestArray = aSeries.data[lastData];
    } else {
      latestArray = aSeries.data[lastData + backPoints];
    }
    latestArray = latestArray == null ? (latestArray = "") : latestArray;
    if (latestArray == "") {
      latestDate = moment(aSeries.data[lastData][0]).format("DD MMM YYYY");
      var numMonths = Math.abs(backPoints);
      var dateDiff = moment(latestDate)
        .subtract(moment.duration({ M: numMonths }))
        .format("DD MMM YYYY");
      var dateDiffinNumber = this.convertInNumber(dateDiff);
      latestArray = [];
      latestArray.push(dateDiffinNumber);
      latestArray.push("");
    }
    return latestArray;
  }

  getformatDateByPeriodicityWithoutUtc(date, period) {
    var format_date = "DD-MMM-YY";
    if (period == "weekly") format_date = "DD-MMM-YY";
    if (period == "monthly") format_date = "MMM-YY";
    if (period == "daily") format_date = "DD-MMM-YY";
    return moment(date).format(format_date);
  }

  getUTCMillis(date) {
    return moment.utc(date, ["YYYY-MMM-DD", "YYYY-MM-DD"]);
  }

  convertInNumber(dateString) {
    return this.getUTCMillis(dateString).valueOf();
  }
}
