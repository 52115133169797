<div class="grid" style="zoom: 0.75">
  <div class="col-12">
    <p-progressBar
      *ngIf="loading"
      [style]="{ height: '5px' }"
      mode="indeterminate"
    ></p-progressBar>
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4">
      <ng-template pTemplate="left">
        <div class="my-2">
          <button
            pButton
            pRipple
            icon="pi pi-refresh"
            class="p-button-success mr-2"
            (click)="initData()"
            [disabled]="loading"
          ></button>
        </div>
      </ng-template>
    </p-toolbar>
    <div class="card">
      <div class="p-grid">
        <div class="p-col-3" *ngFor="let f of filterSet">
          <h6>{{ f.header }}</h6>
          <p-multiSelect
            [options]="filters[f.field]"
            defaultLabel="Select a filter"
            optionLabel="name"
            selectedItemsLabel="{0} items selected"
            [style]="{ width: '100%' }"
            [(ngModel)]="selectedFilter[f.field]"
            (ngModelChange)="filterTable(dt, selectedFilter[f.field], f.field)"
            [showClear]="true"
          ></p-multiSelect>
        </div>
      </div>
      <br />
      <p-table
        #dt
        [value]="tasks"
        [columns]="cols"
        rowGroupMode="subheader"
        groupRowsBy="representative.name"
        sortField="representative.name"
        sortMode="single"
        responsiveLayout="scroll"
        [scrollable]="true"
        scrollHeight="100%"
        [paginator]="true"
        [rows]="10"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        [rowHover]="true"
        selectionMode="single"
        [(selection)]="selectedTask"
        (onRowSelect)="onRowSelect($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="col.field" *ngFor="let col of cols">
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-task let-rowIndex="rowIndex">
          <tr>
            <td
              [pSelectableRow]="task"
              [style]="{
                'min-width': col.field == 'uuid' ? '200px' : '100px'
              }"
              *ngFor="let col of cols"
            >
              <span
                *ngIf="col.field == 'status'"
                class="pi"
                [ngClass]="{
                  'pi-check-circle': task[col.field] == 'Success',
                  'pi-times-circle': task[col.field] == 'Failed',
                  'pi-clock': task[col.field] == 'In progress'
                }"
                [style]="{
                  color:
                    task[col.field] == 'Success'
                      ? 'green'
                      : task[col.field] == 'Failed'
                      ? 'red'
                      : 'orange'
                }"
              ></span>

              <!-- if col is status, make color red if failed and green if success -->
              <span
                [style]="{
                  color:
                    col.field == 'status' &&
                    (task[col.field] == 'Failed'
                      ? 'red'
                      : col.field == 'status' && task[col.field] == 'Success'
                      ? 'green'
                      : 'orange')
                }"
              >
                <!-- capitalize if col is status -->
                {{ task[col.field] }}
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p-dialog
      header="Task Log Details"
      [(visible)]="displayTask"
      [modal]="true"
      [style]="{ 'max-width': '90%', 'min-width': '50%' }"
      (onHide)="onHide()"
    >
      <p-header>
        <!-- Task Log Details -->
        <div
          class="task-status"
          [ngClass]="{
            success: selectedTask?.status === 'Success',
            fail: selectedTask?.status !== 'Success',
            inprogress: !selectedTask?.status
          }"
        >
          <span class="header-label">{{ selectedTask?.record_type }}</span>
          <p-badge
            [severity]="getBadgeSeverity(selectedTask?.status)"
            [style]="{ marginLeft: '10px' }"
            [value]="selectedTask?.status"
            >پ
          </p-badge>
        </div>
      </p-header>
      <p-tabView>
        <p-tabPanel header="Overview">
          <p-fieldset [toggleable]="true" legend="General Info">
            <p>
              <strong>UUID:</strong>
              {{ selectedTask?.uuid }}
            </p>
            <p>
              <strong>User name:</strong>
              {{ selectedTask?.user_name }}
            </p>
            <p>
              <strong>Record type:</strong>
              {{ selectedTask?.record_type }}
            </p>
          </p-fieldset>
          <br />
          <p-fieldset [toggleable]="true" legend="Timestamps">
            <p>
              <strong>Submitted at:</strong>
              {{ selectedTask?.created_at }}
            </p>
            <p>
              <strong>Finished at:</strong>
              {{ selectedTask?.updated_at }}
            </p>
            <p>
              <strong>Duration:</strong>
              {{ selectedTask?.execution_time }}
            </p>
          </p-fieldset>
          <br />
          <p-fieldset [toggleable]="true" legend="File Info">
            <p>
              <strong>File name:</strong>
              {{ selectedTask?.file_name || "N/A" }}
            </p>
          </p-fieldset>
        </p-tabPanel>

        <p-tabPanel header="Details">
          <p-card
            header="Task Specifics"
            styleClass="ui-card-shadow"
            [style]="{ marginBottom: '20px' }"
          >
            <div
              *ngIf="!selectedTask?.isJson"
              [innerHTML]="selectedTask?.details"
            ></div>
            <div *ngIf="selectedTask?.isJson">
              <p-table
                [value]="selectedTask?.details.errors"
                class="creative-table"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Line</th>
                    <th>Details</th>
                    <th>Error</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="line-cell">
                      {{ rowData.line }}
                    </td>
                    <td>
                      <div class="data-details">
                        <div>
                          <strong>Country:</strong>
                          <span class="data-value">{{
                            rowData.values.iso3_country_code
                          }}</span>
                        </div>
                        <div>
                          <strong>Price Type:</strong>
                          <span class="data-value">{{
                            rowData.values.price_type
                          }}</span>
                        </div>
                        <div>
                          <strong>Periodicity:</strong>
                          <span class="data-value">{{
                            rowData.values.periodicity
                          }}</span>
                        </div>
                        <div>
                          <strong>Market:</strong>
                          <span class="data-value">{{
                            rowData.values.market
                          }}</span>
                        </div>
                        <div>
                          <strong>Commodity:</strong>
                          <span class="data-value">{{
                            rowData.values.commodity
                          }}</span>
                        </div>
                        <div>
                          <strong>Source:</strong>
                          <span class="data-value">{{
                            rowData.values.source
                          }}</span>
                        </div>
                        <div>
                          <strong>Unit:</strong>
                          <span class="data-value">{{
                            rowData.values.measure_unit
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="error-cell">
                      {{ rowData.error }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-card>
        </p-tabPanel>
      </p-tabView>

      <p-footer>
        <button
          pButton
          type="button"
          label="Close"
          (click)="displayTask = false; selectedTask = {}"
        ></button>
      </p-footer>
    </p-dialog>
  </div>
</div>
