import { HelperService } from "src/app/services/helper.service";
import { MessageService } from "primeng/api";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(
    private messageService: MessageService,
    private helper: HelperService
  ) {}

  public dynamicMessage(status, message) {
    this.messageService.add({
      severity: status,
      summary: this.helper.capitalizeFirstLetter(status),
      detail: message,
    });
  }

  public cannotCompare(words) {
    this.messageService.add({
      severity: "warn",
      summary: words["key-alert-wrong_measure-title"],
      detail: words["key-alert-wrong_measure-text"],
    });
  }

  public differentOrigin(url, words) {
    this.messageService.add({
      severity: "error",
      summary: words["key-alert-wrong_origin-title"],
      sticky: true,
      detail: (words["key-alert-wrong_origin-title"] || "").replace(
        "{url}",
        url
      ),
    });
  }

  public invalidDataset(words) {
    this.messageService.add({
      severity: "error",
      summary: words["key-alert-invalid_dataset-title"],
      detail: words["key-alert-invalid_dataset-text"],
    });
  }

  public exporterNoOptionsSelected(words) {
    this.messageService.add({
      severity: "warn",
      summary: words["key-alert-exporter_no_options-title"],
      detail: words["key-alert-exporter_no_options-text"],
    });
  }

  public copiedToClipboard(words) {
    this.messageService.add({
      severity: "success",
      summary: words["key-alert-copied_to_clipboard-title"],
      detail: words["key-alert-copied_to_clipboard-text"],
    });
  }

  public currencySelectionError(words) {
    this.messageService.add({
      severity: "warn",
      summary: words["key-alert-currency_selection-title"],
      detail: words["key-alert-currency_selection-text"],
    });
  }

  public saveConfigurationsEmptySelection(words) {
    this.messageService.add({
      severity: "warn",
      summary: words["key-alert-save_configs_no_selection-title"],
      detail: words["key-alert-save_configs_no_selection-text"],
    });
  }

  public deleteDatapoint(n, words) {
    this.messageService.add({
      severity: "success",
      summary: words["key-alert-deleted_datapoints-title"],
      detail: (words["key-alert-deleted_datapoints-text"] || "").replace(
        "{n}",
        n
      ),
    });
  }

  public updateDatapoint(words) {
    this.messageService.add({
      severity: "success",
      summary: words["key-alert-updated_datapoints-title"],
      detail: words["key-alert-updated_datapoints-text"],
    });
  }

  public addDatapoint(n, words) {
    this.messageService.add({
      severity: "success",
      summary: words["key-alert-added_datapoints-title"],
      detail: (words["key-alert-added_datapoints-text"] || "").replace(
        "{n}",
        n
      ),
    });
  }
}
