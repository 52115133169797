<p-table
  [exportFilename]="exportFilename"
  #statisticsDataTable
  [style]="{
    height: '100%',
    paddingBottom: '100px',
    width:
      statistics.currentOption == 'ipa'
        ? statistics.cols.length * 100 + 'px'
        : '100%'
  }"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [columns]="statistics.cols"
  [value]="statistics.rows"
  responsiveLayout="scroll"
  class="p-table"
  styleClass="p-datatable-sm p-datatable-striped table-highlight sticky-header"
>
  <ng-template pTemplate="header" let-columns>
    <tr *ngIf="statistics.currentOption == 'percentage'">
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px">
        <p-inputNumber
          [(ngModel)]="statistics.timeMinusVariantSparkChart"
          (ngModelChange)="onCalculationRequestTrigger()"
          [showButtons]="true"
          buttonLayout="horizontal"
          inputId="horizontal"
          spinnerMode="horizontal"
          [style]="{ height: '20px' }"
          size="2"
          decrementButtonClass="p-button-secondary"
          incrementButtonClass="p-button-secondary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          mode="decimal"
          [min]="1"
          [max]="10"
        >
        </p-inputNumber>
      </th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px"></th>
      <th style="width: 100px">
        <p-inputNumber
          [(ngModel)]="statistics.timeMinusVariant"
          (ngModelChange)="onCalculationRequestTrigger()"
          [showButtons]="true"
          buttonLayout="horizontal"
          inputId="horizontal"
          spinnerMode="horizontal"
          [style]="{ height: '20px' }"
          size="4"
          decrementButtonClass="p-button-secondary"
          incrementButtonClass="p-button-secondary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          mode="decimal"
          [min]="1"
          [max]="100"
        >
        </p-inputNumber>
      </th>
      <th style="width: 100px"></th>
    </tr>
    <tr>
      <th
        *ngFor="let col of columns"
        [style.borderWidth]="
          statistics.currentOption == 'percentage' ? '3px 0 0 0' : '0 0 0 0'
        "
        [style.borderColor]="col.group"
        class="column roboto"
        style="width: 100px"
        [pSortableColumn]="col.uid"
        [ngStyle]="
          statistics.currentOption == 'ipa'
            ? {
                width: '100px',
                'text-align': col.uid == 'date' ? 'center' : 'right'
              }
            : null
        "
      >
        {{ col.label }}
        <p-sortIcon [field]="col.uid"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td
        *ngFor="let col of columns"
        style="width: 100px"
        [style.textAlign]="col.uid == 'series' ? 'left' : 'center'"
        [style.borderWidth]="
          statistics.currentOption == 'percentage' ? '1px 0 1px 0' : '0 0 0 0'
        "
        [style.borderColor]="col.background ? col.group : ''"
        [style.backgroundColor]="col.background"
        class="row"
        [style.padding]="
          col.uid == 'timeMinusVariantYears' ? 0 : '0.3rem 0.5rem'
        "
        [ngStyle]="
          statistics.currentOption == 'ipa'
            ? {
                width: '100px',
                'text-align': col.uid == 'date' ? 'center' : 'right'
              }
            : null
        "
      >
        <span
          *ngIf="col.uid == 'series'"
          class="legend-color"
          [style.backgroundColor]="rowData.legendColor"
        ></span>
        <span
          [style.color]="
            col.colorable ? (rowData[col.uid] <= 0 ? '#007D3A' : '#650F19') : ''
          "
        >
          {{ rowData[col.uid] }}
          <span
            *ngIf="
              statistics.currentOption == 'ipa' &&
              col.uid != 'date' &&
              rowData[col.uid]
            "
            class="ipa-threshold"
            [style.backgroundColor]="rowData.color"
          ></span>
        </span>
        <div *ngIf="col.uid == 'timeMinusVariantYears'">
          <div
            [chart]="rowData.chartObject"
            class="sparkline"
            [id]="'Sparkline-' + rowData.timeMinusVariantSparkChart.id"
            (click)="onShowChartPopupRequestTrigger(rowData)"
          ></div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
