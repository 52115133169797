import { forkJoin } from "rxjs";
import { HelperService } from "src/app/services/helper.service";
import { AlertService } from "src/app/services/alert.service";
import { ApisService } from "src/app/services/apis.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Input } from "@angular/core";
import { Component, OnInit, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-price-upload-modal",
  templateUrl: "./price-upload-modal.component.html",
  styleUrls: ["./price-upload-modal.component.scss"],
})
export class PriceUploadModalComponent implements OnInit {
  @Input("params") params;
  @Input("token") token;
  form: UntypedFormGroup = new UntypedFormGroup({});
  file: any;
  table: any = { rows: [], cols: [] };
  warnings: any[] = [];
  loading = false;
  dataValidation: any = {
    Commodity: { key: "commodity_name", index: 0, values: [] },
    Market: { key: "market_name", index: 0, values: [] },
    Source: { key: "source_name", index: 0, values: [] },
    PriceType: { key: "price_type", index: 0, values: [] },
    MeasureUnit: { key: "mu_lbl", index: 0, values: [] },
  };
  periodicities = ["MONTHLY", "WEEKLY", "DAILY"];
  defaultParams = [
    "ID",
    "ISO3-country-code",
    "Price-Type",
    "Periodicity",
    "Market",
    "Commodity",
    "Date",
    "Price-Value",
    "Currency",
    "Measure-Unit",
    "Source",
  ];
  sizeLimitation: number = 1; // In MB

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apis: ApisService,
    private alertService: AlertService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.initValidation();
    this.form = this.formBuilder.group({
      "csv-file": [""],
    });
    this.warnings = [];
    this.table = { rows: [], cols: [] };
  }

  initValidation() {
    let requests = [];
    let validationKeys = Object.keys(this.dataValidation);
    this.loading = true;
    validationKeys.map((o) => requests.push(this.apis.apiV1(o)));
    forkJoin(requests).subscribe((res: any) => {
      this.loading = false;
      let counter = 0;
      for (let k in this.dataValidation) {
        this.dataValidation[k].values = res[counter].results.map(
          (r) => r[this.dataValidation[k].key]
        );
        counter++;
      }
      this.dataValidation["Periodicity"] = {
        key: "periodicity",
        index: 0,
        values: this.periodicities,
      };
    });
  }

  triggerInput() {
    document.getElementById("upload-prices-csv").click();
  }

  uploadCSVInputChanged(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > this.sizeLimitation) {
        this.alertService.dynamicMessage(
          "error",
          `File size exceeds ${this.sizeLimitation} Mb`
        );
        return;
      }
      this.file = {
        name: file.name,
        type: file.type,
        size: this.helper.bytesToSize(file.size),
        lastModified: new Date(file.lastModified).toUTCString(),
      };
      this.form.get("csv-file").setValue(file);
      this.convertFile(this.form.get("csv-file").value);
      (<any>document.getElementById("upload-prices-csv")).value = null;
    }
  }

  uploadCSV() {
    this.loading = true;
    let file = this.form.get("csv-file").value;
    const formData = new FormData();
    formData.append("files", file);
    formData.append("description", "");
    this.apis
      .adminAPIs(this.token)
      .uploadCSVFile(formData)
      .subscribe((res: any) => {
        this.alertService.dynamicMessage(res.status, res.message);
        this.loading = false;
        this.params.showUploadDialog = false;
      });
  }

  processCsv(content) {
    this.warnings = [];
    content = this.helper.replaceAll(content, "\r", "").split("\n");
    let header = content[0].split(",");
    this.validateHeader(header);
    content = content.slice(1, content.length).map((c, i) => {
      c = c.split('","').map((sc) => this.helper.replaceAll(sc, '"', ""));
      let [hasWarning, keys] = this.validateData(c, i, header);
      if (c.length != header.length) {
        hasWarning = true;
        this.warnings.push({
          text: `Row number ${i + 1} has wrong parameters.`,
        });
      }

      let obj = {};
      c.map((cc, idx) => {
        obj[header[idx]] = cc;
        obj["INDEX"] = i + 1;
        obj["hasWarning"] = hasWarning;
        obj["warnings"] = {};
        if ((<any>keys).length > 0) {
          (<any>keys).map((k) => {
            obj["warnings"][k] = true;
          });
        }
      });
      return obj;
    });
    header = header.map((h) => {
      return {
        field: h,
      };
    });

    header.unshift({ field: "INDEX" });
    this.table.rows = content;
    this.table.cols = header;
  }

  validateHeader(header) {
    this.defaultParams.map((p) => {
      let colIdx = header.findIndex(
        (h) =>
          this.helper.formatCSVColumnValue(h) ==
          this.helper.formatCSVColumnValue(p)
      );
      let keys = Object.keys(this.dataValidation);
      if (colIdx > -1) {
        if (
          keys
            .map((k) => this.helper.formatCSVColumnValue(k))
            .includes(this.helper.formatCSVColumnValue(p))
        ) {
          let key = keys.find(
            (k) =>
              this.helper.formatCSVColumnValue(k) ==
              this.helper.formatCSVColumnValue(p)
          );
          this.dataValidation[key].index = colIdx;
        }
      } else this.warnings.push({ text: `Header column '${p}' is missing.` });
    });
  }

  validateData(data, idx, header) {
    let hasWarning = false;
    let keys = [];
    for (let k in this.dataValidation) {
      if (
        !this.dataValidation[k].values.includes(
          data[this.dataValidation[k].index]
        )
      ) {
        let key = header.find(
          (h) =>
            this.helper.formatCSVColumnValue(h) ==
            this.helper.formatCSVColumnValue(k)
        );
        if (key) keys.push(key);
        hasWarning = true;
        this.warnings.push({
          text: `Row number ${idx + 1} has wrong ${this.dataValidation[k].key}`,
        });
      }
    }
    return [hasWarning, keys];
  }

  convertFile(file: any) {
    this.readFileContent(file)
      .then((content) => {
        this.processCsv(content);
      })
      .catch((error) => {});
  }

  readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }
}
