import { ApisService } from "./../services/apis.service";
import { BehaviorSubject } from "rxjs";
import { SettingsService } from "./../services/settings.service";
import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { UserService } from "../services/user.service";
import { fadeIn } from "../misc/animations";
import { constants } from "src/environments/constants";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: [fadeIn],
})
export class DashboardComponent implements OnInit {
  displaySidebar: boolean = true;
  displaySidebarMini: boolean = false;
  displaySettings: boolean = false;
  menuItems: MenuItem[];
  settingsItems: MenuItem[];
  words: any = {};
  sidebar: any = {};
  datasets: any[] = [];
  languages: any[] = [];
  configs: any = [];
  header: any = {};
  loading: boolean = true;
  user: any = { username: "", password: "" };
  showTaskLog: any;
  sidebarLinks: any = {};

  constructor(
    private settingsService: SettingsService,
    private userService: UserService,
    private apis: ApisService
  ) {}

  ngOnInit() {
    this.getSidebarData();
    this.initUser();
    this.setSidebarLinks();
  }

  setSidebarLinks() {
    let adminTool =
      `${window.location.origin}${window.location.pathname}`.replace(
        "fpmat4",
        "v4"
      );
    let versions = `${adminTool}core/versions/`;

    this.sidebarLinks = {
      versions: versions,
      adminTool: adminTool,
    };
  }

  initUser() {
    this.userService.loggedUser.subscribe((res: any) => {
      this.user = res;
      this.user.permissions = ["CollectionAdmin", "Superuser", "Enumerator"];
      if (Object.keys(this.sidebar).length > 0) this.initSidebar(this.sidebar);
    });
  }

  getSidebarData() {
    this.loading = true;
    this.settingsService.getSettings().subscribe((configs) => {
      if (!configs) return;
      this.configs = configs;
      let sidebar = configs.sidebar;
      this.sidebar = sidebar;
      this.initCustomizations(configs.toolCustomization);
      this.initLang(configs.languages);
      this.initLogos();
      this.loading = false;
    });
  }

  initCustomizations(data) {
    this.header = data;
  }

  initLogos() {
    if (!this.header) return;
    if (!this.header.headerLogos) return;
    this.settingsService.getCurrentLanguage().subscribe((lang) => {
      this.header.headerLogos = this.header.headerLogos.map((logo) => {
        let [url, extension] = logo.url.split(/\.(?=[^\.]+$)/);
        if (url.includes(logo.language_postfix || "__"))
          url = url.split(logo.language_postfix || "__")[0];
        if (logo.multilingual && JSON.parse(logo.multilingual.toLowerCase())) {
          let postfix = logo.language_postfix ? logo.language_postfix : "__";
          url += `${postfix}${lang}`;
        }
        url += `.${extension}`;
        logo.url = url;
        if (logo.external) logo.imgPath = this.apis.getURLFromSidebarItem(logo);
        return logo;
      });
    });
  }

  initLang(languages) {
    this.languages = languages;
    let lang = languages.find((lang) => lang.default);
    this.setLanguage(lang);
  }

  changeLanguage(lang) {
    this.languages.map((l) => (l.default = false));
    lang.default = true;
    this.setLanguage(lang);
  }

  setLanguage(lang) {
    let words = this.configs.languagesTexts[`language-${lang.key}`];
    this.settingsService.updateWords(words, lang.key);
    this.words = words;
    this.initSidebar(this.sidebar);
    this.setLicenseUrl(lang.key);
  }

  initSidebar(data) {
    this.menuItems = [];
    data.map((i) => {
      if (i.hidden && !this.user.isLogged) return;
      let item: MenuItem = this.getItemTemplate(i);
      if (i.items) {
        item.items = [];
        i.items.map((si) => {
          let subItem = this.getItemTemplate(si, true);
          item.items.push(subItem);
        });
      }
      this.menuItems.push(item);
    });
  }

  setLicenseUrl(langKey) {
    if (this.header.showLicense) {
      this.header.licenseUrl = `https://www.fao.org/contact-us/terms/db-terms-of-use/${langKey}`;
    }
  }

  getItemTemplate(i, subitem?) {
    let item: MenuItem = {
      expanded: true,
      label: this.words[i.labelkey],
      icon: "fa " + (i.icon || (subitem ? "fa-database" : "fa-table")),
    };
    if (i.id == "home") {
      item.routerLink = ["/dashboard/home"];
      item.routerLinkActiveOptions = { exact: true };
    }
    if (i.url) {
      item.url = i.url;
      item.target = i.target;
    }
    if (i.internalpage || i.type == "internalpage") {
      item.routerLink = ["/dashboard/browser/" + i.id];
      item.routerLinkActiveOptions = { exact: true };
    }
    if (subitem) {
      if (i.id) {
        item.routerLink = ["/dashboard/tool/" + i.id];
        item.routerLinkActiveOptions = { exact: true };
      }
      if (i.internalpage || i.type == "internalpage") {
        item.routerLink = ["/dashboard/browser/" + i.id];
        item.routerLinkActiveOptions = { exact: true };
      }
    }
    return item;
  }

  toggleSidebar() {
    if (this.displaySidebar) {
      this.displaySidebarMini = true;
      this.displaySidebar = false;
      return;
    }
    this.displaySidebar = true;
    this.displaySidebarMini = false;
  }

  login() {
    if (!this.user.username || !this.user.password) return;
    this.loading = true;
    this.userService.login(this.user).subscribe(
      (res: any) => {
        this.userService.saveUser(this.user, res);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  logout() {
    this.userService.logout();
  }
}
