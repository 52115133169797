<p-dialog
  [appendTo]="'body'"
  [(visible)]="generator.showDialog"
  header="Widgets Generator"
  [baseZIndex]="2500"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [style]="{ width: '90%' }"
>
  <p-steps
    [style]="{ 'margin-top': '10px' }"
    [model]="steps"
    [(activeIndex)]="activeIndex"
  ></p-steps>
  <div
    class="available-widgets"
    *ngIf="activeTab == 'Choose Widgets' && !loading"
  >
    <h3>Choose Widgets</h3>
    <div
      class="widget-thumb"
      [class.selected]="widget.selected"
      (click)="widget.selected = !widget.selected"
      *ngFor="let widget of availableWidgets"
    >
      <p>{{ widget.name }}</p>
      <img [src]="widget.icon" alt="" />
      <fa-icon
        icon="check-circle"
        class="checkmark"
        *ngIf="widget.selected"
      ></fa-icon>
    </div>
  </div>
  <div style="height: 300px" *ngIf="loading">
    <app-loader [loading]="loading"></app-loader>
  </div>
  <div *ngIf="activeTab == 'Chart Widget' && !loading" class="widget-settings">
    <app-widget-chart-modal
      #chartWidget
      [widgets]="widgets.chart"
      [chartRef]="chartRef"
      [words]="words"
      [selectedSeries]="selectedSeries"
      [periodicity]="periodicity"
      [datasetName]="datasetName"
    ></app-widget-chart-modal>
  </div>
  <div
    *ngIf="activeTab == 'Statistics Widget' && !loading"
    class="widget-settings"
  >
    <app-widget-statistics-modal
      #statisticsWidget
      [widgets]="widgets.statistics"
      [chartRef]="chartRef"
      [words]="words"
      [selectedSeries]="selectedSeries"
      [periodicity]="periodicity"
      [datasetName]="datasetName"
      [measure]="measure"
      [extremes]="extremes"
    ></app-widget-statistics-modal>
  </div>
  <div *ngIf="activeTab == 'Finalize'">
    <h5>Options</h5>
    <p-selectButton
      [options]="stateOptions"
      [(ngModel)]="value1"
      optionLabel="label"
      optionValue="value"
    ></p-selectButton>

    <div class="p-grid">
      <div class="p-col-6">
        <h5>Widget Chart</h5>
        <p>Align</p>
        <p-selectButton [options]="justifyOptions" [(ngModel)]="align">
          <ng-template let-item>
            <i [class]="item.icon"></i>
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-col-6">
        <h5>Widget Statistics</h5>
        <p>Align</p>
        <p-selectButton [options]="justifyOptions" [(ngModel)]="align2">
          <ng-template let-item>
            <i [class]="item.icon"></i>
          </ng-template>
        </p-selectButton>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="footer">
      <p-button
        [label]="'Back'"
        styleClass="p-button-success"
        [style]="{ width: '100px' }"
        (click)="back()"
        [disabled]="activeIndex == 0"
      ></p-button>
      <p-button
        [label]="'Next'"
        styleClass="p-button-success"
        [style]="{ width: '100px' }"
        (click)="next()"
        [disabled]="activeIndex > steps.length - 2"
        *ngIf="activeIndex < steps.length - 1"
      ></p-button>
      <p-button
        [label]="'Finalize'"
        styleClass="p-button-success"
        [style]="{ width: '100px' }"
        (click)="next()"
        *ngIf="activeIndex == steps.length - 1"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
