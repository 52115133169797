import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "columnsPicker",
})
export class ColumnsPickerPipe implements PipeTransform {
  transform(cols: any[]): any[] {
    if (cols) {
      return cols.filter((col) => col.show);
    }
  }
}
