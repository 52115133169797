import { HelperService } from "src/app/services/helper.service";
import { CacheService } from "./cache.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constants } from "src/environments/constants";

@Injectable()
export class ApisService {
  private prefix: string;
  // private prefix: string;
  private baseOrigin: string;
  private origin: string;
  private postfix: string;
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    private helper: HelperService
  ) {
    this.fetchConstants();
    this.updateBaseUrl();
  }

  fetchConstants() {
    this.prefix = constants.prefix;
    this.prefix = constants.prefix;
    this.baseOrigin = constants.baseOrigin;
    this.origin = constants.origin;
    this.postfix = constants.postfix;
    this.baseUrl = constants.baseUrl;
  }

  updateBaseUrl(origin?) {
    if (origin && origin.length > 0) {
      if (this.helper.isValidHttpAPI(origin)) {
        if (origin[origin.length - 1] != "/") origin = origin + "/";
        this.setBaseUrl(origin);
      } else {
        this.origin = origin == "review" ? "" : origin + "/";
        this.setBaseUrl(this.prefix + this.origin + this.postfix);
      }
    } else {
      this.origin = this.baseOrigin;
      this.setBaseUrl(this.prefix + this.origin + this.postfix);
    }
  }

  private setBaseUrl(url) {
    this.baseUrl = url;
  }

  getAllSettings() {
    let request = this.http.get(
      this.baseUrl.split("v4")[0] +
        "v4/" +
        this.baseOrigin +
        "core/api/v1/Global/"
    );
    return this.cacheService.loadFromDelayedObservable(
      this.baseUrl + "fpma-settings",
      request
    );
  }

  getUserGroups(token) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `${
        this.baseUrl.split("v4")[0] + "v4/" + this.baseOrigin
      }core/api/v1/Group/`,
      { headers }
    );
  }

  apiV1(base) {
    return this.http.get(`${this.baseUrl}${base}/`);
  }

  public getGrid(url) {
    let request = this.http.get(this.baseUrl + url);
    return request;
  }

  public getGridSerie(ids) {
    return this.http.get(`${this.baseUrl}FpmaSerie/?uuid__in=${ids}`);
  }

  public getPrices(series, periodicity) {
    return this.http.get(
      `${this.baseUrl}FpmaSeriePrice/${series.id}?periodicity=${periodicity}`
    );
  }

  public getMultiplePrices(ids, periodicity?) {
    let req = `${this.baseUrl}FpmaSeriePrice/?uuid__in=${ids.join(",")}`;
    if (periodicity) req += `&periodicity=${periodicity}`;
    return this.http.get(req);
  }

  public getURLFromSidebarItem(item) {
    return item.external && JSON.parse(item.external.toLowerCase())
      ? item.url
      : this.getHTMLStaticPath() + "media/frontend/" + item.url;
  }

  public getHTMLStaticPath() {
    return this.prefix + this.baseOrigin;
  }

  public getMarkets() {
    return this.http.get(this.baseUrl + "Market");
  }

  public getBaseOrigin() {
    return this.baseOrigin;
  }

  public getAPIPrefix() {
    return this.prefix;
  }

  public userAPIs() {
    return {
      login: (user) => {
        return this.http.post(
          this.prefix + this.baseOrigin + "api/token/",
          user
        );
      },
      refresh: (refresh) => {
        return this.http.post(
          this.prefix + this.baseOrigin + "api/token/refresh/",
          { refresh }
        );
      },
      verify: (token) => {
        return this.http.post(
          this.prefix + this.baseOrigin + "api/token/verify/",
          { token }
        );
      },
    };
  }

  public adminAPIs(token) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    return {
      deleteDatapoint: (id) => {
        return this.http.delete(`${this.baseUrl}Price/${id}/`, { headers });
      },
      updateDatapoint: (id, data) => {
        return this.http.patch(`${this.baseUrl}Price/${id}/`, data, {
          headers,
        });
      },
      addDatapoint: (id, data) => {
        return this.http.put(`${this.baseUrl}FpmaSerie/${id}/`, data, {
          headers,
        });
      },
      uploadCSVFile: (formData) => {
        return this.http.post(
          `${this.prefix}${this.baseOrigin}price_module/api/v1/PriceUpload/`,
          formData,
          {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
            }),
          }
        );
      },
    };
  }

  getTaskLog(token) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .get(`${this.baseUrl}admin/TaskLog/`, { headers })
      .toPromise();
  }

  getTaskLogDetails(uuid, token) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .get<any>(`${this.baseUrl}admin/TaskLog/${uuid}/get_by_uuid/`)
      .toPromise();
  }
}
