<app-progress-bar [loading]="loading"></app-progress-bar>
<div class="container">
  <p-toast position="bottom-right" [baseZIndex]="11003">
    <ng-template let-message pTemplate="message">
      <div class="p-toast-message-text">
        <div class="p-toast-summary">{{ message.summary }}</div>
        <div class="p-toast-detail" innerHtml="{{ message.detail }}"></div>
      </div>
    </ng-template>
  </p-toast>
  <p-dialog
    [header]="
      't-1 %Δ in the latest ' + statistics.timeMinusVariantSparkChart + ' years'
    "
    [(visible)]="statistics.showModal"
    [draggable]="true"
    [resizable]="true"
  >
    <p>{{ statistics.chartTitle }}</p>
    <div
      [chart]="statistics.chartObject"
      id="column-chart"
      class="sparkline"
    ></div>
  </p-dialog>
  <p-dialog
    [appendTo]="'body'"
    [header]="words['key-download-modal-options']"
    [(visible)]="exporter.showDialog"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    [dismissableMask]="true"
  >
    <div>
      <h5 style="margin: 0">{{ words["key-download-modal-file_name"] }}</h5>
      <input
        type="text"
        class="p-mt-2"
        pInputText
        style="height: 40px; width: 100%"
        [(ngModel)]="exporter.filename"
        placeholder="File name"
      />
    </div>
    <div class="p-mt-3">
      <h5 style="margin: 0" class="p-mb-2">
        {{ words["key-download-modal-extension"] }}
      </h5>
      <p-selectButton
        [options]="exporter.options"
        [(ngModel)]="exporter.selected"
        multiple="multiple"
        optionLabel="name"
        optionValue="value"
      >
      </p-selectButton>
    </div>
    <div
      class="p-field-checkbox p-mt-3 p-mb-0"
      *ngIf="exporter.enableSelection"
    >
      <p-checkbox
        [(ngModel)]="exporter.selectionOnly"
        binary="true"
        inputId="binary"
      ></p-checkbox>
      <label for="binary">{{
        words["key-download-modal-selection_only"]
      }}</label>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        label="Save"
        styleClass="p-button-secondary"
        (click)="exportAllOptions()"
      ></p-button>
    </ng-template>
  </p-dialog>
  <p-dialog
    [appendTo]="'body'"
    header="Permalink"
    [(visible)]="permalink.showDialog"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    [dismissableMask]="true"
  >
    <div class="p-inputgroup">
      <input
        type="text"
        pInputText
        [value]="permalink.url"
        style="width: 500px; height: 40px"
      />
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-external-link"
        (click)="openPermalink(permalink.url)"
        [style]="{ 'font-size': '10px' }"
      ></button>
    </div>
  </p-dialog>
  <app-widgets-generator
    *ngIf="widgetsGenerator.showDialog"
    [generator]="widgetsGenerator"
    [widgets]="widgets"
    [chartRef]="chart"
    [words]="words"
    [measure]="measures.selected"
    [extremes]="extremes"
    [selectedSeries]="selectedSeries"
    [periodicity]="periodicities.selected"
    [datasetName]="datasetId"
  ></app-widgets-generator>
  <app-price-upload-modal
    [token]="user.token"
    [params]="admin"
    *ngIf="admin.showUploadDialog"
  ></app-price-upload-modal>
  <app-crud-modal [words]="words" [admin]="admin" *ngIf="admin.showDialog">
  </app-crud-modal>
  <div
    class="container__table b-r-24"
    [class.tableFullscreen]="fullscreen.table"
  >
    <p-toolbar class="toolbar" styleClass="toolbar-nopadding toolbar-b-r-24">
      <div class="p-toolbar-group-left">
        <p class="title">
          {{ words[titleKey] }}
          <fa-icon class="arrow" icon="chevron-right"></fa-icon>
          <app-toggler
            *ngIf="rows.length"
            name="table"
            (onSelection)="toggleOptions($event)"
          ></app-toggler>
        </p>
      </div>
      <input
        type="file"
        hidden
        id="load-configuration"
        (change)="onImport($event)"
      />
      <div class="p-toolbar-group-right">
        <fa-icon
          class="controls"
          icon="file-csv"
          (click)="admin.showUploadDialog = true"
          [pTooltip]="'Upload CSV Prices'"
          tooltipPosition="left"
          *ngIf="user.isLogged && !remoteDataset"
        >
        </fa-icon>
        <fa-icon
          class="controls"
          icon="upload"
          (click)="loadConfiguration()"
          [pTooltip]="words['key-datagrid-load']"
          tooltipPosition="left"
        >
        </fa-icon>
        <fa-icon
          class="controls"
          icon="save"
          (click)="saveConfiguration()"
          [pTooltip]="words['key-datagrid-save']"
          tooltipPosition="left"
        >
        </fa-icon>
        <fa-icon
          class="controls"
          icon="download"
          (click)="downloadGrid()"
          [pTooltip]="words['key-datagrid-download']"
          tooltipPosition="left"
        >
        </fa-icon>
        <fa-icon
          (click)="toggleFullscreen('table')"
          class="controls"
          icon="expand-alt"
          [pTooltip]="words['key-datagrid-fullscreen']"
          tooltipPosition="left"
          tooltipPosition="bottom"
        ></fa-icon>
      </div>
    </p-toolbar>
    <div [style.height]="'320px'" *ngIf="!rows.length"></div>
    <p-table
      [exportFilename]="exporter.filename"
      [class.top-panel-hidden]="
        togglers['table']?.active != 'Grid View' || !rows.length
      "
      [columns]="cols"
      [value]="rows"
      [paginator]="true"
      [showCurrentPageReport]="true"
      (onFilter)="onFiltering($event)"
      currentPageReportTemplate="{{ words['key-datagrid-pagination'] }}"
      [(first)]="paginator.first"
      [rows]="fullscreen.table ? 50 : 8"
      selectionMode="multiple"
      [(selection)]="selectedSeries"
      dataKey="id"
      (onRowSelect)="selectedSeriesEvent($event)"
      (onRowUnselect)="unSelectedSeriesEvent($event)"
      styleClass="p-datatable-sm p-datatable-striped table-highlight"
      #dt
      class="p-table datagrid"
      (sortFunction)="customSort($event)"
      [customSort]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 30px">
          <th style="width: 2em">
            <fa-icon
              class="pointer"
              icon="times-circle"
              (click)="clearFilters()"
              [pTooltip]="words['key-datagrid-reset_filter']"
              tooltipPosition="left"
              *ngIf="(appliedFilters | json) != '{}'"
            >
            </fa-icon>
          </th>
          <th *ngFor="let col of columns | columnsPicker">
            <p-multiSelect
              [options]="filters[col.field]"
              resetFilterOnHide="true"
              [(ngModel)]="appliedFilters[col.field]"
              [defaultLabel]="words['key-datagrid-filter_label']"
              optionLabel="name"
              display="chip"
              (ngModelChange)="applyFilter(dt, col.field)"
              (onChange)="checkHidingDropdown($event)"
              *ngIf="filters && col.filterable"
              [appendTo]="fullscreen.table ? '' : 'body'"
            >
            </p-multiSelect>
          </th>
        </tr>
        <tr>
          <th style="width: 2em">
            <fa-icon
              class="pointer"
              icon="plus-circle"
              (click)="seriesGroupSelection()"
              [pTooltip]="words['key-datagrid-select_all']"
              tooltipPosition="left"
              *ngIf="(appliedFilters | json) != '{}'"
            >
            </fa-icon>
          </th>
          <th
            *ngFor="let col of columns | columnsPicker"
            @fadeIn
            class="column roboto"
            [pSortableColumn]="col.field"
          >
            <span>{{ words[col.labelkey] }} </span>
            <fa-icon
              class="controls"
              icon="info-circle"
              *ngIf="col.calculated"
              [pTooltip]="'Periodicities in blue are calculated'"
              [escape]="false"
              tooltipPosition="bottom"
            ></fa-icon>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <!-- class="p-highlight"  -->
        <tr
          class="rowData"
          [ngClass]="{
            'p-highlight': rowData.selected
          }"
        >
          <td class="datagrid-checkbox">
            <p-tableCheckbox
              [value]="rowData"
              #datagridCheckbox
            ></p-tableCheckbox>
          </td>
          <td
            *ngFor="let col of columns | columnsPicker"
            [pSelectableRow]="rowData"
            @fadeIn
            class="row grid-text"
            [title]="
              col.field == 'commodity_name' && datasetOptions.showCommodityInfo
                ? ''
                : rowData[col.field]
            "
            [style]="{
              color: rowData[col.field + 'Calculated'] ? '#0b6cac' : 'black'
            }"
          >
            {{ rowData[col.field] }}
            <span
              *ngIf="
                col.field == 'commodity_name' &&
                datasetOptions.showCommodityInfo
              "
              class="commodity_info"
            >
              <fa-icon
                class="controls"
                icon="info-circle"
                [pTooltip]="rowData['commodity_info_template']"
                [escape]="false"
                tooltipPosition="bottom"
                [tooltipStyleClass]="
                  rowData['commodity_image']
                    ? 'commodity-tooltip__img'
                    : 'commodity-tooltip'
                "
              ></fa-icon>
              <!-- <div class="commodity_modal" *ngIf="col.showModal"></div> -->
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div
      style="height: 350px; width: 100%"
      [class.top-panel-hidden]="togglers['table']?.active != 'Map View'"
    >
      <google-map
        [height]="mapObject.options.height"
        width="100%"
        [zoom]="mapObject.options.zoom"
        [center]="mapObject.center"
        [options]="mapObject.options"
      >
        <map-marker-clusterer [imagePath]="mapObject.markerClustererImagePath">
          <map-marker
            *ngFor="let marker of markets.markers"
            [position]="marker.position"
            [options]="marker.options"
            (mapClick)="markerClickedEvent($event, marker)"
          >
          </map-marker>
        </map-marker-clusterer>
        <map-info-window></map-info-window>
      </google-map>
    </div>
  </div>

  <div
    class="container__chart b-r-24"
    [class.chartFullscreen]="fullscreen.chart"
    [class.hideLegend]="!showLegend"
    *ngIf="selectedSeries.length"
    (load)="setMenuOptionsForToggler('Chart')"
  >
    <p-toolbar class="toolbar" styleClass="toolbar-nopadding toolbar-b-r-24">
      <div class="p-toolbar-group-left">
        <p class="title">
          <app-toggler
            [chartFullscreen]="fullscreen.chart"
            name="chart"
            (onSelection)="toggleOptions($event)"
          >
          </app-toggler>
        </p>
      </div>

      <div class="p-toolbar-group-right">
        <p-badge
          [pTooltip]="
            selectedSeries.length +
            ' ' +
            (words['key-panel-main-no_selected_series'] || '')
          "
          tooltipPosition="left"
          [value]="selectedSeries.length"
        >
        </p-badge>
        <fa-icon
          class="controls"
          icon="laptop-code"
          (click)="widgetsGenerator.showDialog = true"
          [pTooltip]="words['key-panel-main-widget']"
          tooltipPosition="left"
        >
        </fa-icon>
        <fa-icon
          class="controls"
          icon="times-circle"
          (click)="clearAll()"
          [pTooltip]="words['key-panel-main-clear_all']"
          tooltipPosition="left"
        >
        </fa-icon>
        <fa-icon
          (click)="toggleFullscreen('chart')"
          class="controls"
          icon="expand-alt"
          [pTooltip]="words['key-panel-main-fullscreen']"
          tooltipPosition="left"
          tooltipPosition="bottom"
        ></fa-icon>
      </div>
    </p-toolbar>
    <div class="chart-grid p-grid">
      <div
        class="p-lg-10 p-sm-12 p-md-10 p-xl-10 container__chart__content"
        style="padding-right: 0"
      >
        <app-loader [loading]="loading"></app-loader>
        <div
          class="tabs-placeholder"
          *ngIf="togglers['chart']?.active != 'Chart'"
          [style.height]="fullscreen.chart ? '100%' : '400px'"
        ></div>
        <div
          class="chart-data"
          [class.hidden]="togglers['chart']?.active != 'Chart Data'"
          [ngStyle]="{
            height: fullscreen.chart ? '100%' : '400px',
            paddingBottom: fullscreen.chart ? '150px' : '0'
          }"
        >
          <p-toolbar
            *ngIf="user.isLogged && !remoteDataset"
            styleClass="admin-toolbar"
          >
            <div class="p-toolbar-group-left">
              <p-button
                [label]="words['key-admin-add']"
                icon="pi pi-plus"
                styleClass="admin-button"
                [disabled]="admin.disableButtons"
                (click)="addDatapoint()"
              ></p-button>
              <p-button
                [label]="words['key-admin-refresh']"
                icon="pi pi-refresh"
                styleClass="p-button-success admin-button"
                (click)="adminOperation().reload()"
              ></p-button>
              <!-- <p-button
                [label]="words['key-admin-upload']"
                icon="pi pi-upload"
                styleClass="p-button-success admin-button"
                (click)="adminOperation().triggerInput()"
              ></p-button> -->
              <p-button
                [label]="words['key-admin-delete']"
                icon="pi pi-times"
                styleClass="p-button-danger admin-button"
                [disabled]="admin.disableButtons"
                (click)="deleteDatapoint(admin.selectedRow)"
              ></p-button>
            </div>
          </p-toolbar>
          <div class="scrollable">
            <p-table
              selectionMode="single"
              [exportFilename]="exporter.filename"
              #chartDataTable
              [style]="{ width: chartData.cols.length * 100 + 'px' }"
              [columns]="chartData.cols"
              [value]="chartData.rows"
              class="p-table"
              styleClass="p-datatable-sm p-datatable-striped table-highlight sticky-header"
              (sortFunction)="customSort($event)"
              [customSort]="true"
              [(selection)]="admin.selectedRow"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    class="column roboto"
                    [ngStyle]="{
                      width: '100px',
                      'text-align': col.uid == 'date' ? 'center' : 'right'
                    }"
                    [pSortableColumn]="col.uid"
                  >
                    <span
                      *ngIf="col.uid != 'date'"
                      class="legend-color"
                      [style.backgroundColor]="col.legendColor"
                    ></span>
                    {{ col.name }}
                    <p-sortIcon [field]="col.uid"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-rowIndex="rowIndex"
                let-columns="columns"
              >
                <tr [pSelectableRow]="rowData">
                  <td
                    *ngFor="let col of columns"
                    class="row"
                    [ngStyle]="{
                      width: '100px',
                      'text-align': col.uid == 'date' ? 'center' : 'right'
                    }"
                  >
                    <span *ngIf="col.uid == 'date'">{{
                      rowData[col.uid]
                    }}</span>
                    <span *ngIf="col.uid != 'date'">
                      <span
                        *ngIf="!rowData[col.uid + '-data']?.editable"
                        (click)="
                          rowData[col.uid + '-data'] && user.isLogged
                            ? startUpdatingDatapoint(rowData, col.uid, rowIndex)
                            : null
                        "
                      >
                        {{ rowData[col.uid] | number : "1.2-2" || " " }}
                      </span>
                      <span *ngIf="rowData[col.uid + '-data']?.editable">
                        <input
                          size="5"
                          type="number"
                          style="width: 60px"
                          [(ngModel)]="rowData[col.uid]"
                          id="{{ col.uid + '-' + rowIndex }}"
                          (focus)="updateDatapointFocusEvent(rowData, col.uid)"
                          pInputNumber
                          (keyup.enter)="updateDatapoint(rowData, col.uid)"
                          (keyup.escape)="
                            cancelUpdateDatapoint(rowData, col.uid)
                          "
                        />
                      </span>
                      <span
                        *ngIf="
                          (chartTypes.selected == 'ipa' ||
                            chartTypes.selected == 'percentage') &&
                          col.uid != 'date' &&
                          rowData[col.uid] &&
                          col.hasColorLegend
                        "
                        class="ipa-threshold"
                        [style.backgroundColor]="rowData.color"
                      ></span>
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div style="height: 60px" *ngIf="user.isLogged"></div>
          </div>
        </div>
        <div
          class="chart-data"
          *ngIf="togglers['chart']?.active == 'Statistics'"
          [ngStyle]="{
            height: fullscreen.chart ? 'calc(100% - 100px)' : '400px'
          }"
        >
          <div class="scrollable">
            <app-statistics-table
              [statistics]="statistics"
              [periodicity]="periodicities.selected"
              (onCalculationRequest)="calculateStatistics($event[0])"
              (onShowChartPopupRequest)="showChartPopup($event[0])"
              [exportFilename]="exporter.filename"
            ></app-statistics-table>
            <div style="height: 150px" *ngIf="fullscreen.chart"></div>
          </div>
        </div>
        <div
          class="chart-data"
          *ngIf="togglers['chart']?.active == 'Information'"
          [ngStyle]="{ height: fullscreen.chart ? '100%' : '400px' }"
        >
          <div class="scrollable">
            <p-table
              [exportFilename]="exporter.filename"
              #informationDataTable
              [style]="{
                width: information.width + 'px',
                height: '100%',
                paddingBottom: '100px'
              }"
              [columns]="information.cols"
              [value]="information.rows"
              class="p-table"
              styleClass="p-datatable-sm p-datatable-striped table-highlight sticky-header"
              (sortFunction)="customSort($event)"
              [customSort]="true"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [style.borderWidth]="'3px 0 0 0'"
                    [style.borderColor]="col.group"
                    class="column roboto"
                    [style]="{ width: col.width }"
                    [pSortableColumn]="col.field"
                  >
                    {{ words[col.labelkey] }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td
                    *ngFor="let col of columns"
                    [style]="{ width: col.width + 'px' }"
                    class="row"
                  >
                    <p [innerHTML]="rowData[col.field]"></p>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div style="height: 150px" *ngIf="fullscreen.chart"></div>
          </div>
        </div>
        <div
          style="overflow: hidden"
          [chart]="chart"
          id="chart-panel"
          class="chart"
        ></div>
        <div class="chart-currencies">
          <app-toggler
            *ngIf="chartTypes.selected == 'market'"
            name="currency"
            (onSelection)="toggleOptions($event)"
          ></app-toggler>
        </div>
        <p class="market-season-label" *ngIf="chartTypes.selected == 'market'">
          {{ chartTypes.dataLabel }}
        </p>
        <p class="periodicity-label" *ngIf="chartTypes.selected == 'series'">
          {{
            words[
              "key-panel-main-chart-periodicity_label-" + periodicities.selected
            ]
          }}
        </p>
        <fa-icon
          icon="chevron-left"
          class="show-panel"
          (click)="toggleLegend()"
          [pTooltip]="words['key-panel-settings-show']"
          tooltipPosition="left"
        >
        </fa-icon>
        <fa-icon
          class="export-options"
          (click)="
            openExportingMenu($event, exportingMenu, togglers['chart'].active)
          "
          icon="bars"
        ></fa-icon>
        <p-menu
          [appendTo]="fullscreen.chart ? '' : 'body'"
          [style]="{ width: '200px', fontSize: '12px' }"
          #exportingMenu
          [popup]="true"
          [model]="exporter.exportingMenu"
        ></p-menu>
      </div>
      <div class="p-lg-2 p-sm-12 p-md-2 p-xl-2" style="padding-left: 0">
        <div class="legend">
          <p-toolbar class="toolbar" styleClass="toolbar-nopadding">
            <div class="p-toolbar-group-left">
              <p class="title">
                <app-toggler
                  name="legend"
                  (onSelection)="toggleOptions($event)"
                ></app-toggler>
                <fa-icon
                  icon="chevron-right"
                  class="hide-panel"
                  (click)="toggleLegend()"
                  [pTooltip]="words['key-panel-settings-hide']"
                  tooltipPosition="left"
                ></fa-icon>
              </p>
            </div>
          </p-toolbar>
          <div class="browser">
            <div class="type">
              <p-divider align="center">
                <b class="title">{{ words["key-panel-settings-browser"] }}</b>
              </p-divider>
              <div *ngIf="chartTypes.selected == 'series' || loading">
                <p-dropdown
                  [disabled]="true"
                  [style]="{ width: '100%' }"
                  styleClass="browser-dropdown"
                ></p-dropdown>
              </div>
              <div *ngIf="chartTypes.selected == 'ipa' && !loading">
                <p-dropdown
                  [options]="chartTypes.ipaArray"
                  [(ngModel)]="chartTypes.selectedIPA"
                  optionLabel="seriesName"
                  (ngModelChange)="changeIpaChart()"
                  [style]="{ width: '100%' }"
                  styleClass="browser-dropdown"
                  [appendTo]="fullscreen.chart ? '' : 'body'"
                ></p-dropdown>
              </div>
              <div *ngIf="chartTypes.selected == 'market' && !loading">
                <p-dropdown
                  [options]="selectedSeries"
                  [(ngModel)]="chartTypes.selectedMarket"
                  optionLabel="seriesName"
                  (ngModelChange)="
                    switchToMarketSeason('nominal', chartTypes.selectedMarket)
                  "
                  [style]="{ width: '100%' }"
                  styleClass="browser-dropdown"
                ></p-dropdown>
              </div>
              <div *ngIf="chartTypes.selected == 'percentage' && !loading">
                <p-dropdown
                  [options]="percentageChangeData.data"
                  [(ngModel)]="chartTypes.selectedPercentage"
                  optionLabel="seriesName"
                  (ngModelChange)="changePercentageChart()"
                  [style]="{ width: '100%' }"
                  styleClass="browser-dropdown"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div class="items" *ngIf="togglers['legend']?.active == 'Legend'">
            <div *ngIf="chartTypes.selected == 'series'">
              <div
                class="item p-shadow-2"
                *ngFor="let series of selectedSeries"
                (mouseover)="hoverLegend('start', series)"
                (mouseout)="hoverLegend('end', series)"
              >
                <fa-icon
                  icon="times-circle"
                  (click)="removeSeries(series)"
                  class="remove"
                  [pTooltip]="words['key-panel-settings-legend-remove_series']"
                  tooltipPosition="left"
                >
                </fa-icon>
                <p class="title">
                  {{ series.country_name }}, {{ series.market_name }},
                  {{ series.price_type }},
                  {{ series.commodity_name }}
                </p>
                <p
                  class="subtitle"
                  *ngIf="
                    series.showNominal ||
                    (series.currency == 'USD' && currencies.dollarOnly)
                  "
                >
                  <!-- (click)="toggleSeriesVisibility(series, 'nominal')" -->
                  <fa-icon
                    icon="check-circle"
                    [style.color]="series.legendColor"
                    class="checkmark"
                  >
                  </fa-icon>
                  {{ series.currency }}/{{ measures.selected }}
                  <span *ngIf="series.hover?.x"
                    ><b>{{ series.hover?.y.toFixed(2) }}</b
                    >({{ series.hover?.x }})</span
                  >
                </p>
                <p class="subtitle" *ngIf="series.showReal">
                  <!-- (click)="toggleSeriesVisibility(series, 'real')" -->
                  <fa-icon
                    icon="check-circle"
                    [style.color]="series.legendColorReal"
                    class="checkmark"
                  >
                  </fa-icon>
                  {{ series.currency }}-Real/{{ measures.selected }}
                  <span *ngIf="series.hoverReal?.x"
                    ><b>{{ series.hoverReal?.y.toFixed(2) }}</b
                    >({{ series.hoverReal?.x }})</span
                  >
                </p>
                <p class="subtitle" *ngIf="series.showDollar">
                  <!-- (click)="toggleSeriesVisibility(series, 'dollar')" -->
                  <fa-icon
                    icon="check-circle"
                    [style.color]="series.legendColorDollar"
                    class="checkmark"
                  >
                  </fa-icon>
                  USD/{{ measures.selected }}
                  <span *ngIf="series.hoverDollar?.x"
                    ><b>{{ series.hoverDollar?.y.toFixed(2) }}</b
                    >({{ series.hoverDollar?.x }})</span
                  >
                </p>
              </div>
            </div>
            <div *ngIf="chartTypes.selected == 'market'">
              <div class="market-season">
                <div
                  class="p-field-checkbox"
                  *ngFor="let data of marketSeasonData"
                >
                  <p-checkbox
                    [disabled]="data.isEmpty"
                    (onChange)="marketSeasonSelectEvent($event, data)"
                    [value]="data.uid"
                    [(ngModel)]="data.isSelected"
                  ></p-checkbox>
                  <label color="grey">
                    <span
                      class="legend-color"
                      [style.backgroundColor]="data.legendColor"
                    ></span>
                    {{
                      data.endYear == data.startYear
                        ? data.endYear
                        : data.startYear + " / " + data.endYear
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="chartTypes.selected == 'ipa'">
              <div class="item p-shadow-2">
                <fa-icon
                  icon="times-circle"
                  class="remove"
                  [pTooltip]="words['key-panel-settings-legend-remove_series']"
                  tooltipPosition="left"
                >
                </fa-icon>
                <p class="title">
                  {{ chartTypes.selectedIPA.seriesName }}
                </p>
                <p class="subtitle">
                  <fa-icon
                    icon="check-circle"
                    [style.color]="chartTypes.ipaColors.green"
                    class="checkmark"
                  >
                  </fa-icon>
                  {{ words["key-panel-settings-legend-ipa-green"] }}
                </p>
                <p class="subtitle">
                  <fa-icon
                    icon="check-circle"
                    [style.color]="chartTypes.ipaColors.yellow"
                    class="checkmark"
                  >
                  </fa-icon>
                  {{ words["key-panel-settings-legend-ipa-yellow"] }}
                </p>
                <p class="subtitle">
                  <fa-icon
                    icon="check-circle"
                    [style.color]="chartTypes.ipaColors.red"
                    class="checkmark"
                  >
                  </fa-icon>
                  {{ words["key-panel-settings-legend-ipa-red"] }}
                </p>
                <p class="subtitle">
                  <fa-icon
                    icon="check-circle"
                    [style.color]="chartTypes.ipaColors.grey"
                    class="checkmark"
                  >
                  </fa-icon>
                  {{ words["key-panel-settings-legend-ipa-grey"] }}
                </p>
              </div>
            </div>
            <div class="spacer-100"></div>
          </div>
          <div class="options">
            <div *ngIf="togglers['legend']?.active == 'Options'">
              <div class="type">
                <p-divider align="center">
                  <b class="title">{{
                    words["key-panel-settings-label-periodicity"]
                  }}</b>
                </p-divider>
                <div class="periodicities">
                  <div
                    *ngFor="let period of periodicities.types"
                    class="periodicity"
                    [class.active]="period.active"
                    [class.selected]="periodicities.selected == period.period"
                    (click)="
                      period.active ? changePeriodicity(period.period) : null
                    "
                  >
                    <span class="icon"></span>
                    {{ words[period.label] }}
                  </div>
                </div>
              </div>
              <div class="type">
                <p-divider align="center">
                  <b class="title">{{
                    words["key-panel-settings-label-currency"]
                  }}</b>
                </p-divider>
                <div class="currencies">
                  <div *ngFor="let currency of currencies.types">
                    <div class="p-field-checkbox" *ngIf="!currency.hidden">
                      <p-checkbox
                        [name]="currency.type"
                        [value]="currency.type"
                        (onChange)="currencySelectedEvent($event, currency)"
                        [(ngModel)]="currency.selected"
                        [inputId]="currency.type"
                        [disabled]="chartTypes.selected == 'ipa'"
                        [binary]="true"
                      ></p-checkbox>
                      <label color="grey">
                        {{
                          currency.name == "Dollars"
                            ? words["key-panel-settings-label-currency-dollars"]
                            : currency.name
                        }}
                        {{
                          currency.type != "dollar"
                            ? " - " +
                              (words[
                                "key-panel-settings-label-currency-" +
                                  currency.type
                              ] || "")
                            : ""
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="type">
                <p-divider align="center">
                  <b class="title">{{
                    words["key-panel-settings-label-measure"]
                  }}</b>
                </p-divider>
                <div class="measures">
                  <div
                    class="p-field-radiobutton"
                    *ngFor="let measure of measures.types"
                  >
                    <p-radioButton
                      [value]="measure.name"
                      [(ngModel)]="measures.selected"
                      (ngModelChange)="measureSelectedEvent(measure)"
                      [disabled]="
                        chartTypes.selected == 'ipa' ||
                        chartTypes.selected == 'percentage'
                      "
                    >
                    </p-radioButton>
                    <label for="city1">{{ measure.name }}</label>
                  </div>
                </div>
              </div>
              <div class="type">
                <p-divider align="center">
                  <b class="title">{{
                    words["key-panel-settings-label-chart_type"]
                  }}</b>
                </p-divider>
                <div class="measures">
                  <div
                    class="p-field-radiobutton"
                    *ngFor="let type of chartTypes.types"
                  >
                    <p-radioButton
                      [value]="type.name"
                      (ngModelChange)="changeChartType(type)"
                      [(ngModel)]="chartTypes.selected"
                      [disabled]="
                        type.name == 'ipa' &&
                        periodicities.selected != 'monthly'
                      "
                    >
                    </p-radioButton>
                    <label>{{ words[type.label] }}</label>
                    <div *ngIf="type.options" (click)="ipaPanel.toggle($event)">
                      <fa-icon class="controls" icon="chevron-down"></fa-icon>
                      <p-overlayPanel
                        #ipaPanel
                        styleClass="chart-options-panel"
                      >
                        <ng-template pTemplate>
                          <div
                            *ngFor="let option of type.options"
                            class="p-field-radiobutton"
                          >
                            <p-radioButton
                              [value]="option.value"
                              [(ngModel)]="chartTypes[type.model]"
                              (ngModelChange)="
                                switchChartTypeSubOptions(type.model)
                              "
                            ></p-radioButton>
                            <label>{{ words[option.label] }}</label>
                          </div>
                        </ng-template>
                      </p-overlayPanel>
                    </div>
                  </div>
                </div>
              </div>
              <div class="type">
                <p-divider align="center">
                  <b class="title">{{
                    words["key-panel-settings-label-chart_options"]
                  }}</b>
                </p-divider>
                <div class="measures">
                  <div class="p-field-radiobutton">
                    <p-checkbox
                      [(ngModel)]="chartTypes.ignoreNegative"
                      [label]="
                        words[
                          'key-panel-settings-label-chart_options-ignore_negatives'
                        ]
                      "
                      [binary]="true"
                      (ngModelChange)="
                        ipaChartIgnoreNegative(chartTypes.ignoreNegative)
                      "
                    ></p-checkbox>
                  </div>
                </div>
                <div class="measures" *ngIf="chartTypes.selected == 'series'">
                  <div class="p-field-radiobutton">
                    <p-checkbox
                      [(ngModel)]="chartTypes.connectNulls"
                      [label]="
                        words[
                          'key-panel-settings-label-chart_options-connect_nulls'
                        ]
                      "
                      [binary]="true"
                      (ngModelChange)="
                        timeSeriesConnectNulls(chartTypes.connectNulls)
                      "
                    ></p-checkbox>
                  </div>
                </div>
              </div>
              <div class="spacer-100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-placeholder" *ngIf="!selectedSeries.length">
    <div class="chart-placeholder__toolbar">
      <div class="chart-placeholder__toolbar__toggler"></div>
      <div class="chart-placeholder__toolbar__toggler"></div>
      <div class="chart-placeholder__toolbar__toggler"></div>
    </div>
    <div class="chart-placeholder__body">
      <div class="chart-placeholder__body__chart">
        <div class="container">
          <span class="title"
            ><b>{{ words["key-panel-empty-title"] }}</b
            ><br />
            {{ words["key-panel-empty-subtitle"] }}</span
          >
          <img src="assets/imgs/chart-placeholder.png" alt="" />
        </div>
      </div>
      <div class="chart-placeholder__body__options">
        <div class="chart-placeholder__body__options__title"></div>
        <div class="chart-placeholder__body__options__element"></div>
        <div class="chart-placeholder__body__options__element"></div>
        <div class="chart-placeholder__body__options__title"></div>
        <div class="chart-placeholder__body__options__element"></div>
        <div class="chart-placeholder__body__options__element"></div>
      </div>
    </div>
  </div>
  <div class="footer" *ngIf="!selectedSeries.length" [innerHTML]="footer"></div>
</div>
