import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as Rx from "rxjs";

@Injectable()
export class CacheService {
  constructor() {}

  /**
   * Store Data for amount of time
   * @param {string} key
   * @param {any} data
   * @param {integer} ttl Time To Live in minutes
   */
  remember(key: string, data, ttl, expiry = null) {
    let expireAt;
    if (typeof ttl == "number") {
      expireAt = Date.now() + ttl * 1000;
    } else if (ttl == "date") {
      expireAt = expiry;
    }

    return Rx.Observable.create((observer) => {
      const savedData = { data, expireAt };

      localStorage.setItem(key, JSON.stringify(savedData));
      observer.next(savedData);
    });
  }

  rememberForever(key: string, data) {
    return Rx.Observable.create((observer) => {
      let rememberYear = 31536000;
      this.remember(key, data, rememberYear).subscribe((respones) => {
        observer.next(respones);
      });
    });
  }

  /**
   * Return Cached Data by key
   * @param {string}  key
   * @param {boolean} withExpired
   */

  get(key: string, withExpired: boolean = false) {
    if (this.isOffline()) {
      withExpired = true;
    }

    return Rx.Observable.create((observer) => {
      let savedData = localStorage.getItem(key);

      if (!savedData) {
        observer.error(undefined);
      } else {
        let fetchedData = JSON.parse(savedData);

        if (
          (savedData != null && fetchedData.expireAt > Date.now()) ||
          (fetchedData != null && withExpired)
        ) {
          observer.next(fetchedData.data);
          observer.complete();
        }
      }
    });
  }

  loadFromDelayedObservable(key: string, request, fromCache: boolean = false) {
    return Rx.Observable.create((observer) => {
      this.get(key).subscribe(
        (data) => {
          if (this.isOnline() && !fromCache) {
            this.doRequestAndRemember(observer, request, key, data);
          } else {
            observer.next(data);
          }
        },
        (error) => {
          if (this.isOnline()) {
            this.doRequestAndRemember(observer, request, key);
          }
        }
      );
    });
  }

  loadFromObservable(key: string, request) {
    return Rx.Observable.create((observer) => {
      if (this.isOnline()) {
        this.doRequestAndRemember(observer, request, key);
      }
    });
  }

  protected doRequestAndRemember(observer, request, key, cachedData?) {
    let sentOnce = false;
    if (cachedData) {
      setTimeout(() => {
        if (!sentOnce) {
          sentOnce = true;
          observer.next(cachedData);
        }
      }, 300);
    }
    request.subscribe((response) => {
      this.rememberForever(key, response).subscribe(() => {
        if (!cachedData) {
          observer.next(response);
        } else {
          if (!sentOnce || !this.objectsMatch(response, cachedData)) {
            sentOnce = true;
            observer.next(response);
          }
        }
      });
    });
  }

  /**
   * Remove data from cache by key
   * @param {string} key
   */
  remove(key: string) {
    return Rx.Observable.create((observer) => {
      localStorage.remove(key);

      observer.next();
      observer.complete();
    });
  }

  /**
   * Clear Storage
   */
  clear() {
    localStorage.clear();
  }

  isOnline() {
    return true;
  }

  isOffline() {
    return false;
  }

  protected objectsMatch(obj1, obj2): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
}
