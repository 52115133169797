import { CacheService } from "./services/cache.service";
import { MessageService } from "primeng/api";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ApisService } from "./services/apis.service";
import { ToolComponent } from "./pages/tool/tool.component";
import { AppHttpInterceptor } from "./modules/interceptor.module";

import { ChartModule, HIGHCHARTS_MODULES } from "angular-highcharts";

import { GoogleMapsModule } from "@angular/google-maps";

import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faExpandAlt,
  faDownload,
  faAngleDoubleRight,
  faTimesCircle,
  faFileAlt,
  faCheckCircle,
  faSpinner,
  faTable,
  faSave,
  faUpload,
  faPlusCircle,
  faChartArea,
  faChartLine,
  faQuestionCircle,
  faList,
  faCogs,
  faTh,
  faGlobe,
  faChevronRight,
  faChevronLeft,
  faCog,
  faBars,
  faWindowRestore,
  faMapMarkerAlt,
  faInfoCircle,
  faChevronDown,
  faEllipsisV,
  faFileCsv,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";

import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { SliderModule } from "primeng/slider";
import { MultiSelectModule } from "primeng/multiselect";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { ProgressBarModule } from "primeng/progressbar";
import { InputTextModule } from "primeng/inputtext";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TogglerComponent } from "./components/toggler/toggler.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { SidebarModule } from "primeng/sidebar";
import { PanelMenuModule } from "primeng/panelmenu";
import { RippleModule } from "primeng/ripple";
import { MenuModule } from "primeng/menu";
import { BadgeModule } from "primeng/badge";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { TieredMenuModule } from "primeng/tieredmenu";
import { SlideMenuModule } from "primeng/slidemenu";
import { SelectButtonModule } from "primeng/selectbutton";
import { InputTextareaModule } from "primeng/inputtextarea";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BrowserComponent } from "./pages/browser/browser.component";
import { HomeComponent } from "./pages/home/home.component";
import { InputNumberModule } from "primeng/inputnumber";
import { AvatarModule } from "primeng/avatar";
import { ColorPickerModule } from "primeng/colorpicker";
import { StepsModule } from "primeng/steps";
import { TabViewModule } from "primeng/tabview";
import { FieldsetModule } from "primeng/fieldset";
import { CardModule } from "primeng/card";

import more from "highcharts/highcharts-more.src";
import exporting from "highcharts/modules/exporting.src";
import highstock from "highcharts/modules/stock.src.js";
import { WidgetChartComponent } from "./components/widgets/widget-chart/widget-chart.component";
import { ClipboardModule } from "ngx-clipboard";
import { LoaderComponent } from "./components/loader/loader.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";

import { CookieService } from "ngx-cookie-service";
import { CrudModalComponent } from "./components/modals/crud-modal/crud-modal.component";
import { WidgetChartModalComponent } from "./components/modals/widget-chart-modal/widget-chart-modal.component";

import { ReactiveFormsModule } from "@angular/forms";
import * as moment from "moment-timezone";
import { ColumnsPickerPipe } from "./columns-picker.pipe";
import { WidgetStatisticsModalComponent } from "./components/modals/widget-statistics-modal/widget-statistics-modal.component";
import { WidgetsGeneratorComponent } from "./components/modals/widgets-generator/widgets-generator.component";
import { StatisticsTableComponent } from "./components/statistics-table/statistics-table.component";
import { WidgetStatisticsComponent } from "./components/widgets/widget-statistics/widget-statistics.component";
import { PriceUploadModalComponent } from "./components/modals/price-upload-modal/price-upload-modal.component";
import { TaskLogComponent } from "./components/modals/task-log/task-log.component";

window.moment = moment;

@NgModule({
  declarations: [
    AppComponent,
    TogglerComponent,
    ToolComponent,
    DashboardComponent,
    BrowserComponent,
    HomeComponent,
    WidgetChartComponent,
    LoaderComponent,
    ProgressBarComponent,
    CrudModalComponent,
    WidgetChartModalComponent,
    ColumnsPickerPipe,
    WidgetStatisticsModalComponent,
    WidgetsGeneratorComponent,
    StatisticsTableComponent,
    WidgetStatisticsComponent,
    PriceUploadModalComponent,
    TaskLogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ChartModule,
    ClipboardModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ProgressBarModule,
    InputTextModule,
    ToolbarModule,
    TooltipModule,
    RadioButtonModule,
    SidebarModule,
    PanelMenuModule,
    MenuModule,
    RippleModule,
    BadgeModule,
    CheckboxModule,
    DividerModule,
    TieredMenuModule,
    InputNumberModule,
    SlideMenuModule,
    SelectButtonModule,
    InputTextareaModule,
    GoogleMapsModule,
    OverlayPanelModule,
    AvatarModule,
    ColorPickerModule,
    StepsModule,
    TabViewModule,
    FieldsetModule,
    CardModule,
  ],
  providers: [
    ApisService,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [more, exporting, highstock],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    MessageService,
    CacheService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faExpandAlt,
      faDownload,
      faAngleDoubleRight,
      faTimesCircle,
      faFileAlt,
      faCheckCircle,
      faSpinner,
      faTable,
      faSave,
      faUpload,
      faPlusCircle,
      faChartArea,
      faChartLine,
      faQuestionCircle,
      faList,
      faCogs,
      faCog,
      faTh,
      faGlobe,
      faChevronRight,
      faChevronLeft,
      faChevronDown,
      faBars,
      faWindowRestore,
      faMapMarkerAlt,
      faInfoCircle,
      faEllipsisV,
      faFileCsv,
      faLaptopCode
    );
  }
}
