import { SettingsService } from "src/app/services/settings.service";
import { StatisticsService } from "src/app/services/statistics.service";
import { ChartConversionsService } from "src/app/services/chart-conversions.service";
import { Component, OnInit } from "@angular/core";
import { ApisService } from "src/app/services/apis.service";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/services/helper.service";
import * as _ from "lodash";

@Component({
  selector: "app-widget-statistics",
  templateUrl: "./widget-statistics.component.html",
  styleUrls: ["./widget-statistics.component.scss"],
})
export class WidgetStatisticsComponent implements OnInit {
  loading: boolean = true;
  params: any = {};
  ids: any;
  widgets: any;
  updateSeries: boolean = true;
  permalink: string = null;
  statistics: any = this.helper.getStatisticsTemplate();
  words: any = {};

  constructor(
    private route: ActivatedRoute,
    private helper: HelperService,
    private apis: ApisService,
    private chartConversions: ChartConversionsService,
    private statisticsService: StatisticsService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.settingsService.getSettings().subscribe((configs) => {
      if (!configs) return;
      this.route.queryParams.subscribe((params) => {
        if (
          this.params.d != params.d ||
          this.params.n != params.n ||
          !_.isEqual(this.params.wi, params.wi)
        )
          this.updateSeries = true;
        this.params = params;
        let lang = { key: this.params.langKey };
        this.words = configs.languagesTexts[`language-${lang.key}`];
        this.initWidget();
      });
    });
  }

  initWidget() {
    this.widgets = JSON.parse(decodeURIComponent(this.params.wi));
    this.ids = this.widgets.map((w) => w[0]);
    this.apis
      .getMultiplePrices(this.ids, this.params.per)
      .subscribe((res: any) => {
        this.calculateStatistics(res);
      });
  }

  calculateStatistics(res) {
    let data = res.results.map((r) => {
      if (!r.id) r.id = r.uuid;
      return r;
    });
    let series = [];
    this.ids.map((id) => {
      let s: any = { id };
      let datapoints = data.find((r) => r.id == s.id).datapoints;
      let nominal = this.helper.getDataPoints(datapoints);
      let real = this.helper.getDataPoints(datapoints, "real");
      let dollar = this.helper.getDataPoints(datapoints, "dollar");

      s.series = nominal;
      s.nominalDatapoints = nominal;
      s.realDatapoints = real;
      s.dollarDatapoints = dollar;

      if (JSON.parse(this.params.d)) {
        if (s.series) s.series = s.series.slice(-1 * Number(this.params.n));
        if (s.realDatapoints)
          s.realDatapoints = s.realDatapoints.slice(-1 * Number(this.params.n));
        if (s.dollarDatapoints)
          s.dollarDatapoints = s.dollarDatapoints.slice(
            -1 * Number(this.params.n)
          );
      }

      let seriesParams = this.widgets.find((w) => w[0] == s.id);
      s.legendColor = `#${seriesParams[1]}`;
      s.country_name = seriesParams[2];
      s.price_type = seriesParams[3];
      s.market_name = seriesParams[4];
      s.commodity_name = seriesParams[5];
      s.currency = seriesParams[6];
      s.measure_unit_label = seriesParams[7];

      s = this.chartConversions.formatAllMeasures(s);
      series.push(s);
    });
    let selectedCurrencies = this.params.c.split(",");
    let params = {
      extremes: {
        min: "0001-01-01T00:00:00.000Z", // Date object min date
        max: "+275760-09-13T00:00:00.000Z", // Date object max date
      },
      statistics: this.helper.getStatisticsTemplate(),
      selectedCurrencies: selectedCurrencies,
      measure: this.params.m,
    };
    if (this.params.t1)
      params.statistics["timeMinusVariantSparkChart"] = this.params.t1;
    if (this.params.tn) params.statistics["timeMinusVariant"] = this.params.tn;
    let statistics = this.statisticsService.calculateStatistics(
      series,
      this.params.type,
      params,
      this.params.per,
      this.words
    );
    let fields = this.params.fields.split(",");
    statistics.cols = statistics.cols.filter((c) => {
      return fields.includes(c.uid);
    });
    this.statistics = statistics;
  }

  checkForPermalink() {
    if (JSON.parse(this.params.perm || null)) {
      this.permalink = this.helper.createPermalink(
        this.ids,
        this.params.dataset
      );
    } else {
      this.permalink = null;
    }
  }
}
