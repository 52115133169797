import { MessageService } from "primeng/api";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.messageService.add({
            severity: "error",
            summary: "An error occurred",
            detail: err.error.detail || "Please check your internet connection",
          });
        }
        return throwError(err);
      })
    );
  }
}
