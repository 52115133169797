import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class MarketSeasonService {
  constructor() {}

  getMarketSeasonData(series, property) {
    var aSeries = _.cloneDeep(series);
    var startMarketSeasonMonth = aSeries.info.commodityMarketSeasonStart;
    var listObjPointByYear = this.splitInYearStartingFromMonthMarketSeasonStart(
      aSeries[property],
      startMarketSeasonMonth
    );
    this.replacesDateWithMonthToAllYears(listObjPointByYear);
    this.convertToMonthPoints(listObjPointByYear);
    this.sortByMarketSeasonStartMonth(
      listObjPointByYear,
      startMarketSeasonMonth - 1
    );
    listObjPointByYear.sort(
      /*descending sort*/ function (a, b) {
        return -(a.startYear - b.startYear);
      }
    );

    return listObjPointByYear;
  }

  private splitInYearStartingFromMonthMarketSeasonStart(data, startMonth) {
    var listObjPointByYear = [];
    var firstYear = this.getYearFromDate(data[0][0]);
    var lastYear = this.getYearFromDate(data[data.length - 1][0]);

    for (var year = firstYear; year <= lastYear; year++) {
      var dateStart = startMonth + "/" + "01/" + year;
      var start = moment.utc(dateStart, "M/D/YYYY").valueOf();
      var end = moment
        .utc(dateStart, "M/D/YYYY")
        .add(12, "months")
        .subtract(1, "day")
        .valueOf();
      var pointByYear = this.selectDataPoints(data, start, end);
      pointByYear = _.map(pointByYear, (n) => {
        return { month: n[0], y: n[1], year: this.getYearFromDate(n[0]) };
      });
      var isEmpty = this._isEmpty(pointByYear);
      if (isEmpty) pointByYear = this.emptyYear();
      var startYear = this.getYearFromDate(start);
      var endYear = this.getYearFromDate(end);
      var objPointByYear = {
        startYear: year,
        endYear: endYear,
        data: pointByYear,
        isEmpty: isEmpty,
      };
      listObjPointByYear.push(objPointByYear);
    }
    return listObjPointByYear;
  }

  private _isEmpty(pointByYear) {
    var empty = _.isEmpty(pointByYear);
    if (empty) return empty;
    for (var i = 0; i < pointByYear.length; i++) {
      empty = pointByYear[i].y == null;
      if (!empty) {
        return empty;
      }
    }
    return empty;
  }

  private convertToMonthPoints(listObjPointByYear) {
    var year;
    var monthsInYear = 12;

    for (year in listObjPointByYear) {
      var pointThisMonth = [];

      if (!listObjPointByYear[year].isEmpty) {
        var months = listObjPointByYear[year].data;
        for (var monthIndex = 0; monthIndex < monthsInYear; monthIndex++) {
          var pointsByMonth: any = this.filterPointByMonth(months, monthIndex);

          if (this.monthIsNull(pointsByMonth)) {
            pointsByMonth = this.emptyMonth(monthIndex);
          } else {
            var notNULLPointsByMonth =
              this.selectDataPointsByMonthNotNull(pointsByMonth);
            var prices = this.selectDataPointsPrice(notNULLPointsByMonth);
            var value = this.averagePrices(prices);
            pointsByMonth = {
              y: value,
              month: monthIndex,
              year: pointsByMonth[0].year,
            };
          }
          pointThisMonth.push(pointsByMonth);
        }
        listObjPointByYear[year].data = pointThisMonth;
      }
    }
  }

  private filterPointByMonth(data, month) {
    return this.selectDataPointsByMonth(data, month);
  }

  private filterPointByYear(data, year) {
    return this.selectDataPointsByYear(data, year);
  }

  private sortStartingFromValue(data, startValue) {
    var startIndex = data
      .map((e) => {
        return e.month;
      })
      .indexOf(startValue);
    if (startIndex > -1) {
      var first = data.slice(startIndex, data.length);
      var second = data.slice(0, startIndex);
      data = first.concat(second);
    }
    return data;
  }

  private replacesDateWithMonth(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].month > 11) {
        data[i].month = this.getMonthFromDate(data[i].month);
      }
    }
    return data;
  }

  private replacesDateWithMonthToAllYears(listObjPointByYear) {
    var year;
    for (year in listObjPointByYear) {
      this.replacesDateWithMonth(listObjPointByYear[year].data);
    }
    return listObjPointByYear;
  }

  private sortByMarketSeasonStartMonth(
    listObjPointByYear,
    startMarketSeasonMonth
  ) {
    var year;
    for (year in listObjPointByYear) {
      if (startMarketSeasonMonth > 0) {
        listObjPointByYear[year].data = this.sortStartingFromValue(
          listObjPointByYear[year].data,
          startMarketSeasonMonth
        );
      }
    }
    return listObjPointByYear;
  }

  private emptyMonth(month) {
    return { month: month, y: null };
  }

  private emptyYear() {
    var data = [];
    for (var i = 0; i <= 11; i++) {
      data.push(this.emptyMonth(i));
    }
    return data;
  }

  private getYearFromDate(date) {
    return moment.utc(date).year();
  }
  private getMonthFromDate(date) {
    return moment.utc(date).month();
  }

  private selectDataPoints(dataset, from, to) {
    return _.filter(dataset, (e) => {
      return e[0] >= from && e[0] <= to;
    });
  }

  private selectDataPointsByYear(dataset, year) {
    return _.filter(dataset, (e) => {
      return this.getYearFromDate(e[0]) == year;
    });
  }

  private selectDataPointsByMonth(dataset, month) {
    return _.filter(dataset, (n) => {
      return n.month == month;
    });
  }

  private monthIsNull(dataPointsMonth) {
    return (
      _.filter(dataPointsMonth, (n) => {
        return n.month == null || n.y == null;
      }).length == dataPointsMonth.length || _.isEmpty(dataPointsMonth)
    );
  }

  private selectDataPointsByMonthNotNull(dataPointsMonth) {
    return _.filter(dataPointsMonth, (n) => {
      return n.month != null && n.y != null;
    });
  }

  private averagePrices(prices) {
    return _.sum(prices) / prices.length;
  }

  private selectDataPointsPrice(dataPoints) {
    var prices = [];
    for (var i = 0; i < dataPoints.length; i++) {
      prices.push(dataPoints[i].y);
    }
    return prices;
  }
}
