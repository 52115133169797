<!-- <p-dialog
  [appendTo]="'body'"
  [header]="words['key-widget-chart-header']"
  [(visible)]="widgets.showDialog"
  [baseZIndex]="2500"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [style]="{ width: '90%' }"
> -->
<div class="p-grid" style="height: 100%; font-size: 0.9rem">
  <div class="p-col-6">
    <div class="p-grid">
      <div class="p-col-6">
        <div class="p-grid p-fluid">
          <div class="p-field p-col-6 p-mb-0 p-mt-0">
            <h5 class="p-mb-2 p-mt-0">
              {{ words["key-widget-chart-width"] }}
            </h5>
            <p-inputNumber
              [(ngModel)]="widgets.width"
              (ngModelChange)="seriesParamChanged()"
              incrementButtonClass="p-button-secondary"
              decrementButtonClass="p-button-secondary"
              [showButtons]="true"
              mode="decimal"
              [min]="50"
              [max]="3000"
              [step]="10"
            >
            </p-inputNumber>
          </div>
          <div class="p-field p-col-6 p-mb-0 p-mt-0">
            <h5 class="p-mb-2 p-mt-0">
              {{ words["key-widget-chart-height"] }}
            </h5>
            <p-inputNumber
              [(ngModel)]="widgets.height"
              (ngModelChange)="seriesParamChanged()"
              incrementButtonClass="p-button-secondary"
              decrementButtonClass="p-button-secondary"
              [showButtons]="true"
              mode="decimal"
              [min]="50"
              [max]="2000"
              [step]="10"
            >
            </p-inputNumber>
          </div>
          <div class="p-col-6 p-field p-mb-0 p-mt-0">
            <h5 class="p-mb-2 p-mt-0">
              {{ words["key-widget-chart-date_range"] }}
            </h5>
            <div class="p-field-checkbox p-mt-3 p-mb-0 p-ml-3">
              <p-checkbox
                binary="true"
                [(ngModel)]="widgets.dynamicDateRange"
                (ngModelChange)="seriesParamChanged()"
                inputId="dateRange"
              >
              </p-checkbox>
              <label for="dateRange">{{
                words["key-widget-chart-date_range-dynamic"]
              }}</label>
            </div>
          </div>
          <div class="p-col-6 p-field p-mb-0 p-mt-0">
            <h5 class="p-mb-2 p-mt-0">
              {{ words["key-widget-chart-latest_no_period"] }}
            </h5>
            <p-inputNumber
              [(ngModel)]="widgets.noPeriod"
              (ngModelChange)="seriesParamChanged()"
              incrementButtonClass="p-button-secondary"
              decrementButtonClass="p-button-secondary"
              [showButtons]="true"
              mode="decimal"
              [min]="1"
              [max]="1000"
              [step]="1"
              [disabled]="!widgets.dynamicDateRange"
            >
            </p-inputNumber>
          </div>
          <div class="p-col-12 p-field p-mt-0">
            <h5 class="p-mb-2 p-mt-0">
              {{ words["key-panel-settings-label-currency"] }}
            </h5>
            <p-multiSelect
              [options]="currencies"
              [(ngModel)]="widgets.selectedCurrencies"
              optionLabel="label"
              optionValue="value"
              [style]="{ width: '100%' }"
              appendTo="body"
              [filter]="false"
              [showHeader]="false"
              (ngModelChange)="seriesParamChanged()"
            ></p-multiSelect>
          </div>
        </div>
      </div>
      <div class="p-col-6 p-pl-3">
        <h5 class="p-mb-2 p-mt-0">Type</h5>
        <p-dropdown
          [options]="options"
          [(ngModel)]="currentOption"
          optionLabel="label"
          (ngModelChange)="optionChanged(currentOption)"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <h5 class="p-mb-2 p-mt-3">Fields</h5>
        <p-multiSelect
          [options]="fields"
          [(ngModel)]="selectedFields"
          (ngModelChange)="seriesParamChanged()"
          optionLabel="label"
          [style]="{ width: '100%' }"
          appendTo="body"
          [filter]="false"
          [showHeader]="false"
        ></p-multiSelect>
        <h5 class="p-mb-2 p-mt-3">Language</h5>
        <p-dropdown
          [options]="languages"
          [(ngModel)]="selectedLangauge"
          optionLabel="title"
          (ngModelChange)="seriesParamChanged()"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <div *ngIf="currentOption.name == 'percentage'">
          <h5 class="p-mb-2 p-mt-3">t-1 in the latest years</h5>
          <p-inputNumber
            [(ngModel)]="widgets.timeMinusVariantSparkChart"
            (ngModelChange)="seriesParamChanged()"
            incrementButtonClass="p-button-secondary"
            decrementButtonClass="p-button-secondary"
            [showButtons]="true"
            mode="decimal"
            [min]="0"
            [max]="100"
            [step]="1"
          >
          </p-inputNumber>
          <h5 class="p-mb-2 p-mt-3">t-n value</h5>
          <p-inputNumber
            [(ngModel)]="widgets.timeMinusVariant"
            (ngModelChange)="seriesParamChanged()"
            incrementButtonClass="p-button-secondary"
            decrementButtonClass="p-button-secondary"
            [showButtons]="true"
            mode="decimal"
            [min]="0"
            [max]="100"
            [step]="1"
          >
          </p-inputNumber>
        </div>

        <!-- <div class="p-field-checkbox p-mt-3">
          <p-checkbox
            binary="true"
            [(ngModel)]="widgets.enablePermalink"
            (ngModelChange)="seriesParamChanged()"
          >
          </p-checkbox>
          <label color="grey">{{ words["key-widget-chart-permalink"] }}</label>
        </div> -->
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="color-picker">
      <div class="series" *ngFor="let serie of series">
        <div class="p-grid">
          <div class="p-col-8">
            <p [style]="{ color: serie.color }">
              {{ serie.seriesName }}
            </p>
          </div>
          <div class="p-col-4">
            <div class="colors">
              <p-colorPicker
                [(ngModel)]="serie.color"
                format="hex"
                styleClass="widgetColorPicker"
                appendTo="body"
                (ngModelChange)="seriesParamChanged()"
              ></p-colorPicker>
              <input
                pInputText
                [(ngModel)]="serie.color"
                (ngModelChange)="seriesParamChanged()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <textarea
      readonly="true"
      #widgetCode
      [(ngModel)]="widgets.preview"
      pInputTextarea
      style="width: 100%"
      rows="5"
    ></textarea>
    <div class="p-d-flex">
      <div>
        <button
          type="button"
          (click)="showCopyAlert()"
          pButton
          pRipple
          icon="pi pi-copy"
          class="p-button-secondary"
          [label]="words['key-widget-chart-copy_script']"
          [ngxClipboard]="widgetCode"
          [style]="{ 'font-size': '10px' }"
        ></button>
      </div>
      <div class="p-field-checkbox p-ml-2 p-mt-2">
        <p-checkbox
          name="group1"
          value="New York"
          binary="true"
          [(ngModel)]="widgets.livePreview"
          inputId="ny"
          (ngModelChange)="seriesParamChanged()"
        >
        </p-checkbox>
        <label>{{ words["key-widget-chart-live_preview"] }}</label>
      </div>
    </div>
  </div>
</div>
<div
  style="background-color: #e5e5e5; border-radius: 10px; padding: 20px"
  *ngIf="widgets.livePreview"
>
  <iframe
    style="border: none; transition: all 0.5s linear"
    frameborder="0"
    scrolling="no"
    async="true"
    [width]="widgets.width + 'px'"
    [height]="widgets.height + 'px'"
    [src]="widgets.url"
    frameborder="0"
  ></iframe>
</div>
<!-- </p-dialog> -->
