<app-progress-bar [loading]="loading"></app-progress-bar>
<div
  class="header"
  [style.backgroundColor]="header.headerColor"
  [style.borderBottom]="'1px solid ' + header.bottomLineColor"
>
  <div class="p-d-flex">
    <div class="p-mr-2">
      <div class="logo">
        <img
          *ngFor="let logo of header.headerLogos"
          @fadeIn
          [src]="logo.imgPath"
          [style.width]="logo.width"
        />
      </div>
    </div>
    <div class="p-mr-2" style="width: 100%">
      <div class="titles">
        <span class="title" [style.color]="header.fontColor">
          {{ words["key-header-title"] }}
        </span>
        <span class="subtitle" [style.color]="header.fontColor">
          {{ words["key-header-subtitle"] }}
        </span>
      </div>
    </div>
    <div class="languages">
      <ng-container *ngFor="let language of languages; let i = index">
        <span
          class="language"
          (click)="changeLanguage(language)"
          [style.color]="header.fontColor"
          [style.borderBottom]="
            language.default ? '1px solid ' + header.fontColor : 'none'
          "
          >{{ language.title }}</span
        >
      </ng-container>
    </div>
    <div class="settings p-ml-auto p-mt-auto p-mb-auto p-mr-2">
      <i
        class="pi pi-ellipsis-v ellipsis"
        (click)="displaySettings = true"
        [style.color]="header.fontColor"
      ></i>
    </div>
    <p-sidebar
      [(visible)]="displaySettings"
      [baseZIndex]="1"
      [modal]="true"
      [dismissible]="true"
      [showCloseIcon]="true"
      [closeOnEscape]="true"
      class="settings-panel"
      [style]="{
        width: '320px',
        padding: '20px',
        boxShadow: '0 10px 40px 0 rgb(41 50 65 / 10%)'
      }"
      position="right"
    >
      <!-- User not logged in section -->
      <div *ngIf="!user.isLogged">
        <h2 class="text-center">{{ words["key-admin-login"] }}</h2>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
              <input
                [(ngModel)]="user.username"
                type="text"
                pInputText
                [placeholder]="words['key-admin-username']"
              />
            </div>
          </div>
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
              <input
                [(ngModel)]="user.password"
                type="password"
                pInputText
                [placeholder]="words['key-admin-password']"
              />
            </div>
          </div>
          <div class="p-col-12 p-md-12 text-center">
            <button
              pButton
              (click)="login()"
              type="button"
              [label]="words['key-admin-login']"
              [disabled]="loading"
              style="margin-top: 10px"
              [style.background]="header.headerColor"
              [style.fontColor]="header.fontColor"
              [style.borderWidth]="'0px'"
            ></button>
          </div>
        </div>
      </div>

      <!-- User logged in section -->
      <div *ngIf="user.isLogged" style="padding: 10px">
        <!-- User information -->
        <div class="p-grid">
          <div class="p-col-3">
            <p-avatar icon="pi pi-user" size="large"></p-avatar>
          </div>
          <div class="p-col-9">
            <span style="font-size: 0.8rem; color: grey">Username</span>
            <p
              style="
                margin-bottom: 10px;
                margin-top: 2px;
                font-size: 1.2rem;
                font-size: bold;
              "
            >
              {{ user.username }}
            </p>

            <span style="font-size: 0.8rem; color: grey">Permissions</span>
            <!-- Displaying User Permissions using p-badge -->
            <div *ngIf="user.groups && user.groups.length">
              <p-badge
                *ngFor="let group of user.groups"
                [value]="group.name"
                severity="warning"
                [style.zoom]="0.7"
                styleClass="p-mr-1"
                [style.fill]="header.headerColor"
                [style.color]="header.fontColor"
              ></p-badge>
            </div>
          </div>
        </div>
        <!-- Actions: Tasks Logs & Logout -->
        <div class="p-d-flex p-jc-center p-ai-center" style="margin-top: 50px">
          <div class="p-mr-2">
            <button
              pButton
              label="Tasks Logs"
              tooltipPosition="bottom"
              (click)="showTaskLog = true; displaySettings = false"
              class="p-button-rounded p-button-info"
            ></button>
          </div>
          <div>
            <button
              pButton
              icon="pi pi-sign-out"
              class="p-button-rounded p-button-danger"
              (click)="logout()"
            ></button>
          </div>
        </div>
      </div>
      <!-- admin only -->
      <div class="sidebar-footer" *ngIf="user.isLogged">
        <ul class="footer-nav">
          <li>
            <a [href]="sidebarLinks.versions" target="_blank">
              <i class="pi pi-fw pi-list"></i>
              Versions
            </a>
          </li>
          <span class="separator">|</span>
          <li>
            <a [href]="sidebarLinks.adminTool" target="_blank">
              <i class="pi pi-fw pi-cog"></i>
              Admin Tool
            </a>
          </li>
        </ul>
      </div>
    </p-sidebar>
  </div>
</div>
<p-dialog
  [(visible)]="showTaskLog"
  header="Task Log"
  [modal]="true"
  [style]="{ width: '50%', 'max-height': '80%', 'z-index': 1501 }"
  [appendTo]="'body'"
  [draggable]="false"
>
  <app-task-log [token]="user.token" *ngIf="showTaskLog"></app-task-log>
</p-dialog>
<div class="main-container">
  <div
    class="sidebar"
    [ngClass]="{
      'large-sidebar': displaySidebar,
      'mini-sidebar': displaySidebarMini
    }"
  >
    <div class="hide" [class.hidden]="!displaySidebar">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-align-right"
        class="p-button-rounded p-button-secondary"
        (click)="toggleSidebar()"
      ></button>
    </div>
    <p-sidebar
      [(visible)]="displaySidebar"
      [baseZIndex]="1"
      [modal]="false"
      [dismissible]="false"
      [showCloseIcon]="false"
      [closeOnEscape]="false"
      [style]="{
        width: '235px',
        padding: 0,
        paddingTop: '50px',
        boxShadow: '0 10px 40px 0 rgb(41 50 65 / 6%)'
      }"
    >
      <div class="sidebar-content">
        <div
          class="sidebar-content__elements"
          [class.fullHeight]="!header.showLicense"
        >
          <p-panelMenu
            [model]="menuItems"
            [style]="{
              width: '200px',
              'font-size': '12px',
              border: 'none',
              padding: '0'
            }"
            styleClass="sidemenu"
          ></p-panelMenu>
        </div>
        <div
          class="sidebar-content__license"
          *ngIf="!loading && header.showLicense"
        >
          <div class="show-license">
            <a target="_blank" [href]="header.licenseUrl">{{
              words["key-sidebar-license"]
            }}</a>
            <img src="https://www.fao.org/typo3temp/pics/de77f38b9d.png" />
          </div>
        </div>
      </div>
    </p-sidebar>
    <p-sidebar
      [(visible)]="displaySidebarMini"
      [baseZIndex]="1"
      [modal]="false"
      [dismissible]="false"
      [showCloseIcon]="false"
      [closeOnEscape]="false"
      [style]="{
        width: '50px',
        padding: 0,
        paddingTop: '85px',
        boxShadow: '0 10px 40px 0 rgb(41 50 65 / 6%)'
      }"
    >
      <p-tieredMenu
        [model]="menuItems"
        [style]="{
          width: '50px',
          'margin-top': '20px',
          padding: '0',
          borderWidth: '0'
        }"
        [popup]="true"
      ></p-tieredMenu>
    </p-sidebar>
  </div>
  <div class="main-body">
    <router-outlet></router-outlet>
  </div>
</div>
