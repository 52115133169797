import { Component, OnInit } from "@angular/core";
import { StockChart } from "angular-highcharts";
import { ApisService } from "src/app/services/apis.service";
import { HelperService } from "src/app/services/helper.service";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-widget-chart",
  templateUrl: "./widget-chart.component.html",
  styleUrls: ["./widget-chart.component.scss"],
})
export class WidgetChartComponent implements OnInit {
  chart = new StockChart(this.helper.getChartConfigs());
  loading: boolean = true;
  params: any = {};
  ids: any;
  widgets: any;
  updateSeries: boolean = true;
  permalink: string = null;
  size: any = {};

  constructor(
    private route: ActivatedRoute,
    private helper: HelperService,
    private apis: ApisService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (
        this.params.d != params.d ||
        this.params.n != params.n ||
        this.params.cs != params.cs ||
        !_.isEqual(this.params.wi, params.wi)
      )
        this.updateSeries = true;
      this.params = params;
      this.initWidget();
    });
  }

  initWidget() {
    this.widgets = JSON.parse(decodeURIComponent(this.params.wi));
    this.ids = this.widgets.map((w) => w[1]);
    this.size.w = window.innerWidth;
    this.size.h = window.innerHeight;
    this.configureChartOptions();
    if (this.updateSeries) {
      this.apis
        .getMultiplePrices(this.ids, this.params.per)
        .subscribe((res: any) => {
          this.configureChartSeries(res);
        });
    }
  }

  checkForPermalink() {
    if (JSON.parse(this.params.perm || null)) {
      this.permalink = this.helper.createPermalink(
        this.ids,
        this.params.dataset
      );
    } else {
      this.permalink = null;
    }
  }

  configureChartSeries(res?) {
    this.updateSeries = false;
    let selectedCurrencies = ["nominal"];
    if (this.params.cs) selectedCurrencies = JSON.parse(this.params.cs);
    let data = res.results.map((r) => {
      if (!r.id) r.id = r.uuid;
      return r;
    });
    this.chart.ref$.subscribe((c) => {
      while (c.series.length > 0) c.series[0].remove(true);
      for (let i = 0; i < this.ids.length; i++) {
        const s: any = { id: this.ids[i] };
        this.helper.doScaledTimeout(500, i, () => {
          s.legendColor = this.helper.randDarkColor();
          let hasNominal = false;
          let hasReal = false;
          let hasDollar = false;
          let serieName = this.widgets.find((w) => w[1] == s.id)[0];
          if (serieName)
            serieName = serieName
              .split(",")
              .join(", ")
              .split("{[{|}]}")
              .join("%");
          if (selectedCurrencies.includes("nominal")) {
            hasNominal = true;
            s.series = this.helper.getDataPoints(
              data.find((r) => r.id == s.id).datapoints
            );
          }
          if (selectedCurrencies.includes("real")) {
            hasReal = true;
            s.real = this.helper.getDataPoints(
              data.find((r) => r.id == s.id).datapoints,
              "real"
            );
          }
          if (selectedCurrencies.includes("dollar")) {
            hasDollar = true;
            s.dollar = this.helper.getDataPoints(
              data.find((r) => r.id == s.id).datapoints,
              "dollar"
            );
          }

          if (JSON.parse(this.params.d)) {
            if (s.series) s.series = s.series.slice(-1 * Number(this.params.n));
            if (s.real) s.real = s.real.slice(-1 * Number(this.params.n));
            if (s.dollar) s.dollar = s.dollar.slice(-1 * Number(this.params.n));
          }

          if (hasNominal) {
            c.addSeries({
              id: s.id,
              data: s.series,
              type: undefined,
              visible: true,
              name: serieName,
              color: `#${this.widgets.find((w) => w[1] == s.id)[2]}`,
              yAxis: 0,
            });
          }
          if (hasReal) {
            c.addSeries({
              id: `${s.id}-real`,
              visible: true,
              data: _.cloneDeep(s.real),
              type: undefined,
              dashStyle: "ShortDot",
              name: serieName.replace("/", "-real/"),
              color: `#${this.widgets.find((w) => w[1] == s.id)[2]}`,
              marker: {
                enabled: true,
                radius: 1,
              },
              yAxis: 0,
            });
          }
          if (hasDollar) {
            c.yAxis[1].update({ visible: true });
            c.update({ chart: { marginRight: 70 } });
            let serieNameDollar = serieName.split(", ");
            serieNameDollar[serieNameDollar.length - 1] = ` USD/${
              this.params.m.split("/")[1]
            }`;
            c.addSeries({
              id: `${s.id}-dollar`,
              visible: true,
              data: _.cloneDeep(s.dollar),
              type: undefined,
              dashStyle: "ShortDashDotDot",
              name: serieNameDollar.join(", "),
              color: `#${this.widgets.find((w) => w[1] == s.id)[2]}`,
              marker: {
                enabled: true,
                radius: 1,
              },
              yAxis: 1,
            });
          }
          if (i == this.ids.length - 1) this.loading = false;
          return s;
        });
      }
    });
  }

  configureChartOptions() {
    this.checkForPermalink();
    this.chart.ref$.subscribe((c) => {
      c.setSize(this.size.w, this.size.h);
      c.update({
        chart: {
          marginTop: 20,
          marginBottom: JSON.parse(this.params.e)
            ? Number(this.params.p || 100)
            : 0,
          marginRight: 20,
        },
        title: {
          text: this.params.t == "undefined" ? undefined : this.params.t,
          align: "center",
        },
        subtitle: {
          text: this.params.s == "undefined" ? undefined : this.params.s,
          align: "center",
        },
        navigator: <any>{
          top: -20,
          height: 0,
          enabled: false,
        },
        yAxis: [
          {
            opposite: false,
            visible: true,
            title: {
              text: this.params.m,
            },
          },
          {
            opposite: true,
            visible: false,
            title: {
              text: this.params.m.replace(/^.+\//, "USD/"),
            },
          },
        ],
        legend: {
          enabled: JSON.parse(this.params.e),
          align: "center",
          verticalAlign: "bottom",
          layout: "vertical",
          x: 0,
          y: -1 * Number(this.params.l || 0),
        },
        rangeSelector: {
          inputStyle: {
            color: "black",
          },
        },
      });
      c.redraw();
    });
  }

  openPermalink() {
    window.open(this.permalink, "_blank");
  }

  exportImage() {
    let svg = document
      .getElementById("widget-chart-area")
      .getElementsByTagName("svg")[0];
    this.helper.exportSvgToImage(svg);
  }
}
