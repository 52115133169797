import { HelperService } from "./helper.service";
import { StockChart } from "angular-highcharts";
import { Injectable } from "@angular/core";
import { Options } from "highcharts";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ChartConversionsService {
  constructor(private helper: HelperService) {}

  extractAvailableCurrencies(selectedSeries) {}

  updateAxisUnit(chart: StockChart, axis: number, series) {
    chart.ref$.subscribe((c) => {
      c.yAxis[axis].update({
        title: {
          text: `${series.currency}/${series.measure_unit_label}`,
        },
      });
    });
  }

  toggleAxisVisibility(chart: StockChart, axis: number, flag: boolean) {
    chart.ref$.subscribe((c) => {
      c.yAxis[axis].update({
        visible: flag,
      });
    });
  }

  toggleAxisLocation(chart: StockChart, axis, position) {
    chart.ref$.subscribe((c) => {
      c.yAxis[axis].update({
        opposite: position == "left" ? false : true,
        offset: axis == 1 && position == "left" ? 6 : 20,
        title: {
          x: position == "left" ? 0 : 15,
        },
      });
    });
  }

  getSeriesPropertyNameBasedOnCurrencyAndMeasure(currency, measure, isDollar?) {
    let property = [isDollar ? "nominal" : currency, "Datapoints"];
    property.push(
      measure == "Kg" || measure == "tonne"
        ? this.helper.capitalizeFirstLetter(measure)
        : ""
    );
    return property.join("");
  }

  getTitleFromSeries(series, currency, measure) {
    if (currency == "dollar") {
      return `USD/${measure}`;
    }
    let label = currency == "nominal" ? "" : `-${currency}`;
    return `${series.currency}${label}/${measure}`;
  }

  getFullTitleFromSeries(s, currency?, measure?, withoutCurrencyMeasure?) {
    if (!s) return;
    let c = `${s.currency}/${measure}`;
    if (currency) {
      c = `${this.getTitleFromSeries(s, currency, measure)}`;
    }
    let name = `${s.country_name}, ${s.price_type}, ${s.market_name}, ${s.commodity_name}`;
    return withoutCurrencyMeasure ? name : name + `, ${c}`;
  }

  getValuesFromUnit(value, unit, factor) {
    if (!value) return;
    let newValue;

    // Kg unit
    if (unit.current == "Kg") {
      // From tonne
      if (unit.previous == "tonne") {
        // logger('From tonne to KG');
        newValue = value * 1000;
        return newValue;
      }
      // From local measure
      // logger(`From ${unit.previous} to KG`);
      newValue = value * factor;
      return newValue;
    }

    // Tonne unit
    if (unit.current == "tonne") {
      // From Kg
      if (unit.previous == "Kg") {
        // logger('From Kg to Tonne');
        newValue = value / 1000;
        return newValue;
      }
      // From local measure
      // logger(`From ${unit.previous} to Tonne`);
      newValue = (value * factor) / 1000;
      return newValue;
    }

    // Local measure unit
    // From Kg
    if (unit.previous == "Kg") {
      // logger(`From Kg to ${unit.current}`);
      newValue = value / factor;
      return newValue;
    }
    // From Tonne
    // logger(`From Tonne to ${unit.current}`);
    newValue = (value * 1000) / factor;
    return newValue;
  }

  formatChartForCommonUnit(chart, measures) {
    // if(measures.)
  }

  formatAllMeasures(series) {
    let nominalDatapoints = _.cloneDeep(series.series);
    let realDatapoints = _.cloneDeep(series.realDatapoints);
    let dollarDatapoints = _.cloneDeep(series.dollarDatapoints);
    if (series.measure_unit_label == "Kg") {
      // Nominal
      series.nominalDatapoints = nominalDatapoints;
      series.nominalDatapointsKg = nominalDatapoints;
      series.nominalDatapointsTonne =
        this.convertMeasureFromKgToTonne(nominalDatapoints);

      // Real
      series.realDatapoints = realDatapoints;
      series.realDatapointsKg = realDatapoints;
      series.realDatapointsTonne =
        this.convertMeasureFromKgToTonne(realDatapoints);

      // Dollar
      series.dollarDatapoints = dollarDatapoints;
      series.dollarDatapointsKg = dollarDatapoints;
      series.dollarDatapointsTonne =
        this.convertMeasureFromKgToTonne(dollarDatapoints);
    } else if (series.measure_unit_label == "tonne") {
      // Nominal
      series.nominalDatapoints = nominalDatapoints;
      series.nominalDatapointsKg =
        this.convertMeasureFromTonneToKg(nominalDatapoints);
      series.nominalDatapointsTonne = nominalDatapoints;

      // Real
      series.realDatapoints = realDatapoints;
      series.realDatapointsKg =
        this.convertMeasureFromTonneToKg(realDatapoints);
      series.realDatapointsTonne = realDatapoints;

      // Dollar
      series.dollarDatapoints = dollarDatapoints;
      series.dollarDatapointsKg =
        this.convertMeasureFromTonneToKg(dollarDatapoints);
      series.dollarDatapointsTonne = dollarDatapoints;
    } else {
      series.nominalDatapoints = nominalDatapoints;
      series.realDatapoints = realDatapoints;
      series.dollarDatapoints = dollarDatapoints;
      if (series.conversion_factor) {
        // Nominal
        series.nominalDatapointsKg = this.convertFromLocalToKg(
          nominalDatapoints,
          series.conversion_factor
        );
        series.nominalDatapointsTonne = this.convertFromLocalToTonne(
          nominalDatapoints,
          series.conversion_factor
        );

        // Real
        series.realDatapointsKg = this.convertFromLocalToKg(
          realDatapoints,
          series.conversion_factor
        );
        series.realDatapointsTonne = this.convertFromLocalToTonne(
          realDatapoints,
          series.conversion_factor
        );

        // Dollar
        series.dollarDatapointsKg = this.convertFromLocalToKg(
          dollarDatapoints,
          series.conversion_factor
        );
        series.dollarDatapointsTonne = this.convertFromLocalToTonne(
          dollarDatapoints,
          series.conversion_factor
        );
      }
    }
    return series;
  }

  convertMeasureFromKgToTonne(series) {
    return _.cloneDeep(series || []).map((s) => {
      if (s[1]) s[1] = s[1] * 1000;
      return s;
    });
  }

  convertMeasureFromTonneToKg(series) {
    return _.cloneDeep(series || []).map((s) => {
      if (s[1]) s[1] = s[1] / 1000;
      return s;
    });
  }

  convertFromLocalToKg(series, factor) {
    return _.cloneDeep(series || []).map((s) => {
      if (s[1]) s[1] = s[1] * factor;
      return s;
    });
  }

  convertFromLocalToTonne(series, factor) {
    return _.cloneDeep(series || []).map((s) => {
      if (s[1]) s[1] = s[1] * factor * 1000;
      return s;
    });
  }

  updateMeasureBySlash(string, measure) {
    string = string.split("/");
    string[1] = measure;
    return string.join("/");
  }

  setMeasuresForCommonUnits(measures) {
    measures = {
      selected: "Kg",
      types: [
        {
          name: "Kg",
        },
        {
          name: "tonne",
        },
      ],
    };
  }

  checkPeriodicities(series) {
    let periodicities = _.cloneDeep(
      series.map((s) => s.periodicities.map((p) => p.period))
    );
    let intersection = periodicities.reduce((a, b) =>
      a.filter((c) => b.includes(c))
    );
    return intersection;
  }

  getDiffUnits(series) {
    let sameMeasure = true;
    let measure = series[0].measure_unit_label;
    let sameCurrency = true;
    let currency = series[0].currency;
    series.map((s) => {
      if (s.measure_unit_label != measure) sameMeasure = false;
      if (s.currency != currency) sameCurrency = false;
    });
    return [sameMeasure, sameCurrency];
  }

  mode(arr) {
    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  }

  allEqual(arr) {
    return arr.every((v) => v === arr[0]);
  }

  getCommonUnitForNonConvertable(series): any {
    let units = series.map((s) => s.measure_unit_label);
    if (this.allEqual(units)) return { ids: series, unit: units[0] };
    let commonUnit = this.mode(units);
    return {
      ids: series.filter((s) => s.measure_unit_label === commonUnit),
      unit: commonUnit,
    };
  }

  getCommonPeriodicities(series): any {
    let periodicities = series
      .map((s) => s.periodicities)
      .map((s) => s.map((p) => p.period));
    return this.mode(periodicities);
  }

  getMeasuresTemplateFromUnit(unit, convertable) {
    let measures = { selected: unit, previous: unit, types: [{ name: unit }] };
    if (convertable) {
      if (unit == "Kg") {
        measures.types.push({
          name: "tonne",
        });
      } else if (unit == "tonne") {
        measures.types.push({
          name: "Kg",
        });
      } else {
        measures.types.push({
          name: "Kg",
        });
        measures.types.push({
          name: "tonne",
        });
      }
    }
    return measures;
  }

  getCurrenciesTemplate(type, name?) {
    let currencies = {
      types: [
        {
          name: name,
          type: "nominal",
          selected: true,
          hidden: false,
        },
        {
          name: name,
          type: "real",
          selected: false,
          hidden: false,
        },
        {
          name: `Dollars`,
          type: "dollar",
          selected: false,
          hidden: false,
        },
      ],
      selections: {},
    };
    if (type == "dollar" || name == "USD") {
      currencies.types = currencies.types.map((c) => {
        if (c.type != "dollar") {
          c.hidden = true;
          c.selected = false;
        } else {
          c.hidden = false;
          c.selected = true;
        }
        return c;
      });
    }
    return currencies;
  }
  getHarmonizedSeriesFromFilteredGrid(series) {
    let nonConvertable = [];
    let convertable = [];
    let response = {
      ids: [],
      measures: {},
      currencies: {},
      propertyName: "",
      currency: "",
      measure: "",
      periodicities: [],
    };
    let currencySet = new Set<string>();
    let measureSet = new Set<string>();

    // Combine filtering and mapping
    for (let s of series) {
      if (s.conversion_factor) {
        convertable.push(s);
        measureSet.add(s.measure_unit_label);
      } else {
        nonConvertable.push(s);
      }
      currencySet.add(s.currency);
    }

    let measure = "";
    if (nonConvertable.length === series.length) {
      let commons = this.getCommonUnitForNonConvertable(nonConvertable);
      response.ids = commons.ids;
      response.measures = this.getMeasuresTemplateFromUnit(commons.unit, false);
      measure = commons.unit;
    } else {
      if (nonConvertable.length > convertable.length) {
        let [nonConvertables, unit] =
          this.getCommonUnitForNonConvertable(nonConvertable);
        response.ids = nonConvertables;
        response.measures = this.getMeasuresTemplateFromUnit(unit, false);
        measure = unit;
      } else {
        response.ids = convertable;
        if (measureSet.size === 1) {
          measure = Array.from(measureSet)[0];
          response.measures = this.getMeasuresTemplateFromUnit(measure, true);
        } else {
          response.measures = this.getMeasuresTemplateFromUnit("Kg", true);
          measure = "Kg";
        }
      }
    }

    let currency = "";
    if (currencySet.size === 1) {
      currency = "nominal";
      response.currencies = this.getCurrenciesTemplate(
        currency,
        Array.from(currencySet)[0]
      );
    } else {
      currency = "dollar";
      response.currencies = this.getCurrenciesTemplate(
        currency,
        Array.from(currencySet)[0]
      );
    }

    response.periodicities = this.getCommonPeriodicities(response.ids);
    response.ids = response.ids.map((s) => s.id);
    response.propertyName = this.getSeriesPropertyNameBasedOnCurrencyAndMeasure(
      currency,
      measure
    );
    response.currency = currency;
    response.measure = measure;

    return response;
  }

  switchChartOptionsToTimeSeries(c, periodicity, chartTypes) {
    c.yAxis[2].update({ visible: false });
    c.yAxis[3].update({ visible: false });
    c.yAxis[0].update({
      title: {
        text: chartTypes.yAxisLabel,
      },
    });
    c.xAxis[0].options.type = "datetime";
    c.xAxis[0].setCategories(null);
    c.update(<Options>{
      chart: {
        marginTop: 65,
        zoomType: "x",
        type: "line",
      },
      xAxis: {
        labels: {
          formatter: null,
        },
      },
      rangeSelector: {
        enabled: true,
        inputEnabled: true,
      },
      navigator: {
        enabled: true,
      },
      tooltip: this.helper.getMainChartTooltip("series", null, periodicity),
      plotOptions: {
        series: {
          zones: null,
          marker: {
            enabled: true,
            radius: 1,
          },
        },
      },
    });
  }
}
