import { constants } from "src/environments/constants";
import { IpaCalculatorService } from "./ipa-calculator.service";
import { ChartConversionsService } from "./chart-conversions.service";
import { HelperService } from "./helper.service";
import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ChartDataService {
  constructor(
    private helper: HelperService,
    private chartConversions: ChartConversionsService,
    private ipaCalculator: IpaCalculatorService
  ) {}

  chartDataForTimeSeries(params) {
    let selectedCurrencies = this.helper.getSelectedCurrencies(
      params.currencies
    );
    let rows = [];
    let cols = [{ uid: "date", name: params.dateLabel }];
    let series = _.cloneDeep(params.series);
    selectedCurrencies.map((c) => {
      series.map((s) => {
        let uid = `col-${c}-${s.id}`;
        let col = {
          uid,
          name:
            s.country_name +
            ", " +
            s.price_type +
            ", " +
            s.market_name +
            ", " +
            s.commodity_name +
            ", " +
            this.chartConversions.getTitleFromSeries(s, c, params.measure),
        };
        col["legendColor"] =
          c == "nominal"
            ? s.legendColor
            : s[`legendColor${this.helper.capitalizeFirstLetter(c)}`];
        cols.push(col);
        let isDollar = s.currency == "USD";
        let property =
          this.chartConversions.getSeriesPropertyNameBasedOnCurrencyAndMeasure(
            c,
            params.measure,
            isDollar
          );
        let datapoints = s[property];
        if (!datapoints) return;
        datapoints.map((d) => {
          if (
            moment(d[0]) >= moment(params.extremes.min) &&
            moment(d[0]) <= moment(params.extremes.max)
          ) {
            let date = this.helper.getformatDateByPeriodicity(
              params.periodicity,
              d[0]
            );
            // let date = moment(d[0]).format("DD-MMMM-YYYY");
            if (!rows.length) {
              let row = { date: date };
              row[uid] = d[1];
              if (c == "nominal" && params.measure == s.measure_unit_label)
                row[uid + "-data"] = { editable: false, price_id: d[2] };
              rows.push(row);
            } else {
              let existingRow = rows.find((r) => r.date == date);
              if (existingRow) {
                existingRow[uid] = d[1];
                if (
                  (c == "nominal" || isDollar) &&
                  params.measure == s.measure_unit_label
                )
                  existingRow[uid + "-data"] = {
                    editable: false,
                    price_id: d[2],
                  };
              } else {
                let row = { date: date };
                row[uid] = d[1];
                if (
                  (c == "nominal" || isDollar) &&
                  params.measure == s.measure_unit_label
                )
                  row[uid + "-data"] = { editable: false, price_id: d[2] };
                rows.push(row);
              }
            }
          }
        });
      });
    });
    cols = cols
      .map((a: any) => {
        if (a.uid == "date") return a;
        a.n = a.uid.split("-").splice(2, 1).join();
        return a;
      })
      .sort((a, b) => b.n - a.n);
    return { rows, cols };
  }

  chartDataForIPA(selectedIPA, params) {
    let ipaData: any = {
      seriesId: selectedIPA.seriesId,
      seriesName: selectedIPA.seriesName,
      ipaData: selectedIPA.ipaData,
    };
    let realDatapoints: any = {
      seriesId: selectedIPA.seriesId,
      seriesName: this.chartConversions.getFullTitleFromSeries(
        params.series,
        params.currency,
        params.measure
      ),
      realDatapoints: selectedIPA.realDatapoints,
    };
    let ipaArray = [ipaData, realDatapoints];
    let rows = [];
    let cols = [{ uid: "date", name: params.dateLabel }];
    ipaArray.map((ipa) => {
      let uid = `col-${ipa.seriesId}-${ipa.ipaData ? "ipa" : "real"}`;
      let col = {
        uid,
        name: ipa.seriesName,
        hasColorLegend: Boolean(ipa.ipaData),
      };
      cols.push(col);
      (ipa.ipaData || ipa.realDatapoints || []).map((d) => {
        if (
          moment(d[0]) >= moment(params.extremes.min) &&
          moment(d[0]) <= moment(params.extremes.max)
        ) {
          let date = params.isAnnual
            ? moment(d[0]).format("YYYY")
            : this.helper.getformatDateByPeriodicity("monthly", d[0]);
          if (!rows.length) {
            let row = { date: date };
            row[uid] = d[1];
            row["color"] = this.ipaCalculator.getThresholdColorFromZones(
              d[1],
              params.ignoreNegative
            );
            rows.push(row);
          } else {
            let existingRow = rows.find((r) => r.date == date);
            if (existingRow) {
              existingRow[uid] = d[1];
            } else {
              let row = { date: date };
              row[uid] = d[1];
              row["color"] = this.ipaCalculator.getThresholdColorFromZones(
                d[1],
                params.ignoreNegative
              );
              rows.push(row);
            }
          }
        }
      });
    });
    if (params.isAnnual) cols = cols.filter((c) => !c.uid.includes("real"));
    cols = cols
      .map((a: any) => {
        if (a.uid == "date") return a;
        a.n = a.uid.split("-").splice(2, 1).join();
        return a;
      })
      .sort((a, b) => b.n - a.n);
    return { rows, cols };
  }

  chartDataForPercentageChange(selectedPercentage, params) {
    let percentageData: any = {
      seriesId: selectedPercentage.seriesId,
      seriesName: this.chartConversions.getFullTitleFromSeries(
        params.series,
        params.currency,
        params.measure
      ),
      data: selectedPercentage.data,
    };
    let rows = [];
    let cols = [{ uid: "date", name: params.dateLabel }];

    let uid = `col-${percentageData.seriesId}-percentage`;
    let col = { uid, name: percentageData.seriesName, hasColorLegend: true };
    cols.push(col);
    (percentageData.data || []).map((d) => {
      if (
        moment(d[0]) >= moment(params.extremes.min) &&
        moment(d[0]) <= moment(params.extremes.max)
      ) {
        let date = this.helper.getformatDateByPeriodicity(
          params.periodicity,
          d[0]
        );
        if (!rows.length) {
          let row = { date: date };
          row[uid] = d[1];
          row["color"] = d[1] >= 0 ? "red" : "green";
          rows.push(row);
        } else {
          let existingRow = rows.find((r) => r.date == date);
          if (existingRow) {
            existingRow[uid] = d[1];
          } else {
            let row = { date: date };
            row[uid] = d[1];
            row["color"] = d[1] >= 0 ? "red" : "green";
            rows.push(row);
          }
        }
      }
    });

    cols = cols
      .map((a: any) => {
        if (a.uid == "date") return a;
        a.n = a.uid.split("-").splice(2, 1).join();
        return a;
      })
      .sort((a, b) => b.n - a.n);
    return { rows, cols };
  }

  chartDataForMarketSeason(data) {
    data = data.filter((d) => d.isSelected && d.isSelected.length);
    let cols: any = [{ uid: "date", name: "Months" }];
    let rows = [];
    let months = this.helper.getMonthsLongNames();
    data.map((d) => {
      let col = { uid: d.uid, name: d.endYear, legendColor: d.legendColor };
      cols.push(col);
    });
    for (let i = 0; i < 12; i++) {
      let row = { date: months[i] };
      data.map((d) => {
        row[d.uid] = d.data[i].y;
      });
      rows.push(row);
    }
    return { rows, cols };
  }

  calculateInformationTab(selectedSeries, markets, words) {
    let containsImage =
      selectedSeries.filter((s) => s.commodity_image).length > 0;
    let cols = [
      {
        labelkey: "key-datagrid-column-country",
        field: "country_name",
        width: 150,
      },
      {
        labelkey: "key-datagrid-column-price_type",
        field: "price_type",
        width: 100,
      },
      {
        labelkey: "key-datagrid-column-periodicity",
        field: "periodicities",
        width: 200,
      },
      {
        labelkey: "key-datagrid-column-market",
        field: "market",
        width: 200,
      },
      {
        labelkey: "key-datagrid-column-commodity",
        field: "commodity",
        width: containsImage ? 300 : 200,
      },
      {
        labelkey: "key-datagrid-column-source",
        field: "source",
        width: 300,
      },
    ];
    let rows = [];
    selectedSeries.map((s) =>
      rows.push({
        country_name: s.country_name,
        price_type: s.price_type,
        commodity: this.getCommodityTemplate(s, words),
        market: this.getMarketTemplate(
          markets.find((m) => m.id == s.market),
          words
        ),
        source: this.getSourceTemplate(s),
        periodicities: this.getPeriodicityTemplate(s.periodicities, words),
      })
    );
    let width = cols.map((c) => c.width).reduce((prev, curr) => prev + curr);
    return { rows, cols, width };
  }

  getSourceTemplate(series) {
    return `${series.source_name} <br> <a href="${series.source_url}" target="_blank" class="info-url">${series.source_url}</a>`;
  }

  getCommodityTemplate(series, words) {
    let image = "";
    if (series.commodity_image) {
      image = series.commodity_image.split("media/commodity");
      image = `${constants.prefix}${constants.baseOrigin}media/commodity${image[1]}`;
    }
    return `
      ${
        image
          ? `
            <div class="commodity_image">
              <img src="${image}">
            </div>
          `
          : ""
      }
      <div class="commodity_text">
        <i>${words["key-panel-main-information-commodity_name"] || ""}:</i> ${
      series.commodity_name
    } <br />
        <i>${words["key-panel-main-information-commodity_code"] || ""}:</i> ${
      series.commodity_code
    } <br />
        <i>${words["key-panel-main-information-alternative_name"] || ""}:</i> ${
      series.alternative_name
    } <br />
        <i>${words["key-panel-main-information-alternative_code"] || ""}:</i> ${
      series.alternative_code
    } <br />
        <i>${words["key-panel-main-information-commodity_info"] || ""}:</i> ${
      series.commodity_info
    } <br />
      </div>
    `;
  }

  getMarketTemplate(market, words) {
    if (!market) return "";
    return `
      <i>${words["key-panel-main-information-market_name"] || ""}:</i> ${
      market.market_name
    } <br />
      <i>${words["key-panel-main-information-market_type"] || ""}:</i> ${
      market.market_type
    } <br />
      <i>${words["key-panel-main-information-admin_unit"] || ""}:</i> ${
      market.admin_unit
    } <br />
      <i>${words["key-panel-main-information-market_info"] || ""}:</i> ${
      market.market_info
    }
    `;
  }

  getPeriodicityTemplate(periodicities, words) {
    return periodicities
      .map(
        (p) =>
          words[`key-${p.period}`] +
          (p.calculated ? " (Calculated): " : ": ") +
          this.helper.getGridDateFromPeriodicity(p)
      )
      .join("<br>");
  }
}
